import env from "helpers/env-helper";

const ENV_PREPEND: string = "REACT_APP_FEATURE_";

export enum Feature {
  OrdersCloseConfirm = "ORDERS_CLOSE_CONFIRM",
  OrdersReferrerDetails = "ORDERS_REFERRER_DETAILS",
  OrdersFittingQuality = "ORDERS_FITTING_QUALITY",
  TextToSentence = "TEXT_TO_SENTENCE",
  OrdersTips = "ORDERS_TIPS",
}

const featFlagFromEnv = (feature: Feature): boolean => {
  let envKey = `${ENV_PREPEND}${feature}`;
  let envValue = env(envKey).toString();
  return envValue === "1";
};

export const feat = (feature: Feature): boolean => {
  let result: boolean = featFlagFromEnv(feature);
  return result;
};
