import {
  EuiComboBox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiHealth,
  EuiIcon,
  EuiSpacer,
  EuiSuperSelect,
  useIsWithinBreakpoints,
} from "@elastic/eui";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { Order } from "store/data/order/order";
import {
  MMOrderLineFieldProps,
  MMOrderLineProductInput,
} from "../order-line-entry";
import { selectableProductOptions } from "helpers/product-helper";
import { updateMappedTypeNode } from "typescript";
import { css } from "@emotion/react";

export const getEligibleProductVariations = (
  productVariations: any[],
  name?: string,
  family?: string,
  size?: string,
  color?: string,
  isSpecial?: boolean
) => {
  const result = productVariations.filter(
    (product: any) =>
      (name ? product.name === name : true) &&
      (family ? product.family === family : true) &&
      (size ? product.size === size : true) &&
      (color ? product.color === color : true) &&
      (isSpecial !== undefined
        ? product.is_special_variant === isSpecial
        : true)
  );
  console.log(
    "getEligibleProductVariations",
    productVariations.length,
    name,
    family,
    size,
    color,
    isSpecial,
    result
  );

  return result;
};

function MMOrderProduct(props: MMOrderLineFieldProps) {
  const isMobile: boolean = useIsWithinBreakpoints(["xs", "s"]);

  const lang = txt.lang();

  const toProductSelection = (name: string, variations: any[]) => {
    const result = variations.find((variation: any) => variation.name === name);

    return result
      ? {
          label: result[`variant_name_${lang}`]
            ? result[`variant_name_${lang}`]
            : result[`variant_name_en`],
          value: name,
        }
      : { label: name, value: name };
  };

  const [order] = useState<Order | null>(props.order || null);
  const [selection, setSelection] = useState<MMOrderLineProductInput>(
    props.selection
  );

  const [productVariations] = useState<any[]>(props.products || []);
  const [products, setProducts] = useState<any[]>([]);
  const [sizes, setSizes] = useState<any[]>([]);
  const [colors, setColors] = useState<any[]>([]);

  const [selectedFamily, setSelectedFamily] = useState<any>(
    props.selection && props.selection.family
      ? { label: props.selection.family, value: props.selection.family }
      : undefined
  );

  const [selectedProduct, setSelectedProduct] = useState<any>(
    props.selection && props.selection.name
      ? toProductSelection(props.selection.name, productVariations)
      : undefined
  );
  const [selectedSize, setSelectedSize] = useState<string>(
    props.selection && props.selection.size ? props.selection.size : ""
  );
  const [selectedColor, setSelectedColor] = useState<string>(
    props.selection && props.selection.color ? props.selection.color : ""
  );

  const [selectedIsModified, setSelectedIsModified] = useState<boolean>(
    props.selection?.isModification || false
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    console.log("MMOrderProduct, products", products);
  }, [products]);

  useEffect(() => {
    const products: any[] = selectableProductOptions(
      getEligibleProductVariations(
        productVariations,
        undefined,
        selectedFamily ? selectedFamily.value : null
        // selectedSize,
        // selectedColor,
        // selectedIsModified
      ),
      lang,
      "name"
    );
    console.log(
      "setting because of productvariations",
      products.length ? products : products.length
    );
    if (products.length === 1) {
      setSelectedProduct(products[0]);
    }
    setProducts(products);
    setIsLoading(false);
  }, [productVariations]);

  useEffect(() => {
    setSelection(props.selection);
    setSelectedIsModified(props.selection?.isModification || false);
  }, [props.selection]);

  useEffect(() => {
    if (!selectedProduct) {
      setSizes([]);
      setColors([]);
      // setSelectedFamily(undefined);
      setSelectedSize("");
      setSelectedColor("");
      let eligibleProductVariations = getEligibleProductVariations(
        productVariations,
        undefined,
        selectedFamily ? selectedFamily.value : null,
        selectedSize,
        selectedColor,
        selectedIsModified
      );
      setProducts((products: any) =>
        selectableProductOptions(eligibleProductVariations, lang, "name")
      );
    } else {
      let eligibleSizes: any[] = [];
      let eligibleColors: any[] = [];

      let eligibleProductVariations = getEligibleProductVariations(
        productVariations,
        selectedProduct ? selectedProduct.value : undefined,
        selectedFamily ? selectedFamily.value : undefined,
        selectedSize,
        undefined,
        selectedIsModified
      );

      for (let i = 0; i < eligibleProductVariations.length; i++) {
        const product = eligibleProductVariations[i];
        let dropdownDisplay =
          product.color === "n.v.t." ? (
            product.translations[lang] ? (
              product.translations[lang].color
            ) : (
              product.color
            )
          ) : (
            <EuiHealth color={product.color_sample}>
              {product.translations[lang]
                ? product.translations[lang].color
                : product.color}
            </EuiHealth>
          );

        let inputDisplay =
          product.color === "n.v.t." ? (
            product.translations[lang] ? (
              product.translations[lang].color
            ) : (
              product.color
            )
          ) : (
            <EuiHealth
              color={product.color_sample}
              style={{ position: "relative", top: "3px" }}
            >
              {product.translations[lang]
                ? product.translations[lang].color
                : product.color}
            </EuiHealth>
          );

        eligibleColors[product.color] = {
          inputDisplay: inputDisplay,
          dropdownDisplay: dropdownDisplay,
          value: product.color,
          data_color_code: product.color_sample,
        };
        eligibleColors.sort((a: any, b: any) =>
          a.label > b.label ? 1 : b.label > a.label ? -1 : 0
        );
      }

      eligibleProductVariations = getEligibleProductVariations(
        productVariations,
        selectedProduct ? selectedProduct.value : undefined,
        selectedFamily ? selectedFamily.value : undefined,
        undefined,
        selectedColor,
        selectedIsModified
      );

      for (let i = 0; i < eligibleProductVariations.length; i++) {
        const product = eligibleProductVariations[i];
        eligibleSizes[product.size] = {
          inputDisplay: product.size,
          value: product.size,
        };
        eligibleSizes.sort((a: any, b: any) =>
          a.label > b.label ? 1 : b.label > a.label ? -1 : 0
        );
      }

      eligibleSizes = Object.values(eligibleSizes);
      eligibleColors = Object.values(eligibleColors);

      setSizes(eligibleSizes);
      setColors(eligibleColors);
      if (eligibleSizes.length === 0) {
        setSelectedSize("");
      } else if (eligibleSizes.length === 1) {
        setSelectedSize(eligibleSizes[0].value);
      } else if (
        eligibleSizes.findIndex((size: any) => size.value === selectedSize) < 0
      ) {
        setSelectedSize("");
      }
      if (eligibleColors.length === 0) {
        setSelectedColor("");
      } else if (eligibleColors.length === 1) {
        setSelectedColor(eligibleColors[0].value);
      } else if (
        eligibleColors.findIndex(
          (color: any) => color.value === selectedColor
        ) < 0
      ) {
        setSelectedColor("");
      }
    }
  }, [selectedFamily, selectedProduct, selectedColor, selectedSize]);

  useEffect(() => {
    if (props.onChange) {
      const eligibleProductVariations = getEligibleProductVariations(
        productVariations,
        selectedProduct ? selectedProduct.value : null,
        selectedFamily ? selectedFamily.value : null,
        selectedSize,
        selectedColor,
        selectedIsModified
      );

      const variation: any =
        eligibleProductVariations.length === 1
          ? eligibleProductVariations[0]
          : null;

      let updatedSelection: any = selectedProduct ? selection : {};
      let updatedMeasurements: any = updatedSelection.measurements || {};
      console.log(
        "checking for",
        variation,
        !!variation?.note_placeholder_en,
        updatedSelection,
        updatedMeasurements,
        !updatedMeasurements.notes
      );
      if (
        variation &&
        !updatedMeasurements.notes &&
        variation.note_placeholder_en
      ) {
        updatedMeasurements.notes = variation.note_placeholder_en;
      }

      props.onChange({
        ...updatedSelection,
        name: selectedProduct ? selectedProduct.value : null,
        family: selectedFamily ? selectedFamily.value : null,
        size: selectedSize,
        color: selectedColor,
        isSpecial: selectedIsModified,
        measurements: updatedMeasurements,
        code: variation ? variation.code : null,
        variation: variation,
      } as MMOrderLineProductInput);
    }
  }, [selectedFamily, selectedProduct, selectedColor, selectedSize]);

  const css = () => ({
    position: "relative",
    minWidth: "200px",
    width: "260px",
    maxWidth: "260px",
  });

  return (
    <EuiFlexGroup
      gutterSize="s"
      direction={props.direction ? props.direction : "row"}
    >
      <EuiFlexItem
        className="space-below"
        style={
          props.hideNonOptions && products.length <= 1
            ? { display: "none" }
            : css()
        }
      >
        <EuiFormRow
          className="product-input"
          display="rowCompressed"
          label={props.showTitle ? txt.get("orders.order.variant") : undefined}
          isDisabled={!props.isEditable}
        >
          <EuiComboBox
            isDisabled={!props.isEditable}
            className={selectedProduct ? "option-selected" : ""}
            rowHeight={40}
            singleSelection={{ asPlainText: true }}
            isLoading={isLoading}
            color="accent"
            id="product-search"
            isClearable={true}
            fullWidth={true}
            placeholder={txt.get("orders.order.choose_variant")}
            options={products}
            compressed={true}
            selectedOptions={selectedProduct ? [selectedProduct] : []}
            onChange={(selected) =>
              setSelectedProduct(selected.length > 0 ? selected[0] : null)
            }
          />
        </EuiFormRow>
      </EuiFlexItem>

      <EuiFlexItem
        style={
          (props.hideNonOptions &&
            !!selectedColor &&
            ["n/a", "n.v.t"].includes(selectedColor)) ||
          (colors.length === 1 && ["n/a", "n.v.t."].includes(selectedColor))
            ? { display: "none" }
            : css()
        }
      >
        <EuiFormRow
          className="product-input"
          display="rowCompressed"
          label={props.showTitle ? txt.get("orders.order.color") : undefined}
          isDisabled={!props.isEditable}
        >
          <EuiSuperSelect
            disabled={!props.isEditable}
            className={
              selectedColor
                ? `option-selected s-${selectedColor}`
                : "fake-prepend"
            }
            hasDividers={true}
            isLoading={isLoading}
            color="accent"
            id="color-search"
            prepend={
              selectedColor ? undefined : txt.get("orders.order.choose_color")
            }
            placeholder={txt.get("orders.order.choose_color")}
            name={txt.get("orders.order.choose_color")}
            aria-placeholder={txt.get("orders.order.choose_color")}
            options={colors}
            compressed={true}
            valueOfSelected={selectedColor}
            onChange={(selected) => setSelectedColor(selected)}
          />
        </EuiFormRow>{" "}
        {!!selectedColor && colors.length >= 1 ? (
          <div
            style={{
              width: "22px",
              height: "0px",
              display: "flex",
              left: "calc(100% - 44px)",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              zIndex: "10",
              top: "-16px",
            }}
          >
            <EuiIcon
              cursor="pointer"
              type="cross"
              size="s"
              onClick={(e: any) => {
                setSelectedSize("");
                setSelectedColor("");
              }}
            />
          </div>
        ) : (
          <></>
        )}{" "}
      </EuiFlexItem>
      <EuiFlexItem
        style={
          (props.hideNonOptions &&
            !!selectedSize &&
            sizes.length > 1 &&
            ["n/a", "n.v.t."].includes(selectedSize)) ||
          (sizes.length === 1 && ["n/a", "n.v.t."].includes(selectedSize))
            ? { display: "none" }
            : css()
        }
      >
        <EuiFormRow
          className="product-input"
          display="rowCompressed"
          label={props.showTitle ? txt.get("orders.order.size") : undefined}
          isDisabled={!props.isEditable}
        >
          <EuiSuperSelect
            disabled={!props.isEditable}
            className={
              selectedSize
                ? `option-selected s-${selectedSize}`
                : "fake-prepend"
            }
            hasDividers={true}
            isLoading={isLoading}
            color="accent"
            id="size-search"
            prepend={
              !selectedSize ? txt.get("orders.order.choose_size") : undefined
            }
            options={sizes}
            placeholder={txt.get("orders.order.choose_size")}
            name={txt.get("orders.order.choose_size")}
            aria-placeholder={txt.get("orders.order.choose_size")}
            compressed={true}
            valueOfSelected={selectedSize}
            onChange={(selected) => setSelectedSize(selected)}
          />
        </EuiFormRow>
        {!!selectedSize && sizes.length >= 1 ? (
          <div
            style={{
              width: "22px",
              height: "0px",
              display: "flex",
              left: "calc(100% - 44px)",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              zIndex: "10",
              top: "-16px",
            }}
          >
            <EuiIcon
              cursor="pointer"
              type="cross"
              size="s"
              onClick={(e: any) => {
                setSelectedSize("");
                setSelectedColor("");
              }}
            />
          </div>
        ) : (
          <></>
        )}{" "}
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

export default MMOrderProduct;
