import {
  EuiBadge,
  EuiBasicTable,
  EuiButton,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiFieldText,
  EuiFilterButton,
  EuiFilterGroup,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiImage,
  EuiMarkdownEditor,
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiPopover,
  EuiSpacer,
  EuiText,
  EuiTitle,
  EuiToolTip,
} from "@elastic/eui";
import { useEffect, useState } from "react";
import {
  ORDER_EMPTY,
  Order,
  OrderLine,
  OrderLineStatus,
  isOrderAuthorizationNeeded,
  isOrderLineDeclarationNeeded,
  orderLineStatusDescription,
  orderLineToHealth,
} from "store/data/order/order";
import txt from "helpers/text-helper";

import {
  CONNECT_ORDER_YEARS,
  MMOrderEntryInputProps,
} from "../order-entry/order-entry";
import { NO_IMAGE } from "components/products/product-card";
import {
  MMOrderLineProductInput,
  orderLinesToProducts,
} from "../order-entry/order-line-entry";
import { handDescription } from "store/data/scan/scan";
import MMSecureImage from "components/layouts/image/secure-image";

import MMOrderProductSelectDetails from "../order-entry/inputs/order-product-select-details";
import { productOption } from "helpers/product-helper";

import { updateField } from "hoc/helper-hooks";
import {
  COSTING_EMPTY,
  Costing,
  ORDER_COSTING_EMPTY,
  OrderCosting,
  OrderCostingType,
} from "store/data/costing/costing";
import MMCostingContract from "./inputs/costing-contract";
import {
  Contract,
  toAGPHDescription,
  toAPGHForOrderLine,
} from "store/data/contract/contract";
import { Client } from "store/data/client/client";
import { ApiResponse, ApiResponseStatus } from "api/api-helper";
import StringHelper from "helpers/string-helper";
import { VlotInvoiceDetails } from "store/data/invoices/vlot-invoice-details";
import MMCostingDetails from "./inputs/costing-details";
import DateHelper from "helpers/date-helper";
import MMCostingType from "./inputs/costing-type";
import { AttachedToType, File } from "store/data/file/file";
import MMFiles from "components/layouts/files/files";
import ConnectAPIHelper from "api/connect-api-helper";
import { useDispatch } from "react-redux";
import { toastAdd } from "store/components/toast/toast";
import { FileType } from "helpers/file-upload-helper";
import MMFileUpload from "components/layouts/input/file-upload";
import MMCostingAmounts from "./inputs/costing-amounts";
import { UzoviCode } from "store/data/insurer/insurer";
import MMPdfQuotation from "../pdf/pdf-quotation";
import ClientsAPIHelper from "api/clients-api.helper";
import { OrderInputs } from "../order-entry/order-inputs";
import AuthenticationHelper from "helpers/authentication-helper";
import {
  toBlankRationale,
  toCleanRationale,
  toRationale,
} from "./costing-rationale-generators";

export interface MMOption {
  id: string;
  label: string;
}

export interface CostingChangeHandler {
  (costing: Costing, orderLineIndex: number): void;
}
export interface MMOrderLineCostingFieldProps {
  order: Order | null;
  orderLineIndex: number;
  orderLineProduct?: MMOrderLineProductInput;
  costing?: Costing;
  contracts?: Contract[];
  onChange: CostingChangeHandler;
  maxHeight?: string;
  isEditable: boolean;
  hasValidation?: boolean;
  inputs?: OrderInputs;
  baseArticleCode?: string;
  vlotInvoiceDetails?: VlotInvoiceDetails;
}

const toOrderLineCards = (order?: Order | null, products?: any[]) => {
  return order && order.order_lines && order.order_lines.length > 0
    ? orderLinesToProducts(order.order_lines, products || []).filter(
        (orderLineProduct: MMOrderLineProductInput) => !!orderLineProduct.code
      )
    : [];
};

const orderRef = document.getElementById("order-entry") || undefined;

function MMCosting(props: MMOrderEntryInputProps) {
  const api: ConnectAPIHelper = new ConnectAPIHelper();
  const dispatch = useDispatch();

  const lang: string = txt.lang();
  const [order, setOrder] = useState<Order | null | undefined>(props.order);
  const [client, setClient] = useState<Client | null | undefined>();
  const [insuranceDetails, setInsuranceDetails] = useState<any>();
  const [insuranceDetailsError, setInsuranceDetailsError] = useState<string>();
  const [isEditable, setIsEditable] = useState<boolean>(props.isEditable);
  const [canEditInvoiced, setCanEditInvoiced] = useState<boolean>(false);
  const [currentProductOption, setCurrentProductOption] = useState<any | null>(
    null
  );
  const [isDeclarationTypeSelectOpen, setIsDeclarationTypeSelectOpen] =
    useState<boolean>(false);

  const [isRationaleNeeded, setIsRationaleNeeded] = useState<boolean>(false);

  const [visibleIndices, setVisibleIndices] = useState<number[]>(
    props.visibleIndices || []
  );
  const [orderCostingNotes, setOrderCostingNotes] = useState<string>(
    props.order?.costing?.notes || ""
  );
  const [isRationaleOpen, setIsRationaleOpen] = useState<boolean>(false);
  const [rationale, setRationale] = useState<string>(
    props.order?.costing?.rationale || ""
  );
  const [isQuotationPdfOpen, setIsQuotationPdfOpen] = useState<boolean>(false);
  const [isFilesOpen, setIsFilesOpen] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);
  const [fileToAdd, setFileToAdd] = useState<File>();

  const [uzoviCode, setUzoviCode] = useState<UzoviCode>();

  const [orderLineProducts, setOrderLineProducts] = useState<
    MMOrderLineProductInput[]
  >(toOrderLineCards(props.order, props.products || []));

  const [currentHandledOrderLineProduct, setCurrentHandledOrderLineProduct] =
    useState<number | null>(null);

  const [contracts, setContracts] = useState<Contract[]>([]);

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      setCanEditInvoiced(
        await AuthenticationHelper.hasPermission("costings#edit_invoiced_all")
      );
    };
    const currentOrderLines: any[] = toOrderLineCards(
      props.order,
      props.products || []
    );
    setOrderLineProducts(currentOrderLines);
    setVisibleIndices((oldIndices: number[]) => props.visibleIndices || []);
    setOrder(props.order);
    setIsEditable(props.isEditable);
    setOrderCostingNotes(props.order?.costing?.notes || "");
    setRationale(props.order?.costing?.rationale || "");
    setIsRationaleNeeded(
      props.order ? isOrderAuthorizationNeeded(props.order) : false
    );
    setFiles(props.order?.costing?.files || []);

    const loadClientAddress = async (client?: Client | null) => {
      if (client && !client.city && client.id) {
        let clientApi: ClientsAPIHelper = new ClientsAPIHelper();
        const clientAddress: ApiResponse = await clientApi.getClient(client.id);
        if (clientAddress && clientAddress.status === ApiResponseStatus.OK) {
          console.log("setting full client", clientAddress.result);
          setClient(clientAddress.result);
        }
      }
    };
    loadClientAddress(props.client);
    setInterfaceForPermissions();
  }, [
    props.order,
    props.products,
    props.visibleIndices,
    props.client,
    props.isEditable,
  ]);

  useEffect(() => {
    const loadContracts = async (client: Client) => {
      if (!client.id) return;
      const insuranceDetailsResult: ApiResponse =
        await props.clientApi?.getInsuranceDetails(client.id);
      if (
        insuranceDetailsResult &&
        insuranceDetailsResult.status === ApiResponseStatus.OK
      ) {
        if (
          !insuranceDetailsResult.result ||
          !insuranceDetailsResult.result.id
        ) {
          setInsuranceDetails(null);
          txt.get("orders.order.costing.no_client_insurance");
        } else {
          setInsuranceDetails(insuranceDetailsResult.result);
          setInsuranceDetailsError("");

          if (props.api) {
            let filters: any = {
              uzovi_codes: insuranceDetailsResult.result.insurer_uzovi_code,
            };
            if (order && order.ordered_at) {
              let year: string = DateHelper.format(order.ordered_at, "yyyy");
              if (CONNECT_ORDER_YEARS.includes(year)) {
                filters.years = [year];
              }
            }
            // const baseArticleCodes: string[] = order
            //   ? order.order_lines.map((line: OrderLine) =>
            //       line.code.substring(0, line.code.indexOf("-"))
            //     )
            //   : [];
            // if (baseArticleCodes.length > 0) {
            //   filters.base_article_codes = baseArticleCodes;
            // }
            const contractResult: ApiResponse =
              await props.api.getAdminContracts(
                filters,
                300,
                0,
                "base_article_code",
                "asc"
              );
            if (
              contractResult &&
              contractResult.status === ApiResponseStatus.OK
            ) {
              setContracts(contractResult.result);
            } else {
              console.log("Error with contracts", contractResult.message);
            }

            const uzoviCodeResult: UzoviCode[] =
              await props.api.getAdminUzoviCodes({
                uzovi_code: insuranceDetailsResult.result.insurer_uzovi_code,
              });
            if (uzoviCodeResult && uzoviCodeResult.length > 0) {
              setUzoviCode(uzoviCodeResult[0]);
            } else {
              console.log("Error with uzoviCodes", uzoviCodeResult);
              setUzoviCode(undefined);
            }
          } else {
            console.log("NO API");
          }
        }
      } else {
        setInsuranceDetails(null);
        setInsuranceDetailsError(insuranceDetails?.message ?? "");
      }
    };
    if (client) {
      loadContracts(client);
    }
  }, [client, props.clientApi]);

  const renderImage = (product: any) =>
    product && product.product_photos && product.product_photos.length > 0 ? (
      <div style={{ borderRadius: "40px", overflow: "hidden" }}>
        <MMSecureImage
          allowFullScreen={false}
          thumbnailUrl={`${product.product_photos[0]}/thumb.png`}
          url={`${product.product_photos[0]}/thumb.png`}
          api={props.api}
        />
      </div>
    ) : (
      <div style={{ borderRadius: "40px", overflow: "hidden" }}>
        <EuiImage alt="No Image" className="image" src={NO_IMAGE} />
      </div>
    );

  const onChange: CostingChangeHandler = (
    costing: Costing,
    orderLineIndex: number
  ) => {
    console.log("costing input changed", costing, orderLineIndex);
    const updatedOrder: Order = { ...(order || ORDER_EMPTY) };

    updatedOrder.order_lines = updatedOrder.order_lines.map(
      (line: OrderLine, i: number) =>
        i === orderLineIndex ? { ...line, costing: costing } : { ...line }
    );
    if (props.onChange) {
      props.onChange(updatedOrder);
    }
  };

  useEffect(() => {
    if (fileToAdd && props.onChange && props.order) {
      let files: File[] = (props.order?.referral?.files || []).concat(
        fileToAdd ? [fileToAdd] : []
      );

      const updatedCosting: OrderCosting = updateField(
        {
          ...(order?.costing || ORDER_COSTING_EMPTY),
        },
        "files",
        files
      );

      props.onChange(
        updateField(order || ORDER_EMPTY, "costing", updatedCosting)
      );
      setFileToAdd(undefined);
    }
  }, [fileToAdd]);

  const handleAddedFile = (file: File) => {
    setFileToAdd((old) => file);
  };

  const handleDeleteFile = (fileId: number) => {
    const deleteFile = async (fileId: number) => {
      const result = await api.removeOrderFile(
        fileId,
        props.order?.id || undefined,
        props.order?.costing?.id || undefined
      );
      if (
        result &&
        props.onChange &&
        props.order &&
        props.order.costing &&
        props.order.costing.files
      ) {
        const files = props.order.costing.files.filter(
          (file: any) => file.id !== fileId
        );
        const updatedCosting: OrderCosting = updateField(
          {
            ...(order?.costing || ORDER_COSTING_EMPTY),
          },
          "files",
          files
        );
        props.onChange(updateField(order, "costing", updatedCosting));
      } else {
        dispatch(
          toastAdd(txt.get("orders.order.delete_file_failed"), null, "danger")
        );
      }
    };
    deleteFile(fileId);
  };

  const renderGPHForOrderLine = (
    orderLineProduct?: MMOrderLineProductInput
  ) => {
    if (
      orderLineProduct?.costing?.contract?.product_declaration_additional_code1
    ) {
      const aGPHForOrderLine: string = toAPGHForOrderLine({
        costing: orderLineProduct.costing,
        order_type: orderLineProduct.orderType,
        use: orderLineProduct.use ? orderLineProduct.use : undefined,
      });

      if (aGPHForOrderLine) {
        return (
          <EuiToolTip
            position="top"
            content={toAGPHDescription(aGPHForOrderLine)}
          >
            <EuiText size="xs">{aGPHForOrderLine} </EuiText>
          </EuiToolTip>
        );
      }
    }
    return "";
  };

  const renderCalculationInfo = (
    orderLineProductIndex: number,
    costing?: Costing,
    vlotInvoiceDetails?: VlotInvoiceDetails,
    orderLineProduct?: MMOrderLineProductInput
  ) => (
    <EuiFlexGroup
      gutterSize="xs"
      alignItems="center"
      style={{ cursor: "pointer" }}
      onClick={(e: any) => {
        console.log(
          "click",
          currentHandledOrderLineProduct,
          orderLineProductIndex,
          costing,
          vlotInvoiceDetails
        );
        setCurrentHandledOrderLineProduct(
          currentHandledOrderLineProduct === orderLineProductIndex
            ? null
            : orderLineProductIndex
        );
      }}
    >
      {costing?.contract ? (
        <EuiFlexItem grow={false}>
          <EuiFlexGroup alignItems="center">
            <EuiFlexItem grow={false} style={{ maxWidth: "180px" }}>
              <EuiText
                size="xs"
                style={{
                  position: "relative",
                  whiteSpace: "nowrap",
                  fontWeight: "600",
                }}
              >
                {costing?.contract?.insurer?.acronym}

                <span style={{ position: "absolute", top: "-5px" }}>
                  <EuiButtonIcon
                    aria-label="costing details"
                    size="xs"
                    iconSize="s"
                    type="base"
                    iconType={
                      !vlotInvoiceDetails && isEditable ? "pencil" : "list"
                    }
                    onClick={(e: any) => {
                      setCurrentHandledOrderLineProduct(
                        currentHandledOrderLineProduct === orderLineProductIndex
                          ? null
                          : orderLineProductIndex || null
                      );
                    }}
                  />
                </span>
              </EuiText>
              <EuiText size="xs" style={{ whiteSpace: "nowrap" }}>
                {costing?.contract?.insurer_product_code
                  ? costing?.contract?.insurer_product_code
                      .split(",")
                      .join(", ")
                  : ""}
              </EuiText>
              <EuiText size="xs">
                {costing?.contract?.invoice_line_description}
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false} style={{ maxWidth: "180px" }}>
              <EuiText color="grey" size="xs" style={{ position: "relative" }}>
                {costing?.contract?.product_declaration_description || ""}
              </EuiText>
              <EuiText color="grey" size="xs" style={{ whiteSpace: "nowrap" }}>
                {costing?.contract?.product_declaration_code || ""}
              </EuiText>

              <EuiText color="grey" size="xs" style={{}}>
                {renderGPHForOrderLine(orderLineProduct)}
              </EuiText>
              <EuiText color="grey" size="xs" style={{}}>
                {costing.contract.product_declaration_additional_code2
                  ? costing.contract.product_declaration_additional_code2
                      .split(",")
                      .map((codePart: string, i: number) => (
                        <EuiToolTip
                          key={`code-${i}`}
                          position="top"
                          content={toAGPHDescription(codePart)}
                        >
                          <EuiText size="xs">{codePart} </EuiText>
                        </EuiToolTip>
                      ))
                  : ""}
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      ) : (
        <EuiFlexItem>
          <EuiButton
            size="s"
            iconType={"pencil"}
            iconSize="s"
            iconSide="right"
            // style={{ position: "relative", color: "#303030" }}
          >
            {txt.uf("generic.select_x", txt.get("admin.contracts.name"))}
          </EuiButton>
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  );

  const saveRationale = (rationale: string) => {
    if (props.onChange) {
      console.log("saveRationale", rationale);
      const newCosting: OrderCosting = updateField(
        order?.costing || ORDER_COSTING_EMPTY,
        "rationale",
        rationale
      );
      props.onChange(updateField(order, "costing", newCosting));
    } else {
      setRationale(rationale);
    }
  };

  const renderRationale = () =>
    isRationaleOpen ? (
      <EuiModal
        style={{ backgroundColor: "#eeeeee" }}
        onClose={(e: any) => {
          saveRationale(rationale);
          setIsRationaleOpen(false);
        }}
        initialFocus="[name=popswitch]"
        className="order-quote-rationale-modal"
      >
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <EuiTitle size="xs">
              <EuiText color="gray">
                {txt.get("orders.order.costing.rationale.name")}
              </EuiText>
            </EuiTitle>
          </EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          <EuiFlexGrid>
            {props.hasValidation ? (
              <EuiText color="danger">
                {txt.get(
                  "generic.fill_out_x",
                  txt.get("orders.order.costing.rationale.name")
                )}
              </EuiText>
            ) : (
              <></>
            )}
            <EuiFlexItem grow={false}>
              <EuiFlexGroup>
                <EuiFlexItem grow={false}>
                  <EuiButton
                    onClick={(e: any) => {
                      setRationale(toRationale(order, client, props.products));
                    }}
                  >
                    {txt.get("orders.order.costing.rationale.generate")}
                  </EuiButton>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButton
                    onClick={(e: any) => {
                      setRationale(toBlankRationale(order, client));
                    }}
                  >
                    {txt.get("orders.order.costing.rationale.blank")}
                  </EuiButton>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButton
                    onClick={(e: any) => {
                      setRationale(
                        toCleanRationale(order, client, props.products)
                      );
                    }}
                  >
                    {txt.get("orders.order.costing.rationale.clean")}
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem
              style={{
                maxHeight: "500px",
                height: props.hasValidation
                  ? "calc(100vh - 540px)"
                  : "calc(100vh - 490px)",
                width: "850px",
                marginBottom: "20px",
              }}
            >
              <EuiFlexGroup gutterSize="xs">
                <EuiFlexItem>
                  <EuiMarkdownEditor
                    style={{
                      maxHeight: "500px",
                    }}
                    aria-label={txt.get("orders.order.costing.rationale.name")}
                    value={rationale}
                    onChange={(changedRationale: string) => {
                      setRationale(changedRationale);
                    }}
                    height={"full"}
                    initialViewMode="editing"
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiFlexGrid> </EuiFlexGrid>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFlexGroup justifyContent="flexEnd">
                <EuiFlexItem grow={false}>
                  <EuiButton
                    onClick={(e: any) => {
                      saveRationale(rationale);
                      setIsRationaleOpen(false);
                    }}
                  >
                    {txt.get("generic.close")}
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGrid>
        </EuiModalBody>
      </EuiModal>
    ) : (
      <></>
    );

  const renderQuotationPdf = () =>
    isQuotationPdfOpen && order && client ? (
      <EuiModal
        style={{
          backgroundColor: "#fffffff",
          maxWidth: "90vw",
          maxHeight: "80vh",
          width: "90vw",
          height: "90vh",
        }}
        onClose={(e) => setIsQuotationPdfOpen(false)}
        initialFocus="[name=popswitch]"
        className="pdf-preview"
      >
        <MMPdfQuotation
          order={order}
          client={client}
          uzoviCode={uzoviCode}
          insuranceDetails={insuranceDetails}
        />
      </EuiModal>
    ) : (
      <></>
    );

  const renderCalculation = (orderLineProduct: MMOrderLineProductInput) => {
    const costing: Costing | null = orderLineProduct.costing || null;
    const vlotInvoiceDetails: VlotInvoiceDetails | null =
      orderLineProduct.vlot_invoice_details || null;

    return (
      <EuiPopover
        container={orderRef}
        zIndex={6000}
        // anchorPosition="downLeft"
        // offset={140}
        panelPaddingSize="l"
        closePopover={() => {
          setCurrentHandledOrderLineProduct(null);
        }}
        isOpen={currentHandledOrderLineProduct === orderLineProduct.index}
        button={renderCalculationInfo(
          orderLineProduct.index !== undefined ? orderLineProduct.index : -1,
          costing || undefined,
          vlotInvoiceDetails || undefined,
          orderLineProduct || undefined
        )}
      >
        {order && orderLineProduct.index !== null ? (
          <EuiFlexGrid
            className="delivery-details"
            style={{ maxHeight: "450px", maxWidth: "750px" }}
          >
            <EuiFlexItem>
              <MMCostingContract
                order={order}
                baseArticleCode={
                  orderLineProduct.code
                    ? orderLineProduct.code.substring(
                        0,
                        orderLineProduct.code.indexOf("-")
                      )
                    : ""
                }
                orderLineIndex={orderLineProduct.index}
                contracts={contracts}
                costing={costing || undefined}
                onChange={onChange}
                isEditable={
                  orderLineProduct.costing?.is_invoiced
                    ? canEditInvoiced
                    : isEditable
                }
              />
            </EuiFlexItem>
          </EuiFlexGrid>
        ) : (
          <></>
        )}
      </EuiPopover>
    );
  };

  //double now, also in costingamounts....
  const renderResetButton = (
    value: string,
    field: string,
    type: "currency" | "boolean",
    orderLineProductIndex?: number,
    costing?: any,
    vlotInvoiceDetails?: VlotInvoiceDetails
  ) =>
    costing &&
    costing[value] &&
    costing.contract &&
    costing[value] !== costing?.contract[field] ? (
      <EuiButtonEmpty
        isDisabled={!!vlotInvoiceDetails || !isEditable}
        color="warning"
        onClick={() => {
          onChange(
            updateField(
              costing || COSTING_EMPTY,
              value,
              costing?.contract[field]
            ),
            orderLineProductIndex !== undefined
              ? orderLineProductIndex
              : -1 /*hmmmm*/
          );
        }}
        size="xs"
        iconSide="right"
        iconType={!vlotInvoiceDetails && isEditable ? "editorUndo" : ""}
      >
        {txt.get(
          "orders.order.costing.amount_different",
          type === "currency"
            ? StringHelper.currency(costing?.contract[field] || 0)
            : type === "boolean"
              ? txt.get(
                  `generic.${
                    costing?.contract[field] === true
                      ? "yes"
                      : costing?.contract[field] === false
                        ? "no"
                        : "unknown"
                  }`
                )
              : costing?.contract[field] + ""
        )}
      </EuiButtonEmpty>
    ) : (
      <EuiButtonEmpty size="xs" style={{ opacity: 0, pointerEvents: "none" }} />
    );

  const renderProductInfo = (orderLineProduct: MMOrderLineProductInput) => {
    return (
      <EuiFlexItem
        style={{ cursor: "pointer" }}
        onClick={() => {
          selectProductOption(orderLineProduct);
        }}
      >
        <EuiText
          size="xs"
          style={{
            // whiteSpace: "nowrap",
            maxWidth: "160px",
            position: "relative",
          }}
        >
          {orderLineProduct.name || ""}
        </EuiText>
        <EuiText size="xs" style={{ position: "relative" }}>
          {orderLineProduct.code || ""}{" "}
          {orderLineProduct.hand ? handDescription(orderLineProduct.hand) : ""}
          {orderLineProduct.digits
            ? ` ${orderLineProduct.digits.join(",")}`
            : ""}
        </EuiText>
        <EuiText size="xs" color="grey" style={{ whiteSpace: "nowrap" }}>
          {" "}
          {orderLineProduct.orderType ? orderLineProduct.orderType : ""}{" "}
          {orderLineProduct.status &&
          orderLineProduct.status !== OrderLineStatus.Drafted ? (
            <EuiBadge color={orderLineToHealth(orderLineProduct.status)}>
              {orderLineStatusDescription(orderLineProduct.status)}
            </EuiBadge>
          ) : (
            <></>
          )}
        </EuiText>
      </EuiFlexItem>
    );
  };

  const renderAuthorizationButtons = (
    orderLineProduct: MMOrderLineProductInput
  ) => {
    const costing: Costing | null = orderLineProduct.costing || null;
    const vlotInvoiceDetails: VlotInvoiceDetails | null =
      orderLineProduct.vlot_invoice_details || null;

    return (
      <EuiFormRow
        style={{ position: "relative", top: "4px" }}
        label={txt.get("orders.order.costing.request_authorization")}
        helpText={
          order?.costing?.type === OrderCostingType.InvoicePrivate ||
          (orderLineProduct.orderType &&
            !isOrderLineDeclarationNeeded(orderLineProduct.orderType)) ? (
            <></>
          ) : (
            renderResetButton(
              "authorization",
              "authorization",
              "boolean",
              orderLineProduct.index,
              costing || undefined,
              vlotInvoiceDetails || undefined
            )
          )
        }
      >
        <EuiFilterGroup contentEditable={false} compressed={true}>
          <EuiFilterButton
            grow={true}
            isDisabled={
              orderLineProduct.costing?.is_invoiced
                ? !canEditInvoiced
                : !isEditable ||
                  (!costing?.contract &&
                    !(order?.costing?.type === OrderCostingType.InvoicePrivate))
            }
            // className="danger"
            // color="danger"
            hasActiveFilters={costing?.authorization === false}
            onClick={(e: any) => {
              onChange(
                updateField(
                  costing || COSTING_EMPTY,
                  "authorization",
                  costing?.authorization === false ? null : false
                ),
                orderLineProduct.index !== undefined
                  ? orderLineProduct.index
                  : -1 /*hmmmm*/
              );
            }}
          >
            {txt.get("generic.no")}
          </EuiFilterButton>
          <EuiFilterButton
            isDisabled={
              orderLineProduct.costing?.is_invoiced
                ? !canEditInvoiced
                : !isEditable ||
                  (!costing?.contract &&
                    !(order?.costing?.type === OrderCostingType.InvoicePrivate))
            }
            // className="success"
            // color="success"
            hasActiveFilters={costing?.authorization === true}
            onClick={(e: any) => {
              onChange(
                updateField(
                  costing || COSTING_EMPTY,
                  "authorization",
                  costing?.authorization === true ? null : true
                ),
                orderLineProduct.index !== undefined
                  ? orderLineProduct.index
                  : -1 /*hmmmm*/
              );
            }}
          >
            {txt.get("generic.yes")}
          </EuiFilterButton>
        </EuiFilterGroup>
      </EuiFormRow>
    );
  };
  const columns = () => {
    let columns: any[] = [
      {
        name: txt.get("orders.order.product"),
        field: "id",
        render: (id: string, orderLineProduct: MMOrderLineProductInput) =>
          renderProductInfo(orderLineProduct),
      },
    ];
    if (
      order &&
      order.costing &&
      order.costing.type === OrderCostingType.InvoicePrivate
    ) {
      //we don't show the calculation for now
    } else {
      columns.push({
        name: txt.get("admin.contracts.name"),
        field: "id",
        render: (id: string, orderLineProduct: MMOrderLineProductInput) =>
          renderCalculation(orderLineProduct),
      });
    }
    columns = columns.concat([
      {
        name: (
          <EuiText size="s">
            {txt.get("orders.order.costing.amount")}
            <EuiText size="xs" style={{ display: "inline" }}>
              {" "}
              {txt.lo("pdfs.quotation.vat_inclusive")}{" "}
              {txt.lo("pdfs.quotation.vat")}
            </EuiText>
          </EuiText>
        ),
        field: "id",
        render: (id: string, orderLineProduct: MMOrderLineProductInput) => (
          <MMCostingAmounts
            isEditable={
              orderLineProduct.costing?.is_invoiced
                ? canEditInvoiced
                : isEditable
            }
            inputs={props.inputs}
            onChange={onChange}
            hasValidation={props.hasValidation}
            order={order || null}
            costing={orderLineProduct.costing || undefined}
            orderLineIndex={orderLineProduct.index}
            orderLineProduct={orderLineProduct}
          />
        ),
      },
      {
        name: txt.get("orders.order.costing.authorization_needed"),
        field: "id",
        render: (id: string, orderLineProduct: MMOrderLineProductInput) =>
          renderAuthorizationButtons(orderLineProduct),
      },
      {
        name: txt.get("orders.order.costing.handled"),
        field: "id",
        render: (id: string, orderLineProduct: MMOrderLineProductInput) => (
          <MMCostingDetails
            costing={orderLineProduct.costing}
            orderLineIndex={orderLineProduct.index}
            orderLineProduct={orderLineProduct}
            onChange={onChange}
          />
        ),
      },
    ]);
    return columns;
  };

  const selectProductOption = (orderLineProduct: MMOrderLineProductInput) => {
    setCurrentProductOption(
      productOption(orderLineProduct.variation, lang, orderLineProduct)
    );
  };

  const renderProductSelectDetail = (currentProductOption: any) => {
    return currentProductOption && props.api && order ? (
      <MMOrderProductSelectDetails
        api={props.api}
        order={order}
        products={props.products || []}
        // selection={{ ...EMPTY_PRODUCT_INPUT, name: currentProductOption.value }}
        productOption={currentProductOption}
        onCancel={() => setCurrentProductOption(null)}
        onSelect={undefined}
        onDelete={undefined}
        isEditable={isEditable}
        orderTypes={props.orderTypes}
        remakeReasons={props.remakeReasons}
        clientProductions={props.clientProductions}
        showProductionDetails={props.showProductionDetails}
      />
    ) : (
      <></>
    );
  };

  const renderDeclarationTypeSelect = () => (
    <EuiPopover
      container={orderRef}
      zIndex={6000}
      anchorPosition="downRight"
      panelPaddingSize="l"
      closePopover={() => {
        setIsDeclarationTypeSelectOpen(false);
      }}
      isOpen={isDeclarationTypeSelectOpen}
      button={
        <EuiButtonIcon
          aria-label="calculation"
          iconType="pencil"
          size="xs"
          iconSize="s"
          onClick={(e: any) => {
            setIsDeclarationTypeSelectOpen(!isDeclarationTypeSelectOpen);
          }}
          // style={{ position: "absolute", top: "0px", right: "-10px" }}
        />
      }
    >
      <MMCostingType
        order={order}
        onChange={(order: Order) => {
          if (props.onChange) props.onChange(order);
          setIsDeclarationTypeSelectOpen(false);
        }}
      />
    </EuiPopover>
  );

  const renderFiles = () => (
    <EuiPopover
      zIndex={6000}
      isOpen={isFilesOpen}
      closePopover={() => {
        setIsFilesOpen(false);
      }}
      button={
        <EuiButtonEmpty
          onClick={(e: any) => {
            setIsFilesOpen(!isFilesOpen);
          }}
          iconSide="right"
          iconSize="s"
          iconType={"paperClip"}
          color="text"
          style={{ color: "#909090" }}
        >
          {txt.get("orders.order.attachments.name")} ({files.length})
        </EuiButtonEmpty>
      }
    >
      <EuiFlexGroup
        gutterSize="none"
        direction="column"
        style={{ width: "220px" }}
      >
        {files.length > 0 ? (
          <EuiFlexItem>
            <MMFiles
              detailedPreview={true}
              files={files}
              onDelete={props.isEditable ? handleDeleteFile : undefined}
            />
          </EuiFlexItem>
        ) : (
          <></>
        )}
        <EuiSpacer size="s" />
        <EuiFlexItem>
          <EuiFlexGroup direction="column">
            <EuiFlexItem>
              <MMFileUpload
                multiple={true}
                fileTypes={[
                  FileType.Docx,
                  FileType.Image,
                  FileType.Pdf,
                  FileType.Text,
                ]}
                attachmentOptions={{
                  orderId: props.order?.id,
                  attachedToId: props.order?.costing?.id || undefined,
                  attachedToType: AttachedToType.OrderCosting,
                }}
                onAdded={handleAddedFile}
                // title={
                //   files.length === 0
                //     ? txt.get("referrals.upload_letter")
                //     : undefined
                // }
                size={"small"}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPopover>
  );
  return (
    <EuiFlexGroup
      direction="column"
      gutterSize="none"
      // style={{ paddingLeft: "20px" }}
    >
      <EuiFlexItem
        grow={false}
        style={{
          paddingLeft: "20px",
          paddingTop: "20px",
          paddingBottom: "15px",
          backgroundColor: "#ffffff",
        }}
      >
        <EuiFlexGroup alignItems="center">
          <EuiFlexItem grow={true}>
            <EuiTitle size="xs">
              <EuiText color="grey" style={{ position: "relative" }}>
                {order &&
                order.costing &&
                order.costing.type === OrderCostingType.InvoicePrivate
                  ? txt.get("orders.order.costing.calculation_private")
                  : txt.get("orders.order.costing.calculation")}

                {renderDeclarationTypeSelect()}
              </EuiText>
            </EuiTitle>
            {order &&
            order.costing &&
            order.costing.type === OrderCostingType.InvoicePrivate ? (
              <EuiFieldText
                compressed={true}
                placeholder={`${txt.get("orders.order.costing.notes")}...`}
                value={orderCostingNotes}
                onChange={(e: any) => {
                  setOrderCostingNotes(e.target.value);
                }}
                onBlur={(e: any) => {
                  if (props.onChange) {
                    props.onChange(
                      updateField(
                        order || ORDER_EMPTY,
                        "costing",
                        updateField(
                          order.costing || ORDER_COSTING_EMPTY,
                          "notes",
                          e.target.value
                        )
                      )
                    );
                  }
                }}
              />
            ) : client && client && insuranceDetails ? (
              <EuiText size="s">
                {insuranceDetails.insurer_name} (
                {insuranceDetails.insurer_uzovi_code})
              </EuiText>
            ) : insuranceDetailsError ? (
              <EuiText size="s">{insuranceDetailsError}</EuiText>
            ) : (
              <EuiText size="s">...</EuiText>
            )}
          </EuiFlexItem>

          <EuiFlexItem
            grow={false}
            style={{
              alignItems: "flex-end",
              justifyContent: "flex-start",
              marginRight: "20px",
            }}
          >
            {renderFiles()}
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiFlexItem
        grow={false}
        style={{
          paddingLeft: "18px",
          paddingRight: "9px",
          backgroundColor: "#ffffff",
        }}
      >
        <EuiBasicTable
          compressed={true}
          className="rows-paper"
          style={{
            overflow: "auto",
            maxHeight: "calc(100vh - 540px)",
          }}
          tableLayout="auto"
          itemId="index"
          items={orderLineProducts}
          columns={columns()}
          noItemsMessage={txt.uf(
            "generic.found_no_x",
            txt.get("orders.order.order_line.name")
          )}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiFlexGroup style={{ marginTop: "10px" }}>
          <EuiFlexItem grow={false}>
            {isRationaleNeeded ? (
              <EuiButton
                fill={true}
                size="s"
                color={
                  props.hasValidation &&
                  props.inputs?.costing?.rationale?.validator &&
                  !props.inputs.costing.rationale.validator(
                    order?.costing?.rationale
                  ).result
                    ? "danger"
                    : "accent"
                }
                iconType={
                  order?.costing?.rationale && order.costing.rationale !== ""
                    ? "check"
                    : "plus"
                }
                onClick={() => {
                  setIsRationaleOpen(!isRationaleOpen);
                }}
              >
                {txt.get("orders.order.costing.rationale.name")}
              </EuiButton>
            ) : (
              <EuiButtonEmpty
                color={"text"}
                iconType={
                  order?.costing?.rationale && order.costing.rationale !== ""
                    ? "check"
                    : "plus"
                }
                onClick={() => {
                  setIsRationaleOpen(!isRationaleOpen);
                }}
              >
                {txt.get("orders.order.costing.rationale.name")}
              </EuiButtonEmpty>
            )}
          </EuiFlexItem>
          <EuiFlexItem grow={true}></EuiFlexItem>
          <EuiFlexItem style={{ justifySelf: "flex-end" }} grow={false}>
            <EuiButton
              size="s"
              isDisabled={!(order && client)}
              iconType={"download"}
              iconSide="right"
              onClick={(e: any) => {
                setIsQuotationPdfOpen(true);
              }}
            >
              {txt.get("generic.download")}
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      {renderProductSelectDetail(currentProductOption)}
      {renderRationale()}
      {renderQuotationPdf()}
    </EuiFlexGroup>
  );
}

export default MMCosting;
