import {
  EuiButton,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiIcon,
  EuiLoadingSpinner,
  EuiPageBody,
  EuiPopover,
  EuiTab,
  EuiTabs,
  EuiText,
  EuiTitle,
  useIsWithinBreakpoints,
} from "@elastic/eui";
import { v4 as uuid } from "uuid";

import { useState, Fragment, useEffect } from "react";
import {
  ORDER_EMPTY,
  Order,
  OrderLine,
  OrderLineStatus,
  OrderStatus,
  OrderStatusAction,
  isOrderLineDeclarationNeeded,
  orderStatusFromAction,
  orderToApiOrderInfo,
} from "store/data/order/order";
import {
  ORGANISATION_EMPTY,
  Organisation,
} from "store/data/organisation/organisation";
import { PersonalDetails } from "store/data/personal-details/personal-details";
import {
  OrderInputs,
  OrderLineInputs,
  VALIDATION_VISIBILITY_NONE,
  ValidationVisibility,
  determineOrderInputs,
  finalNumberCorrection,
  isClientInputValid,
  isMeasurementsInputValid,
  isNeedsInputValid,
  isOrderCostingInputValid,
  isOrderInputValid,
  isOrderLineCostingInputValid,
  isOrderLinesInputValid,
  isReferralInputValid,
  isReferrerInputValid,
  isWhoWhereInputValid,
} from "./order-inputs";
import ConnectAPIHelper from "api/connect-api-helper";
import ClientsAPIHelper from "api/clients-api.helper";
import AuthenticationHelper from "helpers/authentication-helper";
import txt from "helpers/text-helper";
import MMOrderInputWhoWhere from "./inputs/order-who-where";
import MMOrderClient from "./inputs/order-client";
import MMOrderReferral from "./inputs/order-referral";
import UrlHelper from "helpers/url-helper";
import MMOrderLineEntry, {
  MMOrderLineProductInput,
  orderLinesToProducts,
} from "./order-line-entry";
import { trackEvent } from "helpers/analytics-helper";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toastAdd } from "store/components/toast/toast";
import { useDebounce } from "use-debounce";
import MMOrderScans from "../order-scans";
import MMOrderAttachments from "./inputs/order-attachments";
import MMOrderStatus from "./order-status";
import MMOrderReferrer from "./inputs/order-referrer";
import { Referrer } from "store/data/referrer/referrer";
import MMOrderIndication from "./inputs/order-indication";
import MMOrderNeeds from "./inputs/order-needs";
import MMOrderProductSelect from "./inputs/order-product-select";
import MMOrderLineCards from "./order-line-cards";
import { OnProductSelect } from "components/products/product-cards";
import { OrderNavigate } from "../order-popover";
import {
  acceptPermitted,
  cancelPermitted,
  configuringPermitted,
  correctionsPermitted,
  deletePermitted,
  deliverPermitted,
  downloadPermitted,
  finalizePermitted,
  isAccepter,
  rejectPermitted,
  savePermitted,
  submitPermitted,
} from "./order-permissions";
import {
  AlertConfirmActionState,
  AlertInputValueType,
  confirmAsk,
  confirmGet,
} from "store/components/alert/confirm";
import DateHelper from "helpers/date-helper";
import { Hand } from "store/data/scan/scan";
import FileUploadHelper from "helpers/file-upload-helper";
import generateOrderPDF from "../pdf/pdf";
import { format } from "date-fns";
import { AssignedDevice, AssignedDeviceDetails } from "store/data/scan/scanner";
import { Location } from "store/data/location/location";
import snakecaseKeys from "snakecase-keys";
import MMOrderStatusLogs from "../order-log";
import { Input } from "helpers/input-helper";
import MMOrderLineDelivery from "../order-delivery/order-line-delivery";
import { Fitting } from "store/data/fitting/fitting";
import { isAllFittingHandled } from "../order-delivery/fitting-inputs";
import { Workflow } from "store/data/production/workflow";
import { Client } from "store/data/client/client";
import { ApiResponse, ApiResponseStatus } from "api/api-helper";
import MMCosting from "../order-costing/costing";
import { COSTING_EMPTY, Costing } from "store/data/costing/costing";
import { Contract } from "store/data/contract/contract";
import {
  REFERRAL_EMPTY,
  Referral,
  ReferralStatus,
} from "store/data/referral/referral";
import MMOrderCompletion from "./order-completion";
import MMOrderIntake from "./inputs/order-intake";
import { feat, Feature } from "feats";
import ManoXAPIHelper from "api/manox-api-helper";

const api: ConnectAPIHelper = new ConnectAPIHelper();
const clientApi: ClientsAPIHelper = new ClientsAPIHelper();
const manoxApi: ManoXAPIHelper = new ManoXAPIHelper();

export const toApiFormat = (result: any) => {
  return snakecaseKeys(JSON.parse(JSON.stringify(result)));
};
export interface MMOrderEntryProps {
  orderNavigate?: OrderNavigate;
  onOrderId?: Function;
  onOrderVersion?: Function;
  onOrderChange?: Function;
  order?: Order;
  isOrderChanged: boolean;
  orderTab?: string;
  products?: any[];
  isLoadingProducts?: boolean;
}

export interface OrderChangeHandler {
  (input: Order, findCosting?: boolean): void;
}

export enum FocusState {
  Loading = "LOADING",
  Client = "CLIENT",
  Order = "ORDER",
  Validating = "VALIDATING",
  Delivery = "DELIVERY",
}

export type FocusStateChange = (focusState: FocusState) => void;
//years that we have pricing for / orders for
export const CONNECT_ORDER_YEARS: string[] = ["2024", "2023"];

export interface MMOrderEntryInputProps {
  api?: ConnectAPIHelper;
  clientApi?: ClientsAPIHelper;
  order?: Order | null;
  inputs?: OrderInputs;
  visibleIndices?: number[];
  popoverContainer?: any;
  personalDetails?: PersonalDetails | null;
  organisation?: Organisation | null;
  client?: Client | null;
  referrer?: Referrer | null;
  products?: any[];
  orderTypes?: any[];
  remakeReasons?: any[];
  incompleteReasons?: any[];
  communicationReasons?: any[];
  practitioners?: any[];
  organisations?: any[];
  locations?: any[];
  deliveryMethods?: any[];
  workflows?: Workflow[];
  workflowNames?: any[];
  clientProductions?: any[];
  clientReferrals?: Referral[];
  onChange?: OrderChangeHandler;
  onProductSelect?: OnProductSelect;
  showValidation?: boolean; //force show of interface group
  hasValidation?: boolean; //show the validation error
  measurementsOnly?: boolean;
  hideNonOptions?: boolean;
  measurementsShown?: string[];
  focusState?: FocusState;
  changeFocusState?: FocusStateChange;
  maxHeight?: string;
  showProductionDetails?: boolean;
  onScanInstructionsChange?: Function;
  isEditable: boolean;
}

export const nameSorter = (a: any, b: any) =>
  a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
export const fullnameSorter = (a: any, b: any) =>
  a.firstName + a.lastName < b.firstName + b.lastName
    ? -1
    : a.firstName + a.lastName > b.firstName + b.lastName
      ? 1
      : 0;

function MMOrderEntry(props: MMOrderEntryProps) {
  const isSmallScreen: boolean = useIsWithinBreakpoints(["xs", "s", "m", "l"]);
  const [order, setOrder] = useState<Order | null>(null);
  const [client, setClient] = useState<Client | null>(null);
  const [inputs, setInputs] = useState<OrderInputs>();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);

  const [changeStatusRef] = useState("update_order_status_" + uuid());
  const [deleteRef] = useState("delete_order_" + uuid());
  const [submitRef] = useState("submit_order_" + uuid());

  const [saveButtonsToShow, setSaveButtonsToShow] = useState<any[]>([]);
  const [orderStateButtonsToShow, setOrderStateButtonsToShow] = useState<any[]>(
    []
  );
  const [moreButtonsToShow, setMoreButtonsToShow] = useState<any[]>([]);
  const [canSeeLogs, setCanSeeLogs] = useState<boolean>(false);
  const [canSeeCosts, setCanSeeCosts] = useState<boolean>(false);
  const [canEditCosts, setCanEditCosts] = useState<boolean>(false);
  const [canEditOrderCompletion, setCanEditOrderCompletion] =
    useState<boolean>(false);
  const [isOrderLogOpen, setIsOrderLogOpen] = useState<boolean>(false);
  const [isScanInstructionsVisible, setIsScanInstructionsVisible] =
    useState<boolean>(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isCorrigable, setIsCorrigable] = useState(false);
  const [isConfigurable, setIsConfigurable] = useState(false);
  const [isAttachmentsLoading, setIsAttachmentsLoading] = useState(false);
  const [isScansVisible, setIsScansVisible] = useState(false);
  const [showAttachments, setShowAttachments] = useState<boolean>(false);
  const [showProductionDetails, setShowProductionDetails] = useState(false);

  const [focusState, setFocusState] = useState<FocusState>(FocusState.Loading);

  const [tabSelected, setTabSelected] = useState<string>(props.orderTab || "");
  const [measurementsTabRef, setMeasurementsTabRef] = useState<any | null>(
    null
  );
  const [showValidation, setShowValidation] = useState<ValidationVisibility>(
    VALIDATION_VISIBILITY_NONE
  );
  const [hasValidation, setHasValidation] = useState<ValidationVisibility>(
    VALIDATION_VISIBILITY_NONE
  );
  const [tabs, setTabs] = useState<string[]>([]);
  const [products, setProducts] = useState<any[]>([]);
  const [orderTypes, setOrderTypes] = useState<any[]>([]);
  const [remakeReasons, setRemakeReasons] = useState<any[]>([]);
  const [incompleteReasons, setIncompleteReasons] = useState<any[]>([]);
  const [communicationReasons, setCommunicationReasons] = useState<any[]>([]);
  const [practitioners, setPractitioners] = useState<any[]>([]);
  const [organisations, setOrganisations] = useState<any[]>([]);
  const [workflows, setWorkflows] = useState<Workflow[]>([]);
  const [workflowNames, setWorkflowNames] = useState<any[]>([]);

  const [currentClientCode] = useDebounce(order?.client_code, 700);
  const [clientProductions, setClientProductions] = useState<any[]>([]);
  const [clientReferrals, setClientReferrals] = useState<Referral[]>([]);

  const [locations, setLocations] = useState<any[]>([]);
  const [deliveryMethods, setDeliveryMethods] = useState<any[]>([]);

  const [organisation, setOrganisation] = useState<Organisation | null>(null);
  const [personalDetails, setPersonalDetails] =
    useState<PersonalDetails | null>(null);
  const [isSaveSelectOpen, setIsSaveSelectOpen] = useState<boolean>(false);
  const [isMoreSelectOpen, setIsMoreSelectOpen] = useState<boolean>(false);

  const [assignedDevices, setAssignedDevices] = useState<
    AssignedDeviceDetails[]
  >([]);
  const [measuringOrderLineIndex, setMeasuringOrderLineIndex] =
    useState<number>(0);
  const [saveOption, setSaveOption] = useState("save");
  //   const [saveOption, setSaveOption] = useLocalStorage(
  //   "order_save_option",
  //   "save"
  // );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getOrderedByCompany = () => {
    let result: Organisation = ORGANISATION_EMPTY;

    if (order && order.organisation_id && organisations && organisations.find) {
      result = organisations.find(
        (organisation) => organisation.id === order.organisation_id
      );
    }
    return result;
  };

  const navigateBack = () => {
    const lastVisited = localStorage.getItem("last_visited_orders_view");
    if (lastVisited === "orders") {
      navigate("/my-dashboard");
    } else if (lastVisited === "search") {
      navigate("/orders");
    } else {
      navigate("/my-dashboard");
    }
  };

  useEffect(() => {
    console.log("setting order from existing order");

    setOrder(
      props.order || {
        ...ORDER_EMPTY,
        is_demo: AuthenticationHelper.isDemo() === "1",
      }
    );

    if (focusState !== FocusState.Delivery) {
      setFocusState(
        !!props.order &&
          !!props.order.id &&
          (!focusState || focusState !== FocusState.Validating)
          ? FocusState.Order
          : FocusState.Loading
      );
    }

    // await handleOrder(props.order, focusState);
  }, [props.order]);

  useEffect(() => {
    setIsLoading(true);
    const load = async () => {
      const username: string = AuthenticationHelper.getUsername();
      const email: string = AuthenticationHelper.getEmail();
      let personalDetails = await api.getPersonalDetails(email || username);

      if (!personalDetails || personalDetails.length === 0) {
        setPersonalDetails(null);
        return;
      }
      personalDetails = personalDetails[0];
      setPersonalDetails(personalDetails);

      const orderValues: any = await api.getOrderValues();

      if (orderValues.types) {
        setOrderTypes(orderValues.types.sort(nameSorter));
      }
      if (orderValues.users) {
        setPractitioners(
          orderValues.users
            .filter(
              (user: any) =>
                user.isPractitioner ||
                (order && order.practitioner && order.practitioner === user.id)
            )
            .sort(fullnameSorter)
        );
      }
      if (orderValues.methods) {
        setDeliveryMethods(orderValues.methods.sort(nameSorter));
      }
      if (orderValues.locations) {
        setLocations(orderValues.locations);
      }
      if (orderValues.remake_reasons) {
        setRemakeReasons(orderValues.remake_reasons);
      }

      const organisations = await api.getOrganisations();
      setOrganisations(organisations);

      const workflows = await api.getWorkflows();
      setWorkflows(workflows && workflows.result ? workflows.result : []);
      setWorkflowNames(
        workflows && workflows.result
          ? workflows.result.map((workflow: Workflow) => workflow.name)
          : []
      );

      const organisation =
        personalDetails && personalDetails?.organisation
          ? personalDetails.organisation
          : personalDetails?.organisation_id
            ? await api.getOrganisation(
                AuthenticationHelper.getOrganisationId()
              )
            : null;

      let orderingOrganisation = null;
      if (
        order &&
        order.organisation_id &&
        organisations &&
        organisations.find
      ) {
        orderingOrganisation = organisations.find(
          (org: Organisation) => org.id === parseInt(order.organisation_id)
        );
      }
      if (!orderingOrganisation) {
        orderingOrganisation = organisation;
      }
      let ordering = orderingOrganisation
        ? [orderingOrganisation.ordering]
        : [];

      const communicationReasons: any[] = await api.getCommunicationReasons();
      setCommunicationReasons(communicationReasons);

      if (await AuthenticationHelper.hasPermission("orders#validate_all")) {
        const incompleteReasons: any[] = await api.getIncompleteReasons();
        setIncompleteReasons(incompleteReasons);
      }

      const isAcceptingRole: boolean =
        !!props.order &&
        !!props.order.id &&
        (await isAccepter(props.order.status, ordering));

      const showDeliver: boolean =
        !!props.order &&
        !!props.order.id &&
        (await deliverPermitted(props.order.status)) &&
        !isAcceptingRole;

      setFocusState(
        !!props.order && !!props.order.id
          ? showDeliver
            ? FocusState.Order
            : // FocusState.Delivery
              FocusState.Order
          : FocusState.Client
      );

      setShowProductionDetails(AuthenticationHelper.isInternalUser());

      if (organisation) {
        setOrganisation(organisation);
        if (!props.order || !props.order.id) {
          setOrder({
            ...ORDER_EMPTY,
            organisation: organisation,
            organisation_id: organisation.id,
          });
        }
      } else {
        setOrganisation(null);
        const inputs: OrderInputs = await determineOrderInputs(
          order,
          products,
          orderTypes
        );
        setInputs((old: OrderInputs | undefined) => inputs);
      }
      setIsLoading(false);
    };

    load();
  }, [props.order]);

  useEffect(() => {
    setIsLoadingProducts(props.isLoadingProducts || false);
    setProducts(props.products || []);
  }, [props.products, props.isLoadingProducts]);

  useEffect(() => {
    console.log("FocusState", focusState);
  }, [focusState]);

  useEffect(() => {
    const handleOrderInputs = async (order?: Order | null) => {
      // console.log("handleOrderInputs", order);
      if (order) {
        setInputs(await determineOrderInputs(order, products, orderTypes));
      }
    };

    handleOrderInputs(order);

    const handleClientSummary = async (order?: Order | null) => {
      if (
        order &&
        order.client_code &&
        (!client ||
          !client.client_code ||
          client.client_code.toString() !== order.client_code.toString())
      ) {
        const result: any = await clientApi.getClientSummaryByClientCode(
          order.client_code
        );
        console.log("getClientSummaryByClientCode", result);
        if (result && result.status === ApiResponseStatus.OK) {
          setClient(result.result.length > 0 ? result.result[0] : null);
        }
      }
    };

    handleClientSummary(order);
  }, [order, focusState, isLoading]);

  const duplicateOrder = async (orderId: number) => {
    setIsLoading(true);

    const result = await api.duplicateOrder(orderId);
    console.log("duplicateOrder", result);
    if (result && result.order_id) {
      dispatch(
        toastAdd(
          txt.get("generic.is_duplicated", txt.get("orders.order.name")),
          null,
          "success"
        )
      );
      if (props.orderNavigate) props.orderNavigate(result.order_id);
    } else {
      dispatch(toastAdd(txt.get("generic.error"), null, "danger"));
    }

    setIsLoading(false);
  };

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      console.log("setInterfaceForPermissions");
      let orderStateButtons: any[] = [];
      let saveButtons: any[] = [];
      let moreButtons: any[] = [];
      let status = order ? order.status : OrderStatus.Drafted;

      const canSeeCosts: boolean = await AuthenticationHelper.hasPermission([
        "invoices#read_all",
        "costings#read_all",
      ]);
      const canEditCosts: boolean = await AuthenticationHelper.hasPermission([
        "invoices#edit_all",
        "costings#edit_all",
      ]);

      setCanSeeLogs(
        await AuthenticationHelper.hasPermission([
          "order_status_logs#read",
          "order_status_logs#read_org",
          "order_status_logs#read_all",
        ])
      );
      setCanSeeCosts(canSeeCosts);
      setCanEditCosts(canEditCosts);
      setCanEditOrderCompletion(
        await AuthenticationHelper.hasPermission("orders#validate_all")
      );
      let isDemo: boolean = order
        ? order.is_demo
        : AuthenticationHelper.isDemo() === "1";

      let orderingOrganisation = null;

      if (
        order &&
        order.organisation_id &&
        organisations &&
        organisations.find
      ) {
        orderingOrganisation = organisations.find(
          (org: Organisation) => org.id === parseInt(order.organisation_id)
        );
      }
      if (!orderingOrganisation) {
        orderingOrganisation = organisation;
      }
      let ordering = orderingOrganisation
        ? [orderingOrganisation.ordering]
        : [];
      const isEditable =
        (await savePermitted(status)) || (await submitPermitted(status));
      // console.log("setInterfaceForPermissions", isEditable);
      setIsEditable(isEditable);
      setShowAttachments(false); //AuthenticationHelper.isInternalUser());

      if (await cancelPermitted(status)) {
        moreButtons.push(
          <EuiContextMenuItem
            key="cancel"
            // size="s"
            color="danger"
            icon="cross"
            onClick={(e: any) => {
              setIsMoreSelectOpen(false);
              handleCancelOrder();
            }}
          >
            {txt.get("orders.order.status_action.cancel")}
          </EuiContextMenuItem>
        );
      }

      if (
        (await savePermitted(status)) ||
        (await correctionsPermitted(status)) ||
        (await deliverPermitted(status))
      ) {
        moreButtons.push(
          <EuiContextMenuItem
            key="save"
            icon="save"
            onClick={() => {
              setIsMoreSelectOpen(false);
              handleSaveOrder();
            }}
          >
            {txt.get("generic.save")}
          </EuiContextMenuItem>
        );
      }

      if (order && order.id) {
        moreButtons.push(
          <EuiContextMenuItem
            key="duplicate"
            icon="copy"
            onClick={() => {
              setIsMoreSelectOpen(false);
              duplicateOrder(order.id);
            }}
          >
            {txt.get("generic.duplicate")}
          </EuiContextMenuItem>
        );
      }

      if (await deletePermitted(status, isDemo)) {
        moreButtons.push(
          <EuiContextMenuItem
            key="delete"
            icon="trash"
            onClick={() => {
              setIsMoreSelectOpen(false);
              handleDeleteOrder();
            }}
          >
            {txt.get("generic.delete")}
          </EuiContextMenuItem>
        );
      }

      if (await downloadPermitted(status)) {
        moreButtons.push(
          <EuiContextMenuItem
            key="download"
            icon="download"
            onClick={() => {
              setIsMoreSelectOpen(false);
              downloadPDF();
            }}
          >
            {txt.get("generic.download")}
          </EuiContextMenuItem>
        );
      }

      if (
        (await savePermitted(status)) ||
        (await correctionsPermitted(status))
      ) {
        saveButtons.push(
          // <EuiFlexGroup key="save-options" gutterSize="none" responsive={false}>
          //   <EuiFlexItem grow={false}>
          <EuiButtonEmpty
            aria-label="save"
            key="save"
            size="s"
            color={
              !feat(Feature.OrdersCloseConfirm) && props.isOrderChanged
                ? "accent"
                : "text"
            }
            style={{
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
              minInlineSize: "calc(126px - 26px)",
            }}
            iconType={
              !feat(Feature.OrdersCloseConfirm) && props.isOrderChanged
                ? "warning"
                : undefined
            }
            iconSide="right"
            isLoading={isAttachmentsLoading}
            onClick={(e: any) => handleSaveOrder()}
          >
            {txt.get(`generic.${saveOption}`)}
          </EuiButtonEmpty>
          // </EuiFlexItem>
          // <EuiFlexItem grow={false}>
          //   <EuiPopover
          //     id="save-select"
          //     button={
          //       <EuiButtonIcon
          //         aria-label="save-option"
          //         display="base"
          //         color="text"
          //         key="save-options"
          //         size="s"
          //         onClick={() => {
          //           setIsSaveSelectOpen(!isSaveSelectOpen);
          //         }}
          //         style={{
          //           backgroundColor: "transparent",
          //           height: "100%",
          //           marginLeft: "1px",
          //           borderTopLeftRadius: "0px",
          //           borderBottomLeftRadius: "0px",
          //         }}
          //         iconType="arrowDown"
          //       />
          //     }
          //     isOpen={isSaveSelectOpen}
          //     closePopover={closeSaveSelect}
          //     panelPaddingSize="none"
          //     anchorPosition="downRight"
          //     style={{ height: "100%" }}
          //   >
          //     <EuiContextMenuPanel size="s" items={saveOptions} />
          //   </EuiPopover>
          // </EuiFlexItem>
          // </EuiFlexGroup>
        );
        // saveButtons.push(
        //   <EuiText
        //     className={
        //       props.isOrderChanged
        //         ? "order-changed-info visible"
        //         : "order-changed-info"
        //     }
        //     size="xs"
        //     color="#c0c0c0"
        //   >
        //     {txt.get("orders.order.changed")}
        //   </EuiText>
        // );
      }

      if (await submitPermitted(status)) {
        orderStateButtons.push(
          <EuiButton
            key="order"
            size="s"
            fill
            isDisabled={!["measurements", "costing"].includes(tabSelected)}
            color="accent"
            onClick={(e: any) => handleSubmitOrder()}
            isLoading={isAttachmentsLoading}
          >
            {txt.get("generic.order")}
          </EuiButton>
        );
      }

      const permitAccept: boolean = await acceptPermitted(status, ordering);
      const permitDeliver: boolean = await deliverPermitted(status);
      const hasAccepterRole: boolean = await isAccepter(status, ordering);
      if (permitDeliver) {
        orderStateButtons.push(
          <EuiButton
            key="deliver"
            size="s"
            color="accent"
            fill={true}
            iconSide="right"
            isLoading={isAttachmentsLoading}
            isDisabled={!isAllFittingHandled(order)}
            iconType="checkInCircleFilled"
            onClick={(e: any) => handleDeliverOrder()}
          >
            {txt.get("orders.order.status_action.deliver")}
          </EuiButton>
        );
      }

      if (permitAccept) {
        orderStateButtons.push(
          <EuiButton
            key="accept"
            size="s"
            color="success"
            isLoading={isAttachmentsLoading}
            iconType="checkInCircleFilled"
            onClick={(e: any) => handleAcceptOrder()}
          >
            {txt.get("orders.order.status_action.accept")}
          </EuiButton>
        );
      }

      if (await finalizePermitted(status)) {
        orderStateButtons.push(
          <EuiButton
            key="finalize"
            size="s"
            color="success"
            isLoading={isAttachmentsLoading}
            iconType="checkInCircleFilled"
            onClick={(e: any) => handleFinalizeOrder()}
          >
            {txt.get("orders.order.status_action.finalize")}
          </EuiButton>
        );
      }

      if (await rejectPermitted(status)) {
        orderStateButtons.push(
          <EuiButton
            key="reject"
            size="s"
            color="danger"
            isLoading={isAttachmentsLoading}
            iconType="errorFilled"
            onClick={(e: any) => handleRejectOrder()}
          >
            {txt.get("orders.order.status_action.reject")}
          </EuiButton>
        );
      }

      setMoreButtonsToShow(moreButtons);
      setSaveButtonsToShow(saveButtons);
      setOrderStateButtonsToShow(orderStateButtons);
      setIsScansVisible(
        (await AuthenticationHelper.hasPermission([
          "scans#read",
          "scans#read_org",
          "scans#read_all",
        ])) && !AuthenticationHelper.getGroups().includes("/b2b")
      );
      setIsCorrigable(await correctionsPermitted(status));

      setIsConfigurable(await configuringPermitted(status));

      let newTabs: string[] = [];

      if (
        await AuthenticationHelper.hasPermission([
          "referrals#read",
          "referrals#read_org",
          "referrers#read_all",
          "referrers#read",
          "referrers#read_org",
          "referrers#read_all",
        ])
      ) {
        newTabs.push("referral");
      }
      if (
        await AuthenticationHelper.hasPermission([
          "needs#read",
          "needs#read_org",
          "needs#read_all",
        ])
      ) {
        newTabs.push("needs");
      }

      newTabs.push("product_selection");
      newTabs.push("measurements");

      if (canSeeCosts) {
        newTabs.push("costing");
      }

      if (
        (hasAccepterRole && permitDeliver) ||
        (order?.status &&
          [
            OrderStatus.Accepted,
            OrderStatus.Packaged,
            OrderStatus.PackagedPartial,
            OrderStatus.Routed,
            OrderStatus.RoutedPartial,
            OrderStatus.Produced,
            OrderStatus.ProducedPartial,
            OrderStatus.Shipped,
            OrderStatus.ShippedPartial,
            OrderStatus.Delivered,
            OrderStatus.DeliveredPartial,
            OrderStatus.Rejected,
            OrderStatus.Finalized,
          ].includes(order?.status))
      ) {
        newTabs.push("fitting");
      }

      setTabs((tabs: any[]) => newTabs);
    };

    if (focusState === FocusState.Validating) {
      // console.log("something changing, validating, update validation");
      updateValidation();
    } else {
      // console.log("something changing, but not validating");
    }

    if (order && (order.id || order.organisation_id)) {
      setInterfaceForPermissions();
    }
  }, [
    order,
    inputs,
    organisation,
    organisations,
    focusState,
    isSaveSelectOpen,
    isAttachmentsLoading,
    tabSelected,
    props.isOrderChanged,
  ]);

  useEffect(() => {
    if (!tabSelected || !tabs.includes(tabSelected)) {
      setTabSelected(props.orderTab || UrlHelper.queryParam("tab") || tabs[0]);
    }
  }, [props.orderTab, tabSelected, tabs]);

  const loadClientReferrals = async (clientCode?: string) => {
    if (!clientCode) {
      setClientReferrals([]);
    } else {
      const referralsResult: ApiResponse = await api.getReferrals(
        {
          client_code: clientCode,
          status: [
            ReferralStatus.Accepted,
            ReferralStatus.NotApplicable,
            ReferralStatus.Pending,
            ReferralStatus.Submitted,
          ],
        },
        50
      );
      if (referralsResult && referralsResult.status === ApiResponseStatus.OK) {
        setClientReferrals(referralsResult.result);
      } else {
        console.log("problem with referralsResult", referralsResult);
        setClientReferrals([]);
      }
    }
  };

  useEffect(() => {
    const loadClientProductions = async (clientCode?: string) => {
      if (!clientCode) {
        setClientProductions([]);
      } else {
        const productionsResult = await api.getProductions({
          client: clientCode,
          status: [
            OrderLineStatus.Submitted,
            OrderLineStatus.Accepted,
            OrderLineStatus.Produced,
            OrderLineStatus.Packaged,
            OrderLineStatus.Routed,
            OrderLineStatus.Shipped,
            OrderLineStatus.Delivered,
            OrderLineStatus.Finalized,
            OrderLineStatus.Rejected,
          ],
        });

        if (productionsResult.status === ApiResponseStatus.OK) {
          setClientProductions(productionsResult.result);
        } else {
          setClientProductions([]);
        }
      }
    };
    loadClientProductions(currentClientCode);

    if (AuthenticationHelper.isInternalUser()) {
      loadClientReferrals(currentClientCode);
    }
  }, [currentClientCode]);

  useEffect(() => {
    console.log("tabSelected", tabSelected);
  }, [tabSelected]);
  useEffect(() => {
    if (showValidation.orderLines && inputs?.order_lines && order) {
      let foundMissing: boolean = false;
      for (let i = 0; i < inputs.order_lines.length; i++) {
        const orderLineInputs: OrderLineInputs = inputs.order_lines[i];
        if (orderLineInputs.measurements && order.order_lines[i]) {
          const measurementInputs: any = { ...orderLineInputs.measurements };
          for (const key in measurementInputs) {
            const input: Input = measurementInputs[key] as Input;
            if (
              input &&
              input.mandatory &&
              !order.order_lines[i].measurements[key]
            ) {
              foundMissing = true;
              break;
            }
          }
        }
        if (
          !foundMissing &&
          orderLineInputs.order_type &&
          order.order_lines[i]
        ) {
          foundMissing =
            orderLineInputs.order_type.mandatory &&
            !!!order.order_lines[i].order_type;
        }
        if (
          !foundMissing &&
          orderLineInputs.remake_reason &&
          order.order_lines[i]
        ) {
          foundMissing =
            orderLineInputs.remake_reason.mandatory &&
            !!!order.order_lines[i].remake_reason;
        }
        if (
          !foundMissing &&
          orderLineInputs.replacement_for &&
          order.order_lines[i]
        ) {
          foundMissing =
            orderLineInputs.replacement_for.mandatory &&
            !!!order.order_lines[i].replacement_for;
        }

        if (foundMissing) {
          setMeasuringOrderLineIndex(i);
          break;
        }
      }
    }
  }, [showValidation.orderLines, inputs?.order_lines, order]);

  const onScanInstructionsChange = () => {
    setIsScanInstructionsVisible((old) => !old);
  };

  const onOrderChange = async (
    updatedOrder: Order,
    findCosting: boolean = false
  ) => {
    console.log("onOrderChange", updatedOrder);

    if (props.onOrderChange) {
      props.onOrderChange(updatedOrder);
    }

    const costingNeeded: OrderLine[] = updatedOrder.order_lines.filter(
      (line: OrderLine) =>
        line.id <= 0 && !!line.code && (!line.costing || !line.costing.contract)
    );
    if (findCosting) {
      console.log("lines needing costing", costingNeeded);
    }
    if (findCosting && costingNeeded.length > 0) {
      let costedOrder: Order = { ...updatedOrder };

      const findCostingForOrderLines = async () => {
        setIsLoading(true);
        if (client && client.id) {
          const insuranceDetailsResult: ApiResponse =
            await clientApi.getInsuranceDetails(client.id);
          if (
            insuranceDetailsResult &&
            insuranceDetailsResult.status === ApiResponseStatus.OK &&
            insuranceDetailsResult.result &&
            insuranceDetailsResult.result.id
          ) {
            let filters: any = {
              uzovi_codes: insuranceDetailsResult.result.insurer_uzovi_code,
            };
            if (costedOrder && costedOrder.ordered_at) {
              let year: string = DateHelper.format(
                costedOrder.ordered_at,
                "yyyy"
              );
              if (CONNECT_ORDER_YEARS.includes(year)) {
                filters.years = [year];
              }
            }
            const baseArticleCodes: string[] = costedOrder
              ? costedOrder.order_lines
                  .filter(
                    (line: OrderLine) =>
                      line.id <= 0 &&
                      !!line.code &&
                      (!line.costing || !line.costing.contract)
                  )
                  .map((line: OrderLine) =>
                    line.code.substring(0, line.code.indexOf("-"))
                  )
              : [];
            if (baseArticleCodes.length > 0) {
              filters.base_article_codes = baseArticleCodes;
              const contractResult: ApiResponse = await api.getAdminContracts(
                filters,
                300,
                0
              );
              if (
                contractResult &&
                contractResult.status === ApiResponseStatus.OK &&
                contractResult.result &&
                contractResult.result.length > 0
              ) {
                console.log("found contract for orderlines. add them", {
                  order_lines: costedOrder?.order_lines,
                  result: contractResult.result,
                });
                costedOrder.order_lines = updatedOrder.order_lines.map(
                  (line: OrderLine) => {
                    if (line.costing?.contract) {
                      return line;
                    } else {
                      let updatedCosting: Costing = {
                        ...(line.costing || COSTING_EMPTY),
                      };
                      const contract: Contract = contractResult.result.find(
                        (contract: Contract) =>
                          contract.base_article_code ===
                          line.code.substring(0, line.code.indexOf("-"))
                      );
                      if (contract) {
                        updatedCosting.contract_id = contract.id;
                        updatedCosting.contract = { ...contract };
                        const hasMeasuringFeeFirst: boolean =
                          updatedOrder.order_lines.findIndex(
                            (line: OrderLine) =>
                              line.costing?.amount_effort &&
                              line.costing?.description_effort
                          ) >= 0;
                        if (
                          !updatedCosting.amount &&
                          contract.product_declaration_value
                        ) {
                          updatedCosting.amount = isOrderLineDeclarationNeeded(
                            line.order_type
                          )
                            ? contract.product_declaration_value
                            : 0;
                        }

                        if (
                          contract.measuring_fee_first &&
                          !updatedCosting.amount_effort
                        ) {
                          updatedCosting.amount_effort =
                            isOrderLineDeclarationNeeded(line.order_type)
                              ? hasMeasuringFeeFirst &&
                                contract.measuring_fee_consecutive
                                ? contract.measuring_fee_consecutive
                                : contract.measuring_fee_first
                              : 0;
                          updatedCosting.description_effort =
                            (hasMeasuringFeeFirst &&
                            contract.measuring_fee_consecutive_code
                              ? contract.measuring_fee_consecutive_code
                              : contract.measuring_fee_first_code) || "";
                        }
                        if (
                          (contract.authorization === true ||
                            contract.authorization === false) &&
                          updatedCosting.authorization !== false &&
                          updatedCosting.authorization !== true
                        ) {
                          updatedCosting.authorization = contract.authorization;
                        }
                      }
                      return { ...line, costing: updatedCosting };
                    }
                  }
                );
              } else {
                console.log("couldn't find contracts for filters", {
                  message: contractResult.message,
                  filters,
                });
              }
            } else {
              console.log(
                "no order lines with order lines without id / costing, not finding contracts",
                {
                  order_lines: updatedOrder?.order_lines,
                  baseArticleCodes,
                }
              );

              costedOrder = {
                ...costedOrder,
                order_lines: costedOrder.order_lines.map((line: OrderLine) =>
                  line.costing
                    ? line
                    : { ...line, costing: { ...COSTING_EMPTY } }
                ),
              };
            }
          }
        }
        console.log("setting contractually updated order", updatedOrder);
        setOrder((old: Order | null) => costedOrder);
        setIsLoading(false);
      };
      await findCostingForOrderLines();
    } else {
      setOrder((old: Order | null) => updatedOrder);
    }
  };

  const onMeasuringProductSelect: OnProductSelect = (
    product: any,
    selected: boolean
  ) => {
    if (selected) {
      setMeasuringOrderLineIndex(product.data_order_line_index);
    } else {
      setMeasuringOrderLineIndex(0); //always have at least the first one selected
    }
  };

  const closeSaveSelect = () => {
    setIsSaveSelectOpen(false);
  };

  const closeMoreSelect = () => {
    setIsMoreSelectOpen(false);
  };

  const selectSaveOption = (option: string) => {
    setSaveOption(option);
    setIsSaveSelectOpen(false);
    saveOrder(option);
  };

  const getSaveOptionIcon = (currentSaveOption: string) => {
    return saveOption === currentSaveOption ? "check" : "empty";
  };

  const handleNextTab = () => {
    setTabSelected(
      tabs[Math.min(tabs.length - 1, tabs.indexOf(tabSelected) + 1)]
    );
    if (focusState === FocusState.Validating) {
      updateValidation();
    }
  };

  const handlePreviousTab = () => {
    setTabSelected(tabs[Math.max(0, tabs.indexOf(tabSelected) - 1)]);
    if (focusState === FocusState.Validating) {
      updateValidation();
    }
  };

  const handleDeleteOrder = () => {
    if (order === null) {
      console.log("no order to delete");
      return;
    }
    dispatch(
      confirmAsk(
        `${txt.uf("generic.delete_x", txt.get("orders.order.name"))}.`,
        txt.get("orders.order.delete_confirm"),
        deleteRef
      )
    );
  };

  const handleSaveOrder = async () => {
    if (order === null) {
      console.log("no order to save");
      return;
    }
    await saveOrder();
    //navigate(0);
  };

  const handleSubmitOrder = async () => {
    const isAllValidated: boolean = isAllValid(updateValidation());
    console.log("handleSubmitOrder", isAllValidated, order);
    const company: Organisation = getOrderedByCompany();
    const clientNaming: string =
      company && company.client_naming
        ? company.client_naming
        : "orders.external_client_reference_name.client";
    const showConfirmDetails: boolean =
      // !AuthenticationHelper.isInternalUser() &&
      !AuthenticationHelper.getGroups().includes("/b2b");

    if (isAllValidated) {
      handleOrderStatusChange(
        order?.id,
        OrderStatusAction.Submit,
        showConfirmDetails ? AlertInputValueType.Check : undefined,
        /* if your not with manometric, we need you to get clients confirmation */
        ` ${
          !showConfirmDetails
            ? `${txt.get("orders.order.confirm_intro")}<br/><br/>
        ${txt.html("orders.order.confirm_description")}`
            : ""
        }
        <br/>
        ${
          showConfirmDetails
            ? txt.get(
                "orders.order.confirm_by_client_explanation",
                txt.get(`${clientNaming}.articled`),
                txt.get("brand.name")
              )
            : ""
        }
        `,
        undefined,
        txt.get("generic.cancel"),
        txt.get("generic.confirm"),
        showConfirmDetails
          ? txt.get(
              "orders.order.confirm_by_client_agreement",
              txt.lo(`${clientNaming}.name`)
            )
          : undefined
      );
    }
  };

  const alertConfirm = useSelector(confirmGet);
  useEffect(() => {
    if (
      alertConfirm.actionState === AlertConfirmActionState.Perform &&
      alertConfirm.actionKey === deleteRef
    ) {
      deleteOrder();
    }
  }, [alertConfirm]);

  const deleteOrder = async () => {
    if (order && order.id) {
      await api.deleteOrder(order.id);
      dispatch(
        toastAdd(
          txt.get("generic.is_deleted", txt.get("orders.order.name")),
          null,
          "success"
        )
      );
    }

    if (props.orderNavigate) props.orderNavigate();
  };

  const submitOrder = async () => {
    if (order) {
      const result = await api.saveAndSubmitOrder(
        orderToApiOrderInfo({
          ...order,
          referral: {
            ...(order.referral || REFERRAL_EMPTY),
            intake_at: order.referral?.intake_at || new Date(),
          },
          referral_id: order.referral_id,
          ordered_at: new Date(),
          is_demo: AuthenticationHelper.isDemo() === "1",
        } as Order)
      );

      if (result && result.order_id) {
        trackEvent(
          "order",
          `order_${OrderStatus.Submitted.toString().toLowerCase()}`,
          `order ${result.order_id}`
        );

        dispatch(
          toastAdd(
            txt.get("orders.order.processed_title"),
            `${txt.get("orders.order.processed_description")}
            ${txt.get("orders.order.processed_description_production")}`,
            "success"
          )
        );

        // generatePDF(result.order_id);
      } else {
        dispatch(
          toastAdd(
            txt.get("orders.order.processing_error"),
            `${txt.get("orders.order.processing_error_description")} `,
            "danger"
          )
        );

        // return null;
      }
    }

    if (props.orderNavigate) props.orderNavigate();
  };

  const downloadPDF = async () => {
    if (!order) {
      return;
    } else {
      await generateOrderPDF(order, products, (file: any) => {
        const filename = `${format(new Date(order.ordered_at), "yyyyMMdd")}-ON${
          order.id
        }${order.client_code ? "-PN" + order.client_code : ""}.pdf`;
        FileUploadHelper.triggerDownloadFile(file.url, filename);
      }).catch((error) => {
        console.log("error: ", error);
      });
    }
  };

  const generatePDF = async (orderId: number) => {
    console.log("[PDF]: generating PDF...");

    //make sure to have the latest order. would be better of order info in input is autosaving
    const orderToDownload = await api.getOrder(orderId);
    const products = await api.getProducts();

    let fileUploadHelper = new FileUploadHelper();

    await generateOrderPDF(orderToDownload, products, (file: any) => {
      const filename = `${format(
        new Date(orderToDownload.ordered_at),
        "yyyyMMdd"
      )}-ON${orderToDownload.id}.pdf`;

      const path = "orders/attach";
      const key = "attach_file";

      const uploadOptions = {
        folder: `${orderToDownload.id}`,
        order_id: orderToDownload.id,
        filename,
        file_type: "application/pdf",
        notify: true,
      };

      fileUploadHelper
        .fileToBase64(file.blob)
        .then((content) => {
          fileUploadHelper
            .upload(path, key, content, uploadOptions)
            .then((result) => {})
            .catch((error) => {
              dispatch(
                toastAdd(
                  txt.get("orders.order.processing_error"),
                  `${txt.get("orders.order.processing_error_description")}`,
                  "danger"
                )
              );
            })
            .finally(() => {
              //in all cases trigger download file
              if (AuthenticationHelper.getGroups().includes("/manometric")) {
                FileUploadHelper.triggerDownloadFile(file.url, filename);
              }
            });
        })
        .catch((error) => {
          dispatch(
            toastAdd(
              txt.get("orders.order.processing_error"),
              `${txt.get("orders.order.processing_error_description")}`,
              "danger"
            )
          );
        });
    });
  };

  const updateValidation = () => {
    console.log("updateValidation");
    setFocusState(FocusState.Validating);
    if (!order || !inputs) {
      setShowValidation({ ...showValidation });
      return false;
    }

    const isValid: any = {
      order: isOrderInputValid(order, inputs),
      client: isClientInputValid(order, inputs),
      whoWhere: isWhoWhereInputValid(order, inputs),
      needs: isNeedsInputValid(order, inputs),
      referral: isReferralInputValid(order, inputs),
      referrer: isReferrerInputValid(order, inputs),
      orderLines: isOrderLinesInputValid(order, inputs),
      measurements: isMeasurementsInputValid(order, inputs),
      orderLineCostings: isOrderLineCostingInputValid(order, inputs),
      quotation: isOrderCostingInputValid(order, inputs),
    };
    const hasValidation: any = {
      order: !isValid.order,
      client: !isValid.client,
      whoWhere: !isValid.whoWhere,
      needs: !isValid.needs,
      referral: !isValid.referral,
      referrer: !isValid.referrer,
      orderLines: !isValid.orderLines,
      measurements: !isValid.measurements,
      orderLineCostings: !isValid.orderLineCostings,
      quotation: !isValid.quotation,
    };

    const visibility: ValidationVisibility = {
      order: !isValid.order,
      client: !isValid.client,
      whoWhere: isValid.client && !isValid.whoWhere,
      referral: isValid.client && isValid.whoWhere && !isValid.referral,
      referrer: isValid.client && isValid.whoWhere && !isValid.referrer,
      needs:
        isValid.client &&
        isValid.whoWhere &&
        isValid.referral &&
        isValid.referrer &&
        !isValid.needs,
      orderLines:
        isValid.client &&
        isValid.whoWhere &&
        isValid.referral &&
        isValid.referrer &&
        isValid.needs &&
        !isValid.orderLines,
      measurements:
        isValid.client &&
        isValid.whoWhere &&
        isValid.referral &&
        isValid.referrer &&
        isValid.needs &&
        isValid.orderLines &&
        !isValid.measurements,
      quotation:
        isValid.client &&
        isValid.whoWhere &&
        isValid.referral &&
        isValid.referrer &&
        isValid.needs &&
        isValid.orderLines &&
        isValid.measurements &&
        !isValid.quotation,
      orderLineCostings:
        isValid.client &&
        isValid.whoWhere &&
        isValid.referral &&
        isValid.referrer &&
        isValid.needs &&
        isValid.orderLines &&
        isValid.measurements &&
        isValid.quotation &&
        !isValid.orderLineCostings,
    };

    if (visibility.referral || visibility.referrer) {
      setTabSelected("referral");
    } else if (visibility.needs) {
      setTabSelected("needs");
    } else if (visibility.orderLines) {
      setTabSelected("product_selection");
    } else if (visibility.measurements) {
      setTabSelected("measurements");
    } else if (visibility.quotation || visibility.orderLineCostings) {
      setTabSelected("costing");
    }

    setShowValidation(visibility);
    setHasValidation(hasValidation);

    return isValid;
  };

  const isAllValid = (isValid: any) => {
    return (
      isValid !== false &&
      isValid.order &&
      isValid.client &&
      isValid.whoWhere &&
      isValid.needs &&
      isValid.referral &&
      isValid.referrer &&
      isValid.orderLines &&
      isValid.orderLineCostings &&
      isValid.quotation
    );
  };

  const handleAcceptOrder = () => {
    handleOrderStatusChange(order?.id, OrderStatusAction.Accept);
  };

  const handleCancelOrder = () => {
    handleOrderStatusChange(
      order?.id,
      OrderStatusAction.Cancel,
      AlertInputValueType.Text
    );
  };

  const handleDeliverOrder = () => {
    // handleOrderStatusChange(order?.id, OrderStatusAction.Deliver);

    handleOrderStatusChange(
      order?.id,
      OrderStatusAction.Deliver,
      AlertInputValueType.Check,
      txt.get("orders.order.delivery_checks")
      // {
      //   title: txt.get("orders.order.delivery_products"),
      //   fields: (order?.order_lines || []).map((line) => ({
      //     type: "select",
      //     label: `${handDescription(
      //       line.hand?.toLowerCase() === "l" ? Hand.Left : Hand.Right
      //     )} ${line.product_selection.type} ${
      //       ["n.v.t.", "n/a"].includes(line.product_selection.color)
      //         ? ""
      //         : line.product_selection.color
      //     } ${
      //       ["n.v.t.", "n/a"].includes(line.product_selection.size)
      //         ? ""
      //         : line.product_selection.size
      //     } `,
      //     key: "id",
      //     id: line.id,
      //     value: [OrderLineStatus.Produced, OrderLineStatus.Packaged].includes(
      //       line.status
      //     )
      //       ? OrderLineStatus.Delivered
      //       : line.status,
      //     read_only: ![
      //       OrderLineStatus.Produced,
      //       OrderLineStatus.Packaged,
      //     ].includes(line.status),
      //     options: [
      //       OrderLineStatus.Produced,
      //       OrderLineStatus.Packaged,
      //     ].includes(line.status)
      //       ? [
      //           {
      //             text: txt.get(
      //               `orders.order.status_action.${OrderStatusAction.Deliver.toLowerCase()}`
      //             ),
      //             value: OrderLineStatus.Delivered,
      //           },
      //           {
      //             text: txt.get(
      //               `orders.order.status_action.${OrderStatusAction.Reject.toLowerCase()}`
      //             ),
      //             value: OrderLineStatus.Rejected,
      //           },
      //           {
      //             text: txt.get("orders.order.cannot_deliver"),
      //             value: line.status,
      //           },
      //         ]
      //       : [
      //           {
      //             text: orderLineStatusDescription(line.status),
      //             value: line.status,
      //           },
      //         ],
      //   })),
      // },
      // txt.get("generic.cancel")
    );
  };

  const handleFinalizeOrder = () => {
    handleOrderStatusChange(order?.id, OrderStatusAction.Finalize);
  };

  const handleRejectOrder = () => {
    handleOrderStatusChange(
      order?.id,
      OrderStatusAction.Reject,
      AlertInputValueType.Text
    );
  };

  const handleOrderStatusChange = async (
    orderId: number | string | undefined,
    statusAction: OrderStatusAction,
    inputType?: any,
    content?: any,
    customActionData?: any,
    cancelText?: string,
    confirmText?: string,
    checkDescription?: string
  ) => {
    console.log(
      "handleOrderStatusChange",
      orderId,
      statusAction,
      inputType,
      content,
      customActionData,
      cancelText,
      confirmText,
      checkDescription
    );

    const canEditStatusLog = await AuthenticationHelper.hasPermission(
      "order_status_logs#edit_all"
    );

    dispatch(
      confirmAsk(
        `${txt.get(
          `orders.order.status_action.${statusAction.toLowerCase()}`
        )}.`,
        !canEditStatusLog && content
          ? content
          : txt.get(
              "generic.confirm_action_x_y",
              txt.lo(
                `orders.order.status_action.${statusAction.toLowerCase()}`
              ),
              txt.lo("orders.order.articled")
            ),
        changeStatusRef,
        {
          orderId,
          statusAction,
          ...customActionData,
          editableDate: canEditStatusLog
            ? DateHelper.toDateTimeSortable()
            : null,
        },
        canEditStatusLog &&
          (inputType === undefined || inputType === AlertInputValueType.Check)
          ? {
              valueType: AlertInputValueType.Text,
              description: checkDescription
                ? checkDescription
                : txt.get("orders.order.log_note"),
              isMandatory: false,
              isVisible: true,
              value: "",
            }
          : inputType !== undefined
            ? {
                valueType: inputType,
                description: checkDescription
                  ? checkDescription
                  : inputType === AlertInputValueType.Text
                    ? txt.get("generic.add_reasoning")
                    : txt.get("orders.order.delivery_checks_confirm"),
                isMandatory: true,
                isVisible: true,
              }
            : undefined,
        confirmText || undefined,
        cancelText
      )
    );
  };

  useEffect(() => {
    if (
      alertConfirm.actionState === AlertConfirmActionState.Perform &&
      alertConfirm.actionKey === changeStatusRef
    ) {
      let comment;
      let orderLineStatusses;
      let status = orderStatusFromAction(alertConfirm.actionData.statusAction);

      if (status === OrderStatus.Submitted) {
        submitOrder();
      } else {
        let loggedAt =
          alertConfirm &&
          alertConfirm.actionData &&
          alertConfirm.actionData.editableDate
            ? alertConfirm.actionData.editableDate
            : null;

        if (alertConfirm) {
          if (
            alertConfirm.input &&
            alertConfirm.input.valueType !== AlertInputValueType.Check
          ) {
            comment = alertConfirm.input.value;
          }
          if (
            alertConfirm.actionData.statusAction === OrderStatusAction.Deliver
            //||
            // alertConfirm.actionData.statusAction === OrderStatusAction.Finalize
          ) {
            if (order && order.order_lines) {
              let fullyDelivered: boolean = true;
              orderLineStatusses = [];
              for (let i = 0; i < order?.order_lines.length; i++) {
                const orderLine: OrderLine = order?.order_lines[i];
                if (orderLine.fittings && orderLine.fittings.length > 0) {
                  const fitting: Fitting = orderLine.fittings[0];
                  if (fitting.is_fitting === true) {
                    orderLineStatusses.push({
                      order_line_id: orderLine.id,
                      status: OrderLineStatus.Delivered,
                      logged_at: loggedAt,
                    });
                  } else if (fitting.is_fitting === false) {
                    orderLineStatusses.push({
                      order_line_id: orderLine.id,
                      status: OrderLineStatus.Rejected,
                      logged_at: loggedAt,
                    });
                  } else if (
                    ![OrderLineStatus.Cancelled].includes(orderLine.status)
                  ) {
                    fullyDelivered = false;
                  }
                }
              }
              if (!fullyDelivered) {
                status = OrderStatus.DeliveredPartial;
              }
            }

            // let fullyDelivered: boolean = true;
            // let linesChecked: any[] = alertConfirm.actionData.fields
            //   ? alertConfirm.actionData.fields
            //   : [];

            // orderLineStatusses = [];
            // for (let i = 0; i < (order?.order_lines || []).length; i++) {
            //   const line = (order?.order_lines || [])[i];
            //   const lineChecked = linesChecked.find(
            //     (lineToCheck: any) => lineToCheck.id === line.id
            //   );

            //   //order is fully delivered if all lines are in an end state (cancelled,delivered,rejected);
            //   const statusToCheck = lineChecked
            //     ? lineChecked.value
            //     : line.status;
            //   fullyDelivered &&= [
            //     OrderLineStatus.Delivered,
            //     OrderLineStatus.Cancelled,
            //     OrderLineStatus.Rejected,
            //   ].includes(statusToCheck);

            //   orderLineStatusses.push({
            //     order_line_id: line.id,
            //     status:
            //       status === OrderStatus.Finalized
            //         ? OrderLineStatus.Finalized
            //         : lineChecked.value,
            //     loggedAt,
            //   });
            // }
            // if (!fullyDelivered) {
            //   status = OrderStatus.DeliveredPartial;
            // }
          }
        }

        const handleUpdate = async (
          orderId: number,
          status: OrderStatus,
          comment: string | null | undefined,
          orderLineStatusses: any[] = [],
          loggedAt = null
        ) => {
          //save order before changing status, because users might have changed stuff
          //not sure if this can be rolled up into changeOrderStatus
          if (
            status === OrderStatus.Finalized ||
            status === OrderStatus.Delivered ||
            status === OrderStatus.DeliveredPartial ||
            status === OrderStatus.Rejected
          ) {
            await saveOrder(undefined, true);
          }
          await changeOrderStatus(
            orderId,
            status,
            comment,
            orderLineStatusses,
            loggedAt
          );
        };
        handleUpdate(
          alertConfirm.actionData.orderId,
          status,
          comment,
          orderLineStatusses,
          loggedAt
        );
      }
    }
  }, [alertConfirm]);

  const changeOrderStatus = async (
    orderId: number,
    status: OrderStatus,
    comment: string | null | undefined,
    orderLineStatusses: any[] = [],
    loggedAt = null
  ) => {
    console.log(orderId, status, comment, orderLineStatusses);
    const result = await api.updateOrderStatus(
      orderId,
      status,
      comment || "",
      orderLineStatusses,
      loggedAt
    );
    if (result) {
      trackEvent(
        "order",
        `order_${status.toString().toLowerCase()}`,
        `order ${orderId}`
      );
      dispatch(toastAdd(`Updated order`, null, "success"));
      navigate(0);
    } else {
      dispatch(toastAdd(`Updated order failed:`, null, "danger"));
    }
  };

  const saveOrder = async (
    currentSaveOption?: string,
    silent: boolean = false
  ) => {
    console.log("saveOrder", order);
    if (!order) {
      return false;
    }

    if (!currentSaveOption) {
      currentSaveOption = saveOption;
    }

    if (!silent) {
      setIsLoading(true);
    }
    const orderInfo: any = orderToApiOrderInfo(order);

    if (focusState === FocusState.Validating) {
      setFocusState(FocusState.Order);
    }
    const result = await api.saveOrder(orderInfo);

    await loadClientReferrals(order?.client_code);

    if (!silent) {
      setIsLoading(false);
    }
    if (result && result.order_id) {
      if (!silent) {
        trackEvent(
          "order",
          `order_${OrderStatus.Drafted.toString().toLowerCase()}`, //this is wrong
          `order ${result.order_id}`
        );
      }

      if (currentSaveOption === "save" && result.order_id) {
        //nothing

        if (props.orderNavigate) {
          props.orderNavigate(result.order_id);
        } else {
          navigate(`/orders/${result.order_id}`);
        }
      } else if (currentSaveOption === "save_back") {
        navigateBack();
      } else if (currentSaveOption === "save_new" && props.orderNavigate) {
        props.orderNavigate("new");
      }
      if (!silent) {
        dispatch(
          toastAdd(
            txt.get("generic.is_saved", txt.get("orders.order.name")),
            null,
            "success"
          )
        );
      }
    } else {
      dispatch(
        toastAdd(
          result.message
            ? txt.get(`errors.${result.message}.name`)
            : txt.get("generic.error"),
          result.message ? txt.get(`errors.${result.message}.description`) : "",
          "danger"
        )
      );
    }

    if (props.onOrderVersion) {
      props.onOrderVersion(order.version + 1);
    }
    return result.order_id;
  };

  const saveOptions = [
    <EuiContextMenuItem
      key="save"
      icon={getSaveOptionIcon("save")}
      onClick={() => {
        selectSaveOption("save");
      }}
    >
      {txt.get("generic.save")}
    </EuiContextMenuItem>,
    <EuiContextMenuItem
      key="save_back"
      icon={getSaveOptionIcon("save_back")}
      onClick={() => {
        selectSaveOption("save_back");
      }}
    >
      {txt.get("generic.save_back")}
    </EuiContextMenuItem>,
    <EuiContextMenuItem
      key="save_new"
      icon={getSaveOptionIcon("save_new")}
      onClick={() => {
        selectSaveOption("save_new");
      }}
    >
      {txt.get("generic.save_new")}
    </EuiContextMenuItem>,
  ];

  const getHands = (order: Order | null) => {
    //get unique hands
    let hands: Hand[] = [];

    if (order && order.order_lines) {
      const orderLineProducts: MMOrderLineProductInput[] = orderLinesToProducts(
        order.order_lines,
        products
      );
      for (let i = 0; i < orderLineProducts.length; i++) {
        const current: MMOrderLineProductInput = orderLineProducts[i];
        if (
          current.hand &&
          !hands.includes(current.hand as Hand) &&
          current.variation &&
          current.variation.measurement_category.toLowerCase() === "3d scan"
        ) {
          // console.log("adding", current.hand);
          hands.push(current.hand as Hand);
        }
      }
    }
    return hands;
  };

  const handleFocusStateFromClient = async (focusState: FocusState) => {
    //setFocusState(focusState);
    await saveOrder("save", true);
  };

  const getAssignedDevices = (
    order: Order | null,
    user: PersonalDetails | null,
    locations: Location[] | null
  ) => {
    //get unique assigned devices with details (location / personal details) they are assigned to
    let devices: AssignedDeviceDetails[] = [];
    if (order) {
      let locationDevices: AssignedDevice[] = [];
      let location;
      if (order.location) {
        location = order.location;
        locationDevices = order.location.assigned_devices;
      } else if (order.location_id && locations) {
        location = locations.find((loc: any) => loc.id === order.location_id);
        if (location) {
          locationDevices = toApiFormat((location as any).AssignedDevices); //from old api
        }
      }

      console.log("getAssignedDevices", order.location, user);
      for (let i = 0; i < locationDevices.length; i++) {
        const currentDevice: AssignedDevice = locationDevices[i];

        if (
          currentDevice &&
          !devices.find(
            (details: AssignedDeviceDetails) =>
              details.device.device_id === currentDevice.device_id
          )
        ) {
          devices.push({
            device: currentDevice,
            location: { ...location } as Location,
          });
        }
      }
    }
    if (user && user.assigned_devices) {
      for (let i = 0; i < user.assigned_devices.length; i++) {
        const currentDevice: AssignedDevice = user.assigned_devices[i];

        if (
          currentDevice &&
          !devices.find(
            (details: AssignedDeviceDetails) =>
              details.device.device_id === currentDevice.device_id
          )
        ) {
          devices.push({ device: currentDevice, user: { ...user } });
        }
      }
    }

    // console.log(
    //   "getAssignedDevices",
    //   order?.location?.assigned_devices,
    //   user?.assigned_devices,
    //   devices
    // );
    return devices;
  };

  const renderSelectedTab = () => {
    switch (tabSelected) {
      case "referral":
        return renderReferral();
      case "needs":
        return renderNeeds();
      case "product_selection":
        return isLoadingProducts ? (
          <EuiLoadingSpinner />
        ) : (
          renderProductSelection()
        );
      case "measurements":
        return isLoadingProducts ? <EuiLoadingSpinner /> : renderMeasurements();
      case "fitting":
        return renderFitting();
      case "costing":
        return renderCosting();
    }
    return <></>;
  };

  const renderReferral = () => (
    <div className="order-content order-referral">
      <EuiFlexGroup
        style={{
          margin: "0px auto",
          maxWidth:
            props.order?.order_lines && props.order.order_lines.length > 0
              ? "920px"
              : "936px",
        }}
        direction="column"
        // alignItems="center"
        justifyContent="center"
        gutterSize="xl"
      >
        <EuiFlexItem grow={true}>
          <MMOrderIntake
            showValidation={showValidation.referral}
            hasValidation={hasValidation.referral}
            inputs={inputs}
            order={order}
            clientReferrals={clientReferrals}
            onChange={onOrderChange}
            isEditable={isEditable}
          />
        </EuiFlexItem>
        <EuiFlexGroup
          justifyContent="spaceBetween"
          gutterSize="xl"
          className={
            order?.order_lines && order.order_lines.length > 0
              ? "guttersize-xxl with-products"
              : "guttersize-xxl"
          }
        >
          <EuiFlexItem grow={false}>
            <MMOrderReferral
              showValidation={showValidation.referral}
              hasValidation={hasValidation.referral}
              inputs={inputs}
              order={order}
              clientReferrals={clientReferrals}
              onChange={onOrderChange}
              isEditable={isEditable}
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <MMOrderReferrer
              api={api}
              showValidation={showValidation.referrer}
              hasValidation={hasValidation.referrer}
              inputs={inputs}
              order={order}
              organisations={organisations}
              onChange={onOrderChange}
              isEditable={isEditable}
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <MMOrderIndication
              inputs={inputs}
              order={order}
              onChange={onOrderChange}
              isEditable={isEditable}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexGroup>
    </div>
  );

  const renderNeeds = () => (
    <div
      className="order-content order-needs"
      style={{
        width: "100%",
        margin: "0px auto",
        maxWidth:
          props.order?.order_lines && props.order.order_lines.length > 0
            ? "920px"
            : "936px",
      }}
      // alignItems="center"
      // justifyContent="center"
      // gutterSize="xl"
    >
      <MMOrderNeeds
        inputs={inputs}
        showValidation={showValidation.needs}
        hasValidation={hasValidation.needs}
        order={order}
        onChange={onOrderChange}
        isEditable={isEditable}
      />
    </div>
  );

  const renderProductSelection = () => (
    <EuiFlexGroup className="order-content order-product-selection">
      <EuiFlexItem>
        {products.length === 0 ? (
          <Fragment>
            <EuiLoadingSpinner />
            <EuiText size="s" color="subdued">
              {txt.get("generic.loading")}
            </EuiText>
          </Fragment>
        ) : (
          <MMOrderProductSelect
            order={order}
            inputs={inputs}
            hasValidation={hasValidation.orderLines}
            showValidation={showValidation.orderLines}
            organisation={organisation}
            personalDetails={personalDetails}
            products={products}
            clientProductions={clientProductions}
            onChange={onOrderChange}
            orderTypes={orderTypes}
            remakeReasons={remakeReasons}
            communicationReasons={communicationReasons}
            isEditable={isEditable}
            showProductionDetails={showProductionDetails}
          />
        )}
      </EuiFlexItem>
    </EuiFlexGroup>
  );

  const renderMeasurements = () => (
    <EuiFlexGroup
      className="order-content order-measurements"
      gutterSize="xl"
      justifyContent="spaceAround"
      ref={(ref) => setMeasurementsTabRef(ref)}
    >
      <EuiFlexItem grow={1}>
        <MMOrderScans
          isScanInstructionVisible={isScanInstructionsVisible}
          isInstructionsVisibleChanged={(isVisible: boolean) =>
            setIsScanInstructionsVisible(isVisible)
          }
          order={order ? order : ORDER_EMPTY}
          currentClientCode={
            currentClientCode ? parseInt(currentClientCode) : undefined
          }
          isScanButtonVisible={false}
          isScansVisible={true}
          closeAfterScan={false}
          hands={getHands(order)}
          assignedDevices={getAssignedDevices(
            order,
            personalDetails,
            locations
          )}
          maxHeight="calc(100vh - 580px)"
          personalDetails={personalDetails}
        />
      </EuiFlexItem>
      {showAttachments ? (
        <EuiFlexItem grow={1}>
          <MMOrderAttachments
            isEditable={isEditable}
            order={order}
            onChange={onOrderChange}
            maxHeight="calc(100vh - 560px)"
          />
        </EuiFlexItem>
      ) : (
        <EuiFlexItem grow={1}></EuiFlexItem>
      )}

      <EuiFlexItem grow={2}>
        <EuiFlexGroup className="product-filters" direction="column">
          <MMOrderLineEntry
            popoverContainer={measurementsTabRef}
            visibleIndices={
              tabSelected === "measurements" ? [measuringOrderLineIndex] : []
            }
            inputs={inputs}
            hasValidation={hasValidation.measurements}
            showValidation={showValidation.measurements}
            maxHeight="calc(100vh - 460px)"
            measurementsOnly={true}
            order={order}
            organisation={organisation}
            personalDetails={personalDetails}
            products={products}
            clientProductions={clientProductions}
            onChange={onOrderChange}
            onScanInstructionsChange={onScanInstructionsChange}
            orderTypes={orderTypes}
            remakeReasons={remakeReasons}
            communicationReasons={communicationReasons}
            workflowNames={workflowNames}
            hideNonOptions={true}
            measurementsShown={["o1", "o2", "o3", "o4", "l1", "l2"]}
            showProductionDetails={showProductionDetails}
            onProductSelect={onMeasuringProductSelect}
            isEditable={isEditable}
          />
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );

  const renderFitting = () =>
    isLoadingProducts ? (
      <EuiLoadingSpinner />
    ) : (
      <MMOrderLineDelivery
        api={api}
        order={order}
        organisation={organisation}
        personalDetails={personalDetails}
        products={products}
        clientProductions={clientProductions}
        onChange={onOrderChange}
        orderTypes={orderTypes}
        remakeReasons={remakeReasons}
        onProductSelect={
          tabSelected === "measurements" ? onMeasuringProductSelect : undefined
        }
        communicationReasons={communicationReasons}
        visibleIndices={
          tabSelected === "measurements" ? [measuringOrderLineIndex] : []
        }
        isEditable={isEditable}
        showProductionDetails={showProductionDetails}
      />
    );

  const renderCosting = () =>
    isLoadingProducts ? (
      <EuiLoadingSpinner />
    ) : (
      <MMCosting
        api={api}
        clientApi={clientApi}
        client={client}
        order={order}
        inputs={inputs}
        hasValidation={
          hasValidation.quotation || hasValidation.orderLineCostings
        }
        organisation={organisation}
        personalDetails={personalDetails}
        products={products}
        clientProductions={clientProductions}
        onChange={onOrderChange}
        orderTypes={orderTypes}
        remakeReasons={remakeReasons}
        onProductSelect={
          tabSelected === "measurements" ? onMeasuringProductSelect : undefined
        }
        communicationReasons={communicationReasons}
        visibleIndices={
          tabSelected === "measurements" ? [measuringOrderLineIndex] : []
        }
        isEditable={canEditCosts}
        showProductionDetails={showProductionDetails}
      />
    );

  const renderOrderLog = () =>
    order && order.id && canSeeLogs ? (
      <EuiPopover
        id="order-log"
        button={
          <EuiButtonIcon
            aria-label="order-log"
            display="base"
            color="text"
            key="order-log"
            size="xs"
            onClick={() => {
              setIsOrderLogOpen(!isOrderLogOpen);
            }}
            style={{
              position: "relative",
              top: "-10px",
              backgroundColor: "transparent",
            }}
            iconType="iInCircle"
          />
        }
        isOpen={isOrderLogOpen}
        closePopover={() => {
          setIsOrderLogOpen(false);
        }}
        panelPaddingSize="none"
        anchorPosition="downRight"
        style={{ height: "100%" }}
      >
        <MMOrderStatusLogs order={order} />
      </EuiPopover>
    ) : (
      <></>
    );

  const renderOrder = () =>
    // tabSelected === "product_selection" &&
    order && order.order_lines && order.order_lines.length > 0 ? (
      <EuiFlexItem grow={false}>
        {canEditOrderCompletion ? (
          <EuiFlexGroup
            className={`order-completion-holder${
              order.is_complete === true
                ? " is-complete"
                : order.is_complete === false
                  ? " is-incomplete"
                  : ""
            }`}
            gutterSize="l"
          >
            <EuiFlexItem>
              <MMOrderCompletion
                order={order}
                isEditable={isEditable}
                incompleteReasons={incompleteReasons}
                onChange={onOrderChange}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        ) : (
          <></>
        )}

        <EuiPageBody paddingSize="l" className="order-entries">
          <EuiFlexGrid>
            <EuiFlexGroup
              className="order-header"
              alignItems="flexStart"
              gutterSize="none"
            >
              <EuiFlexItem>
                <EuiTitle size="xs" className="subdued">
                  <EuiText color="grey" style={{ whiteSpace: "nowrap" }}>
                    {order && order.id
                      ? `${txt.get("orders.order.name")} ${order.id}`
                      : txt.get("orders.new.page_title")}
                    {renderOrderLog()}
                  </EuiText>
                </EuiTitle>
              </EuiFlexItem>
              <EuiFlexItem grow={true} style={{ alignItems: "flex-end" }}>
                {order &&
                order.status &&
                order.status !== OrderStatus.Drafted ? (
                  <MMOrderStatus order={order} isEditable={isEditable} />
                ) : (
                  <></>
                )}
              </EuiFlexItem>
            </EuiFlexGroup>

            <EuiFlexGroup className="order-cards" direction="column">
              <EuiFlexItem>
                <EuiFlexGroup className="order-cards-holder" direction="column">
                  {isLoadingProducts ? (
                    <EuiLoadingSpinner />
                  ) : (
                    <MMOrderLineCards
                      api={api}
                      order={order}
                      hasValidation={hasValidation.orderLines}
                      showValidation={showValidation.orderLines}
                      inputs={inputs}
                      workflows={workflows}
                      organisation={organisation}
                      personalDetails={personalDetails}
                      products={products}
                      clientProductions={clientProductions}
                      onChange={onOrderChange}
                      orderTypes={orderTypes}
                      remakeReasons={remakeReasons}
                      onProductSelect={
                        tabSelected === "measurements"
                          ? onMeasuringProductSelect
                          : undefined
                      }
                      communicationReasons={communicationReasons}
                      visibleIndices={
                        tabSelected === "measurements"
                          ? [measuringOrderLineIndex]
                          : []
                      }
                      isEditable={isEditable}
                      showProductionDetails={showProductionDetails}
                    />
                  )}
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexGrid>
          <EuiFlexItem grow={true} style={{ marginBottom: "8px" }}>
            <EuiFlexGroup alignItems="flexEnd" justifyContent="flexEnd">
              {!isLoading && orderStateButtonsToShow ? (
                orderStateButtonsToShow.map((button: any, i: number) => (
                  <EuiFlexItem grow={false} key={`button-${i}`}>
                    {button}
                  </EuiFlexItem>
                ))
              ) : (
                <></>
              )}
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiPageBody>
      </EuiFlexItem>
    ) : (
      <></>
    );

  const renderButtons = () => (
    <EuiFlexGroup
      justifyContent="spaceBetween"
      gutterSize="s"
      className="order-details-buttons"
    >
      <EuiFlexItem grow={false}>
        <EuiFlexGroup key="save-options" gutterSize="none" responsive={false}>
          {saveButtonsToShow.map((button: any, i: number) => (
            <EuiFlexItem grow={false} key={`button-${i}`}>
              {button}
            </EuiFlexItem>
          ))}
        </EuiFlexGroup>
      </EuiFlexItem>
      {tabs.length > 1 ? (
        <EuiFlexItem grow={false}>
          <EuiFlexGroup gutterSize="s">
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty
                size="s"
                color="text"
                isDisabled={tabs.indexOf(tabSelected) <= 0}
                onClick={() => handlePreviousTab()}
              >
                {txt.get("generic.previous")}
              </EuiButtonEmpty>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <EuiButton
                size="s"
                isDisabled={tabs.indexOf(tabSelected) >= tabs.length - 1}
                onClick={() => handleNextTab()}
              >
                {txt.get("generic.next")}
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      ) : tabSelected === "fitting" ? (
        <EuiFlexItem grow={true} style={{ marginBottom: "8px" }}>
          <EuiFlexGroup alignItems="flexEnd" justifyContent="flexEnd">
            {!isLoading && orderStateButtonsToShow ? (
              orderStateButtonsToShow.map((button: any, i: number) => (
                <EuiFlexItem grow={false} key={`button-${i}`}>
                  {button}
                </EuiFlexItem>
              ))
            ) : (
              <></>
            )}
          </EuiFlexGroup>
        </EuiFlexItem>
      ) : (
        <></>
      )}
    </EuiFlexGroup>
  );
  const renderTabs = (tabs: string[], showValidation: ValidationVisibility) => (
    <EuiTabs
      className={tabs.length > 4 ? "order-tabs-tight order-tabs" : "order-tabs"}
      bottomBorder={false}
      style={{ marginTop: "20px" }}
    >
      {tabs.map((tab: string) => {
        const tabHasValidation: boolean =
          (showValidation.order &&
            tab === "referral" &&
            (hasValidation.referral || hasValidation.referrer)) ||
          (tab === "needs" && hasValidation.needs) ||
          (tab === "product_selection" && hasValidation.orderLines) ||
          (tab === "measurements" && hasValidation.measurements) ||
          (tab === "costing" &&
            (hasValidation.quotation || hasValidation.orderLineCostings));

        return (
          <EuiTab
            key={`tab-${tab}`}
            onClick={() => {
              setTabSelected(tab);
              if (focusState === FocusState.Validating) {
                updateValidation();
              }
            }}
            isSelected={tabSelected === tab}
            style={tabs.length === 1 ? { opacity: 0 } : {}}
          >
            <EuiText
              size="s"
              color={
                tabHasValidation
                  ? "danger"
                  : tabSelected === tab
                    ? undefined
                    : "#ffffff55"
              }
            >
              <strong>{txt.get(`orders.order.intake.${tab}`)}</strong>
            </EuiText>
          </EuiTab>
        );
      })}
    </EuiTabs>
  );

  return (
    <EuiFlexGroup
      id="order-entry"
      className={`order-entry order-focus-${focusState.toLowerCase()} ${
        order?.order_lines && order?.order_lines.length > 0
          ? "has-order-lines"
          : ""
      }`}
    >
      {isLoading ? (
        <EuiFlexItem
          style={{
            position: "absolute",
            width: "100vw",
            maxWidth: "1400px",
            left: 0,
            right: 0,
            textAlign: "center",
            top: "calc(50vh - 200px)",
            bottom: 0,
          }}
        >
          <EuiLoadingSpinner style={{ margin: "0px auto 10px" }} />
          <EuiText size="s" color="white">
            {txt.get("generic.loading")}
          </EuiText>
        </EuiFlexItem>
      ) : (
        <></>
      )}
      <EuiFlexItem className="order-entry-input">
        <EuiFlexGrid gutterSize="none">
          <EuiPageBody paddingSize="xl" className="order-header">
            <EuiFlexItem className="more-buttons">
              <EuiPopover
                id="save-more"
                button={
                  <EuiIcon
                    aria-label="save-option"
                    className="more-button"
                    display="base"
                    color="#fff"
                    key="save-options"
                    size="s"
                    onClick={() => {
                      setIsMoreSelectOpen(!isMoreSelectOpen);
                    }}
                    type="boxesVertical"
                  />
                }
                isOpen={isMoreSelectOpen}
                closePopover={closeMoreSelect}
                panelPaddingSize="none"
                anchorPosition="leftUp"
                style={{ height: "100%" }}
              >
                <EuiContextMenuPanel size="s" items={moreButtonsToShow} />
              </EuiPopover>
            </EuiFlexItem>
            <EuiFlexGroup direction="column" gutterSize="s">
              <EuiForm>
                <EuiFlexGroup gutterSize="xl" justifyContent="spaceBetween">
                  <EuiFlexItem grow={false}>
                    <MMOrderClient
                      focusState={focusState}
                      changeFocusState={(focusState: FocusState) =>
                        handleFocusStateFromClient(focusState)
                      }
                      order={order}
                      inputs={inputs}
                      client={client}
                      organisation={organisation}
                      onChange={onOrderChange}
                      deliveryMethods={deliveryMethods}
                      showValidation={showValidation.client}
                      hasValidation={hasValidation.client}
                      isEditable={isEditable}
                    />
                  </EuiFlexItem>
                  {/* <EuiFlexItem grow={false}>
                    <MMOrderOrderedAt order={order} onChange={onOrderChange} />
                  </EuiFlexItem> */}
                  {![FocusState.Loading, FocusState.Client].includes(
                    focusState
                  ) ? (
                    <EuiFlexItem grow={false} style={{ marginRight: "10px" }}>
                      <MMOrderInputWhoWhere
                        focusState={focusState}
                        changeFocusState={(focusState: FocusState) =>
                          setFocusState(focusState)
                        }
                        order={order}
                        inputs={inputs}
                        showValidation={showValidation.whoWhere}
                        hasValidation={hasValidation.whoWhere}
                        organisation={organisation}
                        personalDetails={personalDetails}
                        practitioners={practitioners}
                        locations={locations}
                        organisations={organisations}
                        deliveryMethods={deliveryMethods}
                        onChange={onOrderChange}
                        isEditable={isEditable}
                      />
                    </EuiFlexItem>
                  ) : (
                    <></>
                  )}
                </EuiFlexGroup>
                <EuiFlexGroup>
                  {![FocusState.Loading, FocusState.Client].includes(
                    focusState
                  ) ? (
                    renderTabs(tabs, showValidation)
                  ) : (
                    <></>
                  )}
                </EuiFlexGroup>
              </EuiForm>
            </EuiFlexGroup>
          </EuiPageBody>
          {![FocusState.Loading, FocusState.Client].includes(focusState) ? (
            <EuiPageBody paddingSize="xl" className="order-details">
              {!isLoading ? (
                <EuiFlexGroup gutterSize="s" direction="column">
                  <EuiFlexItem grow={true} className="order-tab-content">
                    {renderSelectedTab()}
                  </EuiFlexItem>
                  <EuiFlexItem
                    grow={false}
                    className="order-tab-buttons"
                    // style={{ height: "70px" }}
                  >
                    {renderButtons()}
                  </EuiFlexItem>
                </EuiFlexGroup>
              ) : (
                <EuiFlexGroup
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                >
                  <EuiLoadingSpinner size="l" />
                </EuiFlexGroup>
              )}
            </EuiPageBody>
          ) : (
            <></>
          )}
        </EuiFlexGrid>
      </EuiFlexItem>
      {!isLoading && focusState !== FocusState.Delivery ? renderOrder() : <></>}
    </EuiFlexGroup>
  );
}

export default MMOrderEntry;
