import {
  EuiButton,
  EuiComboBox,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiIcon,
  EuiSpacer,
  EuiSwitch,
  EuiText,
  EuiToolTip,
} from "@elastic/eui";
import ConnectAPIHelper from "api/connect-api-helper";
import AuthenticationHelper from "helpers/authentication-helper";
import txt from "helpers/text-helper";
import { onStringValue, toSelectOptions, updateField } from "hoc/helper-hooks";
import { Fragment, useEffect, useState } from "react";
import { Location, viewName } from "store/data/location/location";
import {
  PersonalDetails,
  fullName,
} from "store/data/personal-details/personal-details";
import {
  AssignedDevice,
  SCANNER_EMPTY,
  Scanner,
} from "store/data/scan/scanner";

const USER_IS_PRACTITIONER_DEFAULT: boolean = true;
const LOCATION_CHAR_LENGTH: number = 32;
const DESCRIPTION_CHAR_LENGTH: number = 255;
export type ScannerSave = (
  scanner: Scanner,
  assignedDevices: AssignedDevice[]
) => void;

export interface MMScannerProps {
  fields: {
    scanner: Scanner | null;
  };
  handleSubmit?: ScannerSave;
}

function MMScanner(props: MMScannerProps) {
  const api = new ConnectAPIHelper();
  const [scanner, setScanner] = useState(props.fields.scanner);
  const [locations, setLocations] = useState<Location[]>([]);
  const [users, setUsers] = useState<PersonalDetails[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);

  const [editAllowed, setEditAllowed] = useState(false);

  const getForSelection = (selections: any[], selectables: any[]) =>
    selections
      .map((selection: any) =>
        selectables.find((selectable: any) => selectable.id === selection.value)
      )
      .filter((found: any) => !!found);

  useEffect(() => {
    const load = async () => {
      const locationResult = await api.getAdminLocations({}, 1000);
      if (locationResult && locationResult.status === "OK") {
        setLocations(locationResult.result);
      } else {
        setLocations([]);
      }

      const userResult = await api.getAdminUsers({}, 1000);
      console.log("userResult", userResult);
      if (userResult && userResult.status === "OK") {
        setUsers(userResult.result);
      } else {
        setUsers([]);
      }
    };

    load();

    const setPermissions = async () => {
      setEditAllowed(
        await AuthenticationHelper.hasPermission("scanners#edit_all")
      );
    };
    setPermissions();
  }, []);

  useEffect(() => {
    if (locations.length > 0 && scanner) {
      setSelectedLocations(
        toSelectOptions(
          locations
            .filter((location: Location) =>
              location.assigned_devices.find(
                (device: AssignedDevice) => device.device_id === scanner.id
              )
            )
            .map((location: Location) => ({
              name: viewName(location),
              id: location.id,
            }))
        )
      );
    }
    if (users.length > 0 && scanner) {
      setSelectedUsers(
        toSelectOptions(
          users
            .filter((user: PersonalDetails) =>
              user.assigned_devices.find(
                (device: AssignedDevice) => device.device_id === scanner.id
              )
            )
            .map((user: PersonalDetails) => ({
              name: fullName(user),
              id: user.id,
            }))
        )
      );
    }
  }, [locations, users]);

  const onLocationChange = (selectedOptions: any[]) => {
    setSelectedLocations(selectedOptions);

    if (selectedOptions.length > 0) {
      setSelectedUsers([]);
      let updatedScanner: Scanner = { ...SCANNER_EMPTY, ...scanner };
      let foundLocations: Location[] = getForSelection(
        selectedOptions,
        locations
      );
      let locationDescription: string = foundLocations
        .map((location: any) => viewName(location))
        .join(", ");
      let locationText = foundLocations
        .map((location: any) => location.city)
        .join(", ");

      updatedScanner = updateField(
        updatedScanner,
        "description",
        locationDescription.substring(0, LOCATION_CHAR_LENGTH)
      );
      updatedScanner = updateField(updatedScanner, "location", locationText);
      setScanner(updatedScanner);
    }
  };

  const onUserChange = (selectedOptions: any[]) => {
    setSelectedUsers(selectedOptions);
    if (selectedOptions.length > 0) {
      setSelectedLocations([]);
      let updatedScanner: Scanner = { ...SCANNER_EMPTY, ...scanner };
      updatedScanner = updateField(updatedScanner, "location", "Mobile");

      let descriptionText: string = getForSelection(selectedOptions, users)
        .map((user: any) => fullName(user))
        .join(", ");

      updatedScanner = updateField(
        updatedScanner,
        "description",
        descriptionText.substring(0, DESCRIPTION_CHAR_LENGTH)
      );

      setScanner(updatedScanner);
    }
  };

  const onSubmit = (e: any) => {
    if (props.handleSubmit && scanner) {
      let assignedDevices: AssignedDevice[] = [];
      const assignedLocations: any = getForSelection(
        selectedLocations,
        locations
      ).map(
        (location: any) =>
          ({
            assigned_type: "location",
            assigned_id: location.id,
            device_id: scanner.id,
            device_serial: scanner.serial,
            device_description: `${scanner.location} ${scanner.description}`,
          }) as AssignedDevice
      );
      const assignedUsers: any = getForSelection(selectedUsers, users).map(
        (user: any) =>
          ({
            assigned_type: "personal_details",
            assigned_id: user.id,
            device_id: scanner.id,
            device_serial: scanner.serial,
            device_description: `${scanner.location} ${scanner.description}`,
          }) as AssignedDevice
      );
      assignedDevices = assignedDevices.concat(
        assignedLocations,
        assignedUsers
      );
      console.log("Scanner.onSubmit", { scanner, assignedDevices });
      props.handleSubmit(scanner, assignedDevices);
    }
  };

  return (
    <EuiForm>
      <EuiFormRow label={txt.get("admin.scanners.scanner.name")}>
        <EuiFieldText
          readOnly={!editAllowed}
          name="serial"
          value={scanner?.serial}
          onChange={(e) =>
            setScanner(updateField(scanner, "serial", onStringValue(e)))
          }
        />
      </EuiFormRow>
      <EuiSpacer size="s" />

      <EuiSpacer size="s" />
      <EuiFormRow
        label={txt.get("admin.locations.name")}
        labelAppend={
          <EuiToolTip
            position="right"
            content={txt.get("admin.scanners.setup_explanation")}
          >
            <EuiIcon type="questionInCircle" />
          </EuiToolTip>
        }
      >
        <EuiComboBox
          isDisabled={!editAllowed}
          aria-autocomplete="none"
          aria-label="Select location"
          placeholder={txt.uf(
            "generic.select_x",
            txt.get("admin.locations.name")
          )}
          // singleSelection={{ asPlainText: true }}
          options={toSelectOptions(
            locations.map((location: Location) => ({
              name: viewName(location),
              id: location.id,
            }))
          )}
          selectedOptions={selectedLocations}
          onChange={onLocationChange}
        />
      </EuiFormRow>
      <EuiFormRow label={txt.get("admin.users.name")}>
        <EuiComboBox
          isDisabled={!editAllowed}
          aria-autocomplete="none"
          aria-label="Select user"
          placeholder={txt.uf("generic.select_x", txt.get("admin.users.name"))}
          // singleSelection={{ asPlainText: true }}
          options={toSelectOptions(
            users.map((user: PersonalDetails) => ({
              name: fullName(user),
              id: user.id,
            }))
          )}
          selectedOptions={selectedUsers}
          onChange={onUserChange}
        />
      </EuiFormRow>
      <EuiFormRow
        label={`${txt.get(
          "admin.scanners.scanner.location"
        )}  (max ${LOCATION_CHAR_LENGTH} chars)`}
      >
        <EuiFieldText
          readOnly={!editAllowed}
          name="location"
          maxLength={LOCATION_CHAR_LENGTH}
          value={scanner?.location}
          onChange={(e) =>
            setScanner(updateField(scanner, "location", onStringValue(e)))
          }
        />
      </EuiFormRow>
      <EuiFormRow
        label={`${txt.get(
          "admin.scanners.scanner.description"
        )} (max ${DESCRIPTION_CHAR_LENGTH} chars)`}
      >
        <EuiFieldText
          readOnly={!editAllowed}
          name="description"
          maxLength={DESCRIPTION_CHAR_LENGTH}
          value={scanner?.description}
          onChange={(e) =>
            setScanner(updateField(scanner, "description", onStringValue(e)))
          }
        />
      </EuiFormRow>

      {editAllowed ? (
        <Fragment>
          <EuiSpacer size="xxl" />
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiButton size="s" onClick={onSubmit} fill={true}>
                {txt.get("generic.save")}
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </Fragment>
      ) : (
        <></>
      )}
    </EuiForm>
  );
}
export default MMScanner;
