import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiText } from "@elastic/eui";
import { useEffect, useState } from "react";
import {
  ORDER_EMPTY,
  Order,
  OrderLine,
  OrderStatus,
} from "store/data/order/order";
import txt from "helpers/text-helper";
import { Hand } from "store/data/scan/scan";
import { MMOrderEntryInputProps } from "./order-entry";
import MMProductCard from "components/products/product-card";
import { productOption } from "helpers/product-helper";
import {
  EMPTY_PRODUCT_INPUT,
  MMOrderLineProductInput,
  orderLinesToProducts,
  productsToOrderLines,
} from "./order-line-entry";
import { OnProductSelect } from "components/products/product-cards";
import MMOrderProductSelectDetails from "./inputs/order-product-select-details";
import ConnectAPIHelper from "api/connect-api-helper";
import { OnProductLine } from "./inputs/order-product-select";
import { Workflow } from "store/data/production/workflow";
import DateHelper from "helpers/date-helper";
import AuthenticationHelper from "helpers/authentication-helper";
import {
  isOrderLineInputValid,
  OrderInputs,
  OrderLineInputs,
} from "./order-inputs";

const DAYS_AFTER_PRODUCED_READY_FOR_DELIVERY: number = 1;

//by name, because product doesn't have workflow id yet (as opposed to a production)
const toWorkflowLookup = (workflows?: Workflow[]) => {
  if (workflows) {
    let lookup: any = {};
    workflows.forEach((workflow: Workflow) => {
      if (workflow.name) {
        lookup[workflow.name] = workflow;
      }
    });
    return lookup;
  } else {
    return {};
  }
};

const toOrderLineCards = (order?: Order | null, products?: any[]) => {
  return order && order.order_lines && order.order_lines.length > 0
    ? orderLinesToProducts(order.order_lines, products || []).filter(
        (orderLineProduct: MMOrderLineProductInput) => !!orderLineProduct.code
      )
    : [];
};

const toDueDate = (
  product: any,
  workflowLookup: any,
  fromDate: Date = new Date()
) => {
  const lastStep =
    product && product.workflow && workflowLookup[product.workflow]
      ? workflowLookup[product.workflow].workflow_steps[
          workflowLookup[product.workflow].workflow_steps.length - 1
        ]
      : null;

  if (lastStep) {
    return DateHelper.addBusinessDays(
      lastStep.delivery_day + DAYS_AFTER_PRODUCED_READY_FOR_DELIVERY,
      fromDate
    );
  }
  return null;
};

// DateHelper.toDate(
//   DateHelper.addBusinessDays(
//     lastStep.delivery_day,
//     order_date
//   )
// )
function MMOrderLineCards(props: MMOrderEntryInputProps) {
  const lang: string = txt.lang();
  const [currentProductOption, setCurrentProductOption] = useState<any | null>(
    null
  );
  const [visibleIndices, setVisibleIndices] = useState<number[]>(
    props.visibleIndices || []
  );
  const [orderLineProducts, setOrderLineProducts] = useState<
    MMOrderLineProductInput[]
  >(toOrderLineCards(props.order, props.products || []));

  const [workflowLookup, setWorkflowLookup] = useState<any>(
    toWorkflowLookup(props.workflows)
  );

  const [dueDates, setDueDates] = useState<any>(null);
  const [dueDate, setDueDate] = useState<Date | null>(null);

  useEffect(() => {
    const currentOrderLines: any[] = toOrderLineCards(
      props.order,
      props.products || []
    );
    setOrderLineProducts(currentOrderLines);
    setVisibleIndices((oldIndices: number[]) => props.visibleIndices || []);

    if (AuthenticationHelper.isInternalUser()) {
      setWorkflowLookup(toWorkflowLookup(props.workflows));

      const dueDates: any = currentOrderLines.map((orderLineProduct) =>
        toDueDate(
          orderLineProduct.variation,
          workflowLookup,
          !props.order?.status || props.order.status === OrderStatus.Drafted
            ? new Date()
            : props.order?.ordered_at
        )
      );
      let dueDate: any = null;
      for (let i = 0; i < dueDates.length; i++) {
        const date: Date | null = dueDates[i];
        if (date && (dueDate === null || DateHelper.isBefore(dueDate, date))) {
          dueDate = date;
        }
      }
      setDueDate(dueDate);
      setDueDates([...dueDates]);
    } else {
      setWorkflowLookup({});
      setDueDate(null);
      setDueDates([]);
    }
  }, [props.workflows, props.order, props.products, props.visibleIndices]);

  useEffect(() => {
    if (props.hasValidation && props.order && props.order.order_lines) {
      console.log("open input for order line cards", props.inputs);

      for (let i = 0; i < props.order.order_lines.length; i++) {
        const orderLine: OrderLine = props.order.order_lines[i];
        const orderLineInputs: OrderLineInputs | null =
          toOrderLineInputs(props.inputs, { data_order_line_index: i }) || null;
        console.log("checking ", i, orderLine, orderLineInputs);
        if (!isOrderLineInputValid(orderLine, orderLineInputs)) {
          if (orderLineProducts.length > i) {
            console.log(
              "open the first product with validation to show",
              orderLineProducts[i]
            );

            setCurrentProductOption(
              productOption(
                orderLineProducts[i].variation,
                lang,
                orderLineProducts[i],
                i
              )
            );
            break;
          }
        }
      }
    }
  }, [props.hasValidation, props.inputs, props.order]);

  const onProductSelect: OnProductSelect = (
    product: any,
    selected: boolean
  ) => {
    if (selected) {
      console.log("product select, show popover", product);
      setCurrentProductOption(product);
    } else {
      setCurrentProductOption(null);
    }
  };

  const onUpdateProductLine: OnProductLine = (
    updatedOrderLine: MMOrderLineProductInput,
    index?: number | null
  ) => {
    let newLines: MMOrderLineProductInput[] = [];
    if (index !== null && index !== undefined && !isNaN(index)) {
      for (let i = 0; i < orderLineProducts.length; i++) {
        const orderLine: MMOrderLineProductInput = orderLineProducts[i];
        if (i === index) {
          newLines.push(updatedOrderLine);
        } else {
          newLines.push(orderLine);
        }
      }
    } else {
      newLines = orderLineProducts.concat([updatedOrderLine]);
    }

    if (props.onChange) {
      props.onChange(
        {
          ...ORDER_EMPTY,
          ...props.order,
          order_lines: productsToOrderLines(newLines),
        },
        true
      );
    } else {
      setOrderLineProducts((orderLines: MMOrderLineProductInput[]) => newLines);
    }

    setCurrentProductOption(null);
  };

  const onDeleteProductLine: OnProductLine = (
    deletedOrderLine: MMOrderLineProductInput,
    index?: number | null
  ) => {
    console.log("onDeleteProductLine");
    let newLines: MMOrderLineProductInput[] = [];

    if (index !== undefined && index !== null && !isNaN(index)) {
      for (let i = 0; i < orderLineProducts.length; i++) {
        const orderLine = orderLineProducts[i];
        if (index !== i) {
          newLines.push(orderLine);
        }
      }
    } else {
      newLines = [...orderLineProducts];
    }

    if (props.onChange) {
      props.onChange({
        ...ORDER_EMPTY,
        ...props.order,
        order_lines: productsToOrderLines(newLines),
      });
    } else {
      setOrderLineProducts(newLines);
    }
    setCurrentProductOption(null);
  };

  const onDuplicateProductLine: OnProductLine = (
    duplicatedOrderLine: MMOrderLineProductInput,
    index?: number | null
  ) => {
    let newLines: MMOrderLineProductInput[] = [];

    let duplication: MMOrderLineProductInput = { ...duplicatedOrderLine };
    delete duplication.costing;

    if (index !== undefined && index !== null && !isNaN(index)) {
      for (let i = 0; i < orderLineProducts.length; i++) {
        const orderLine = orderLineProducts[i];
        newLines.push(orderLine);
        if (index === i) {
          newLines.push(duplication);
        }
      }
    } else {
      newLines = [...orderLineProducts];
    }

    if (props.onChange) {
      props.onChange(
        {
          ...ORDER_EMPTY,
          ...props.order,
          order_lines: productsToOrderLines(newLines),
        },
        true
      );
    } else {
      setOrderLineProducts(newLines);
    }
    setCurrentProductOption(null);
  };

  const toOrderLineInputs = (
    inputs?: OrderInputs,
    currentProductOption?: any
  ): OrderLineInputs | undefined => {
    console.log("toOrderLineInputs", inputs, currentProductOption);
    if (
      inputs?.order_lines &&
      currentProductOption?.data_order_line_index !== undefined &&
      inputs.order_lines.length > currentProductOption?.data_order_line_index
    ) {
      return inputs.order_lines[currentProductOption.data_order_line_index];
    }
    return undefined;
  };

  const renderProductSelectDetail = (currentProductOption: any) => {
    let hasValidation: boolean = false;
    let orderLineInputs: OrderLineInputs | undefined;
    if (props.hasValidation) {
      let orderLine: OrderLine | null =
        props.order &&
        props.order.order_lines &&
        currentProductOption &&
        props.order.order_lines.length >
          currentProductOption.data_order_line_index
          ? props.order.order_lines[currentProductOption.data_order_line_index]
          : null;
      orderLineInputs =
        toOrderLineInputs(props.inputs, currentProductOption) || undefined;
      hasValidation =
        orderLine && orderLineInputs
          ? !isOrderLineInputValid(orderLine, orderLineInputs)
          : false;
      console.log(
        "renderProductSelectDetail",
        props.hasValidation,
        hasValidation,
        orderLine,
        orderLineInputs
      );
    }
    return currentProductOption ? (
      <MMOrderProductSelectDetails
        hasValidation={hasValidation}
        orderLineInputs={orderLineInputs}
        products={props.products || []}
        api={props.api || new ConnectAPIHelper()}
        order={props.order || null}
        productOption={currentProductOption}
        onCancel={() => setCurrentProductOption(null)}
        onSelect={props.isEditable ? onUpdateProductLine : undefined}
        onDelete={props.isEditable ? onDeleteProductLine : undefined}
        onDuplicate={props.isEditable ? onDuplicateProductLine : undefined}
        isEditable={props.isEditable}
        remakeReasons={props.remakeReasons}
        orderTypes={props.orderTypes}
        clientProductions={props.clientProductions}
        showProductionDetails={props.showProductionDetails}
      />
    ) : (
      <></>
    );
  };

  const renderDueDate = (date: Date) => {
    return (
      <EuiText size="xs">
        <EuiText size="xs">
          <EuiIcon
            type="calendar"
            color="danger"
            style={{ position: "relative", top: "-2px" }}
          />{" "}
          {txt.lo("orders.order.deliver_from", DateHelper.toDate(date))}
        </EuiText>
      </EuiText>
    );
  };

  return (
    <EuiFlexGroup
      direction="column"
      gutterSize="none"
      style={{ maxWidth: "250px" }}
    >
      <EuiFlexItem
        style={{
          textAlign: "center",
        }}
      >
        {AuthenticationHelper.isInternalUser() &&
        props.order?.status &&
        [
          OrderStatus.Drafted,
          OrderStatus.Submitted,
          OrderStatus.Accepted,
          OrderStatus.ProducedPartial,
          OrderStatus.PackagedPartial,
        ].includes(props.order.status) &&
        dueDate ? (
          renderDueDate(dueDate)
        ) : (
          // props.order?.status &&
          //   [
          //     OrderStatus.Packaged,
          //     OrderStatus.Produced,
          //     OrderStatus.DeliveredPartial,
          //   ].includes(props.order.status) ? (
          //   <EuiText size="xs" color="#909090">
          //     <EuiText size="xs">
          //       {txt.lo("orders.order.ready_for_delivery")}
          //     </EuiText>
          //   </EuiText>
          // ) :
          <></>
        )}
      </EuiFlexItem>
      {orderLineProducts.map(
        (orderLineProduct: MMOrderLineProductInput, i: number) => (
          <EuiFlexItem
            key={`product-${i}`}
            className="order-card"
            data-index={i}
            grow={false}
          >
            <EuiFlexGroup
              gutterSize="xl"
              id={`order-line-card-${i}-popover-anchor`}
            >
              {
                <MMProductCard
                  direction="row"
                  api={props.api}
                  className={
                    visibleIndices && visibleIndices.includes(i)
                      ? "product-active"
                      : ""
                  }
                  product={productOption(
                    orderLineProduct.variation,
                    lang,
                    orderLineProduct,
                    i
                  )}
                  onSelectIcon={
                    props.onProductSelect
                      ? "images/measure.svg"
                      : props.isEditable
                        ? "pencil"
                        : "eye"
                  }
                  onSubSelectIcon={
                    props.onProductSelect
                      ? props.isEditable
                        ? "pencil"
                        : "eye"
                      : undefined
                  }
                  onSelect={
                    props.onProductSelect
                      ? props.onProductSelect
                      : onProductSelect
                  }
                  onSubSelect={
                    props.onProductSelect ? onProductSelect : undefined
                  }
                />
              }
            </EuiFlexGroup>
          </EuiFlexItem>
        )
      )}
      {renderProductSelectDetail(currentProductOption)}
    </EuiFlexGroup>
  );
}

export default MMOrderLineCards;
