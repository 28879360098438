import {
  EuiCheckbox,
  EuiFilterButton,
  EuiFilterGroup,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiTextArea,
  useIsWithinBreakpoints,
} from "@elastic/eui";
import {
  MMOrderLineFieldProps,
  MMOrderLineProductInput,
} from "../order-line-entry";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import AuthenticationHelper from "helpers/authentication-helper";
import { getNeed, Need } from "store/data/need/need";
import { ValidationResult } from "helpers/input-helper";

function MMPreviousAid(props: MMOrderLineFieldProps) {
  const isMobile: boolean = useIsWithinBreakpoints(["xs", "s"]);
  const [hasPreviousAid, setHasPreviousAid] = useState<boolean | null>();
  const [previousAid, setPreviousAid] = useState<string>();
  const [hasPreviousAidError, setHasPreviousAidError] = useState<string>("");
  const [previousAidError, setPreviousAidError] = useState<string>("");
  const [isTextToSentenceShown, setIsTextToSentenceShown] =
    useState<boolean>(false);

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      setIsTextToSentenceShown(
        await AuthenticationHelper.hasPermission([
          "contracts#read_all",
          "contracts#edit_all",
        ])
      );
    };
    setInterfaceForPermissions();
  }, []);
  useEffect(() => {
    // old_product.used
    // old_product.not_used_description
    // old_product.description

    let deprecatedOldProductNeed: string = props.order?.needs
      ? getNeed(props.order?.needs, "old_product.used")
      : "";
    let deprecatedOldProductDescription: string =
      props.order?.needs &&
      deprecatedOldProductNeed &&
      deprecatedOldProductNeed === "true"
        ? getNeed(props.order?.needs, "old_product.description")
        : "";
    let deprecatedOldProductNotUsedNeedDescription: string = props.order?.needs
      ? getNeed(props.order?.needs, "old_product.not_used_description")
      : "";

    if (
      deprecatedOldProductNeed === "" &&
      deprecatedOldProductNotUsedNeedDescription !== ""
    ) {
      deprecatedOldProductNeed = "false";
    }

    let mergedDeprecatedDescription: string =
      deprecatedOldProductNeed === "true"
        ? deprecatedOldProductDescription
        : deprecatedOldProductNeed === "false"
          ? deprecatedOldProductNotUsedNeedDescription
          : "";
    let newHasPreviousAid: boolean | null | undefined =
      props.selection.hasPreviousAid;

    let newPreviousAid: string | undefined = props.selection.previousAid;

    // Calculated hasPreviousAid value according to product data of a deprecated-intake-screen.
    let has =
      (newHasPreviousAid === undefined || newHasPreviousAid === null) &&
      deprecatedOldProductNeed !== ""
        ? deprecatedOldProductNeed === "true"
        : newHasPreviousAid === undefined
          ? null
          : newHasPreviousAid;

    // Calculated previousAid value according to product data of a deprecated-intake-screen.
    let txt =
      (newPreviousAid === undefined || newPreviousAid === "") &&
      mergedDeprecatedDescription !== ""
        ? mergedDeprecatedDescription
        : newPreviousAid;

    console.log({
      deprecatedOldProductNeed,
      mergedDeprecatedDescription,
      newHasPreviousAid,
      newPreviousAid,
      has,
      txt,
    });

    setHasPreviousAid(has);
    setPreviousAid(txt);

    props.selection.hasPreviousAid = has;
    props.selection.previousAid = txt;
  }, [props.selection, props.order]);

  useEffect(() => {
    if (props.hasValidation && props.orderLineInputs) {
      if (
        props.orderLineInputs.has_previous_aid &&
        props.orderLineInputs.has_previous_aid.mandatory &&
        props.orderLineInputs.has_previous_aid.validator
      ) {
        let validationResult: ValidationResult =
          props.orderLineInputs.has_previous_aid.validator(hasPreviousAid);
        setHasPreviousAidError(
          !validationResult.result ? validationResult.message : ""
        );
      } else {
        setHasPreviousAidError("");
      }
      if (
        props.orderLineInputs.previous_aid &&
        props.orderLineInputs.previous_aid.mandatory &&
        props.orderLineInputs.previous_aid.validator
      ) {
        let validationResult: ValidationResult =
          props.orderLineInputs.previous_aid.validator(previousAid);
        setPreviousAidError(
          !validationResult.result ? validationResult.message : ""
        );
      } else {
        setPreviousAidError("");
      }
    } else {
      setHasPreviousAidError("");
      setPreviousAidError("");
    }
  }, [props.hasValidation, props.orderLineInputs, hasPreviousAid, previousAid]);

  const onPreviousAidChange = (previousAid: string) => {
    if (props.onChange) {
      props.onChange({
        ...(props.selection || {}),
        previousAid,
      } as MMOrderLineProductInput);
    } else {
      setPreviousAid(previousAid);
    }
  };

  const onHasPreviousAidChange = (hasPreviousAid: boolean | null) => {
    if (props.onChange) {
      props.onChange({
        ...(props.selection || {}),
        hasPreviousAid,
      } as MMOrderLineProductInput);
    } else {
      setHasPreviousAid(hasPreviousAid);
    }
  };

  const css = () => {
    let result: any = {};

    if (!isMobile) {
      result = {
        ...result,
        width: "100%",
        minWidth: "260px",
        maxWidth: props.maxWidth ? props.maxWidth : "475px",
        // height: "72px",
      };
    }

    return result;
  };

  return (
    <EuiFlexGroup gutterSize="s" direction="column" style={css()}>
      <EuiFlexItem style={{ position: "relative" }}>
        <EuiFormRow
          className="product-input"
          display="rowCompressed"
          label={
            props.showTitle
              ? txt.get("orders.order.has_previous_aid")
              : undefined
          }
          isInvalid={!!hasPreviousAidError}
          error={hasPreviousAidError}
          // style={css()}
        >
          <EuiFilterGroup contentEditable={false} compressed={true}>
            <EuiFilterButton
              isDisabled={!props.isEditable}
              style={{
                minWidth: "26px",
                padding: "0px",
                margin: "auto",
              }}
              hasActiveFilters={hasPreviousAid === true}
              onClick={(e: any) =>
                onHasPreviousAidChange(hasPreviousAid === true ? null : true)
              }
            >
              {txt.get("generic.yes")}
            </EuiFilterButton>
            <EuiFilterButton
              isDisabled={!props.isEditable}
              style={{
                minWidth: "26px",
                padding: "0px",
                margin: "auto",
              }}
              hasActiveFilters={hasPreviousAid === false}
              onClick={(e: any) =>
                onHasPreviousAidChange(hasPreviousAid === false ? null : false)
              }
            >
              {txt.get("generic.no")}
            </EuiFilterButton>
          </EuiFilterGroup>
        </EuiFormRow>
      </EuiFlexItem>
      {hasPreviousAid !== null ? (
        <EuiFlexItem style={{ position: "relative" }}>
          <EuiFormRow
            className="product-input"
            display="rowCompressed"
            label={
              props.showTitle
                ? txt.get("orders.order.notes_previous_aid")
                : undefined
            }
            isInvalid={!!previousAidError}
            error={previousAidError}
            style={css()}
          >
            <EuiTextArea
              onFocus={props.onFocus || undefined}
              value={previousAid}
              rows={3}
              compressed={true}
              // style={css()}
              placeholder={
                hasPreviousAid
                  ? txt.get("orders.order.notes_previous_aid")
                  : txt.get("orders.order.notes_no_previous_aid")
              }
              onChange={(e) => setPreviousAid(e.target.value)}
              onBlur={(e) => onPreviousAidChange(e.target.value)}
            />
          </EuiFormRow>
        </EuiFlexItem>
      ) : (
        <></>
      )}
    </EuiFlexGroup>
  );
}

export default MMPreviousAid;
