import {
  EuiButton,
  EuiButtonIcon,
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiDatePicker,
  EuiFieldText,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiHealth,
  EuiHorizontalRule,
  EuiIcon,
  EuiImage,
  EuiPopover,
  EuiSpacer,
  EuiSuperSelect,
  EuiText,
  EuiTitle,
  EuiToolTip,
} from "@elastic/eui";
import MMFileUpload from "components/layouts/input/file-upload";
import { FileType } from "helpers/file-upload-helper";
import txt from "helpers/text-helper";
import { AttachedToType, AttachmentType } from "store/data/file/file";
import { ORDER_EMPTY, OrderStatus } from "store/data/order/order";
import { File } from "store/data/file/file";
import { Fragment, useEffect, useState } from "react";
import ConnectAPIHelper from "api/connect-api-helper";
import StringHelper from "helpers/string-helper";
import { MMOrderEntryInputProps } from "../order-entry";
import MMFiles from "components/layouts/files/files";
import { useDispatch } from "react-redux";
import { toastAdd } from "store/components/toast/toast";
import MomentHelper from "helpers/moment-helper";
import { updateField } from "hoc/helper-hooks";
import {
  REFERRAL_EMPTY,
  Referral,
  ReferralStatus,
  referralNeedsFollowUp,
  referralStatusDescription,
  referralToHealth,
} from "store/data/referral/referral";
import { getOrderInput } from "../order-inputs";
import { Input } from "helpers/input-helper";
import MMReferralStatusLogs from "components/orders/referral-log";
import AuthenticationHelper from "helpers/authentication-helper";
import DateHelper from "helpers/date-helper";
import { formalName } from "store/data/personal-details/personal-details";
import { indicationDescription } from "store/data/indication/indication";
import { Moment } from "moment";
import MMTip from "components/layouts/tip/tip";
import MMIcon from "components/layouts/icon/icon";

function MMOrderReferral(props: MMOrderEntryInputProps) {
  const dispatch = useDispatch();
  const api = new ConnectAPIHelper();

  const [referral, setReferral] = useState(props.order?.referral || undefined);
  const [isNoReferralOpen, setIsNoReferralOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [referralError, setReferralError] = useState("");
  const [referredAtError, setReferredAtError] = useState("");
  const [clientReferrals, setClientReferrals] = useState<Referral[]>(
    props.clientReferrals || []
  );
  const [clientReferralOptions, setClientReferralOptions] = useState<any[]>([]);
  const [selectedClientReferralOption, setSelectedClientReferralOption] =
    useState<any>(
      props.order?.referral_id ? props.order.referral_id + "" : "_"
    );
  const [reasons, setReasons] = useState<EuiComboBoxOptionOption<unknown>[]>(
    []
  );
  const [rejectionReasons, setRejectionReasons] = useState<
    EuiComboBoxOptionOption<unknown>[]
  >([]);
  const [showRejection, setShowRejection] = useState(false);
  const [canEditRejection, setCanEditRejection] = useState(false);

  const [statusOptions] = useState(
    Object.values(ReferralStatus)
      .filter((value: any) => isNaN(value))
      .map((status: string) => ({
        inputDisplay: (
          <EuiHealth color={referralToHealth(status)}>
            {referralStatusDescription(status as ReferralStatus)}
          </EuiHealth>
        ),
        value: status,
      }))
  );

  const [selectedReasons, setSelectedReasons] = useState<
    EuiComboBoxOptionOption<unknown>[]
  >([]);

  const [selectedRejections, setSelectedRejections] = useState<
    EuiComboBoxOptionOption<unknown>[]
  >([]);

  const [status, setStatus] = useState("");
  const [files, setFiles] = useState<any[]>([]);
  const [fileToAdd, setFileToAdd] = useState<File>();

  const [isReferralLogOpen, setIsReferralLogOpen] = useState<boolean>(false);

  useEffect(() => {
    const referralFiles =
      props.order && props.order.referral && props.order.referral.files
        ? props.order.referral.files.filter(
            (file: any) => file.attachment.type === "referral_letter_reference"
          )
        : [];
    setFiles(referralFiles);

    setReferral(props.order?.referral || undefined);

    setSelectedReasons(
      props.order &&
        props.order.referral &&
        props.order.referral.no_referral_reasons
        ? props.order.referral.no_referral_reasons
            .split(",")
            .map((reason: string) => ({ label: reason }))
        : []
    );

    setSelectedRejections(
      props.order &&
        props.order.referral &&
        props.order.referral.rejection_reasons
        ? props.order.referral.rejection_reasons
            .split(",")
            .map((reason: string) => ({ label: reason }))
        : []
    );

    if (props.inputs?.referral) {
      if (!props.order?.referral?.referred_at) {
        setReferredAtError(txt.get("orders.order.add_referred_at"));
      } else {
        setReferredAtError("");
      }

      if (
        (props.order?.referral?.files &&
          props.order.referral.files.length > 0) ||
        (!!props.order?.referral?.no_referral_reasons &&
          !!props.order?.referral?.no_referral_follow_up)
      ) {
        setReferralError("");
      } else {
        setReferralError(
          AuthenticationHelper.isInternalUser()
            ? txt.get("orders.order.add_referral_or_missing")
            : txt.get("orders.order.add_referral")
        );
      }
    }

    const setInterfaceForPermissions = async () => {
      setShowRejection(
        AuthenticationHelper.isInternalUser() &&
          props.order?.referral?.status === ReferralStatus.Rejected
      );
      setCanEditRejection(
        await AuthenticationHelper.hasPermission([
          "referrals#accept_all",
          "referrals#accept_internal",
        ])
      );
    };

    setInterfaceForPermissions();
  }, [props.order]);

  const handleIntakeDate = (referral: Referral): Referral => {
    if (
      props.order &&
      props.order.status !== OrderStatus.Drafted &&
      !referral.intake_at &&
      props.order.ordered_at
    ) {
      console.log("handleIntakeDaet");
      return updateField(referral, "intake_at", props.order.ordered_at);
    }
    return referral;
  };
  useEffect(() => {
    let result: any[] = [];
    const noSelection = [
      {
        value: "_",
        inputDisplay: txt.get("orders.order.choose_referral"),
      },
    ];
    // console.log("clientProductions", clientProductions);
    result = noSelection.concat(
      !clientReferrals || !clientReferrals.map
        ? []
        : clientReferrals.map((referral: Referral) => {
            return {
              value: referral.id + "",
              inputDisplay: txt.get(
                "orders.order.referral_from",
                referral.referred_at
                  ? DateHelper.toDate(referral.referred_at)
                  : txt.get("generic.unknown")
              ),
              dropdownDisplay: (
                <Fragment>
                  <EuiText size="s" style={{ fontWeight: "bold" }}>
                    {referral.referred_at
                      ? DateHelper.toDate(referral.referred_at)
                      : txt.get("generic.unknown")}
                    {referral.orders && referral.orders.length > 0 ? (
                      <EuiText
                        size="xs"
                        color="subdued"
                        style={{ float: "right" }}
                      >
                        PN
                        {referral.orders[0].client_code}
                      </EuiText>
                    ) : (
                      <></>
                    )}
                  </EuiText>
                  <EuiText size="s" color="subdued">
                    <p style={{ whiteSpace: "nowrap" }}>
                      {referral.referrer?.person
                        ? formalName(referral.referrer?.person)
                        : "-"}
                      <br />
                      {referral.referrer?.organisation
                        ? referral.referrer.organisation.name
                        : referral.referrer?.alternative_organisation}
                      <br />
                      {indicationDescription(referral.indications)}
                    </p>
                  </EuiText>
                </Fragment>
              ),
            };
          })
    );

    setClientReferralOptions(result);
  }, [clientReferrals]);

  useEffect(() => {
    setClientReferrals(props.clientReferrals || []);
  }, [props.clientReferrals]);

  const onNoReferralFollowUpChanged = (
    noReferralFollowUp: string,
    updateOrder: boolean = true
  ) => {
    const updatedReferral: Referral = handleIntakeDate(
      updateField(
        referral || REFERRAL_EMPTY,
        "no_referral_follow_up",
        noReferralFollowUp
      )
    );

    if (updateOrder && props.onChange) {
      props.onChange(
        updateField(props.order || ORDER_EMPTY, "referral", updatedReferral)
      );
    } else {
      setReferral(updatedReferral);
    }
  };

  const onReferredAtChanged = (value: Moment | null) => {
    if (value) {
      // This is done because the related column in the database is a timestamp.
      // The function expects a date (yyyy-mm-dd) value from a datepicker.
      // Here, it sets the "time" part to combat timezone confusion.
      value.second(0);
      value.minute(0);
      value.hour(12);
    }

    const referredAt = MomentHelper.toDate(value);

    const updatedReferral: Referral = handleIntakeDate(
      updateField(referral || REFERRAL_EMPTY, "referred_at", referredAt)
    );

    if (props.onChange) {
      props.onChange(
        updateField(props.order || ORDER_EMPTY, "referral", updatedReferral)
      );
    } else {
      setReferral(updatedReferral);
    }
  };

  const onIntakeAtChanged = (value: Moment | null) => {
    if (value) {
      // This is done because the related column in the database is a timestamp.
      // The function expects a date (yyyy-mm-dd) value from a datepicker.
      // Here, it sets the "time" part to combat timezone confusion.
      value.second(0);
      value.minute(0);
      value.hour(12);
    }

    const intakeAt = MomentHelper.toDate(value);

    const updatedReferral: Referral = handleIntakeDate(
      updateField(referral || REFERRAL_EMPTY, "intake_at", intakeAt)
    );

    if (props.onChange) {
      props.onChange(
        updateField(props.order || ORDER_EMPTY, "referral", updatedReferral)
      );
    } else {
      setReferral(updatedReferral);
    }
  };

  const onStatusChanged = (status: string) => {
    console.log("onStatusChanged", status);
    if (props.onChange) {
      const updatedReferral: Referral = handleIntakeDate(
        updateField(referral, "status", status)
      );

      props.onChange(
        updateField(
          {
            ...ORDER_EMPTY,
            ...props.order,
          },
          "referral",
          updatedReferral
        )
      );
    } else {
      setStatus(status);
    }
  };
  const onSelectedReasonsChanged = (selected: any[]) => {
    if (props.onChange) {
      const selectedString: string = selected
        .map((selection: any) => selection.label)
        .join(",");
      console.log("onSelectedReasonsChanged", selectedString);
      const updatedReferral: Referral = handleIntakeDate(
        updateField(
          updateField(
            referral,
            "status",
            selectedString.indexOf("n/a") >= 0 ||
              selectedString.indexOf("n.v.t") >= 0
              ? ReferralStatus.NotApplicable
              : referral?.status
          ),
          "no_referral_reasons",
          selectedString
        )
      );

      props.onChange(
        updateField(
          {
            ...ORDER_EMPTY,
            ...props.order,
          },
          "referral",
          updatedReferral
        )
      );
    } else {
      setSelectedReasons(selected);
    }
  };

  const onSelectedRejectionsChanged = (selected: any[]) => {
    if (props.onChange) {
      const selectedString: string = selected
        .map((selection: any) => selection["data-id"] || selection.label)
        .join(",");
      console.log("onSelectedRejectionsChanged", selectedString);
      const updatedReferral: Referral = handleIntakeDate(
        updateField(referral, "rejection_reasons", selectedString)
      );

      props.onChange(
        updateField(
          {
            ...ORDER_EMPTY,
            ...props.order,
          },
          "referral",
          updatedReferral
        )
      );
    } else {
      setSelectedRejections(selected);
    }
  };

  const handleClientReferralOptionChange = (currentOption: string) => {
    let referral: Referral | undefined =
      currentOption === "_"
        ? undefined
        : clientReferrals.find(
            (referral: Referral) => referral.id === parseInt(currentOption)
          );

    console.log("referral", { currentOption, referral });

    if (referral) {
      delete referral.orders;
    }
    if (props.onChange) {
      props.onChange(
        updateField(
          updateField(
            {
              ...ORDER_EMPTY,
              ...props.order,
            },
            "referral_id",
            referral ? referral.id : null
          ),
          "referral",
          referral
        )
      );
    } else {
      setReferral(referral);
    }

    setSelectedClientReferralOption(currentOption);
  };

  useEffect(() => {
    const api = new ConnectAPIHelper();

    const getReasons = async () => {
      setIsLoading(true);
      const reasons = await api.getNoReferralReasons();
      setReasons(
        reasons
          ? reasons
              .filter((reason: string) => !!reason)
              .map((reason: string) => ({ label: reason }))
          : []
      );

      // reasons
      //   ? reasons.map((reason: string) => {
      //       const key: string = StringHelper.snakify(reason);
      //       const translation = txt.lo(
      //         `orders.order.missing_referral_reasons.${key}`
      //       );
      //       return {
      //         label: translation !== key ? translation : reason,
      //       };
      //     })
      //   : []
      // );
      setIsLoading(false);
    };

    if (isNoReferralOpen) {
      getReasons();
    }
  }, [isNoReferralOpen]);

  useEffect(() => {
    const api = new ConnectAPIHelper();

    const getRejectionReasons = async () => {
      setIsLoading(true);
      const reasons = await api.getReferralRejectionReasons();
      setRejectionReasons(
        reasons && reasons.filter
          ? reasons
              .filter((reason: string) => !!reason)
              .map((reason: string) => ({ label: reason }))
          : []
      );

      // const mapped = reasons?.map
      //   ? reasons.map((reason: string) => {
      //       const key: string = StringHelper.snakify(reason);
      //       const translationKey: string = `orders.order.rejection_reasons.${key}`;
      //       const translation: string = txt.lo(translationKey);

      //       return {
      //         label:
      //           translation !== `[${translationKey}]` ? translation : reason,
      //         "data-id": reason,
      //       };
      //     })
      //   : [];
      // console.log("getRejectionReasons", reasons, mapped);

      // setRejectionReasons(mapped);
      setIsLoading(false);
    };

    if (showRejection) {
      getRejectionReasons();
    }
  }, [showRejection]);

  const handleAdded = (file: File) => {
    setFileToAdd((old) => file);
  };

  useEffect(() => {
    if (fileToAdd && props.onChange && props.order) {
      let files: File[] = (props.order?.referral?.files || []).concat(
        fileToAdd ? [fileToAdd] : []
      );

      const updatedReferral: Referral = updateField(
        {
          ...(referral || REFERRAL_EMPTY),
          status:
            referral?.status && referral?.status === ReferralStatus.Accepted
              ? referral?.status
              : ReferralStatus.Submitted,
        },
        "files",
        files
      );

      props.onChange(
        updateField(props.order || ORDER_EMPTY, "referral", updatedReferral)
      );
      setFileToAdd(undefined);
    }
  }, [fileToAdd]);

  const handleDelete = (fileId: number) => {
    const deleteFile = async (fileId: number) => {
      const result = await api.removeOrderFile(
        fileId,
        props.order?.id || undefined,
        props.order?.referral?.id || undefined
      );
      if (
        result &&
        props.onChange &&
        props.order &&
        props.order.referral &&
        props.order.referral.files
      ) {
        const files = props.order.referral.files.filter(
          (file: any) => file.id !== fileId
        );
        const updatedReferral: Referral = updateField(
          {
            ...(referral || REFERRAL_EMPTY),
            status:
              referral?.status && referral?.status === ReferralStatus.Rejected
                ? referral?.status
                : ReferralStatus.Pending,
          },
          "files",
          files
        );
        props.onChange(updateField(props.order, "referral", updatedReferral));
      } else {
        dispatch(
          toastAdd(txt.get("orders.order.delete_file_failed"), null, "danger")
        );
      }
    };
    deleteFile(fileId);
  };

  const onNewReason = (
    searchValue: string,
    flattenedOptions: EuiComboBoxOptionOption<unknown>[]
  ) => {
    const normalizedSearchValue = searchValue.trim().toLowerCase();

    if (!normalizedSearchValue) {
      return;
    }

    const newOption = {
      //   value: searchValue,
      label: searchValue,
    };
    if (
      flattenedOptions.findIndex(
        (option) => option.label.trim().toLowerCase() === normalizedSearchValue
      ) === -1
    ) {
      setSelectedReasons([...selectedReasons, newOption]);
      setReasons([...reasons, newOption]);
    }
  };

  const onNewRejection = (
    searchValue: string,
    flattenedOptions: EuiComboBoxOptionOption<unknown>[]
  ) => {
    const normalizedSearchValue = searchValue.trim().toLowerCase();

    if (!normalizedSearchValue) {
      return;
    }

    const newOption = {
      //   value: searchValue,
      label: searchValue,
    };
    if (
      flattenedOptions.findIndex(
        (option) => option.label.trim().toLowerCase() === normalizedSearchValue
      ) === -1
    ) {
      setSelectedRejections([...selectedRejections, newOption]);
      setRejectionReasons([...rejectionReasons, newOption]);
    }
  };
  const renderReferralUpload = (referralInput?: Input | null) => {
    // console.log("renderReferralUpload", referralInput);
    return (
      <EuiFlexGrid gutterSize="none">
        {referralInput ? (
          <EuiFlexGroup gutterSize="s" direction="column">
            <EuiTitle size="xs">
              <EuiText color="#7E7E7E">
                <MMTip text={txt.html(`orders.order.i.referral`)}>
                  <span style={{ paddingRight: "8px" }}>
                    <MMIcon type="referral" />
                  </span>
                  {txt.get("orders.order.intake.referral")}
                </MMTip>
              </EuiText>
            </EuiTitle>
            <EuiHorizontalRule className="rule-strong" margin="none" />
            <EuiSpacer size="xs" />
            {/* {AuthenticationHelper.isInternalUser() &&
            clientReferralOptions.length > 1 ? (
              <EuiFormRow
                style={{ marginTop: "9px" }}
                className="product-input"
                display="rowCompressed"
                label={""}
              >
                <EuiSuperSelect
                  isLoading={isLoading}
                  compressed={true}
                  color="accent"
                  fullWidth
                  hasDividers={true}
                  name={txt.get("orders.order.choose_referral")}
                  placeholder={txt.get("orders.order.choose_referral")}
                  options={clientReferralOptions}
                  valueOfSelected={selectedClientReferralOption}
                  onChange={(value) => handleClientReferralOptionChange(value)}
                />
              </EuiFormRow>
            ) : (
              <EuiSpacer size="xs" />
            )} */}
            <EuiFormRow
              isDisabled={!props.isEditable}
              isInvalid={props.showValidation && !!referralError}
              error={referralError}
            >
              <EuiFlexGroup
                gutterSize="none"
                direction="column"
                style={{ width: "248px" }}
              >
                {files.length > 0 ? (
                  <EuiFlexItem>
                    <MMFiles
                      previewAll={true}
                      downloadAll={true}
                      detailedPreview={true}
                      files={files}
                      onDelete={props.isEditable ? handleDelete : undefined}
                    />
                  </EuiFlexItem>
                ) : (
                  <></>
                )}
                <EuiSpacer size="s" />
                <EuiFlexItem>
                  <EuiFlexGroup direction="column">
                    {props.isEditable ? (
                      <EuiFlexItem>
                        <MMFileUpload
                          isInvalid={props.showValidation && !!referralError}
                          multiple={true}
                          fileTypes={[
                            FileType.Docx,
                            FileType.Image,
                            FileType.Pdf,
                            FileType.Text,
                          ]}
                          attachmentOptions={{
                            orderId: props.order?.id,
                            attachedToId:
                              props.order?.referral?.id || undefined,
                            attachedToType: AttachedToType.Referral,
                            type: AttachmentType.Referral,
                          }}
                          onAdded={handleAdded}
                          title={
                            files.length === 0
                              ? txt.get("referrals.upload_letter")
                              : undefined
                          }
                          size={files.length === 0 ? "large" : "small"}
                        />
                      </EuiFlexItem>
                    ) : (
                      <></>
                    )}

                    {files.length <= 0 &&
                    AuthenticationHelper.isInternalUser() ? (
                      <EuiFlexItem>
                        <EuiPopover
                          anchorPosition="downRight"
                          ownFocus={false}
                          button={
                            <EuiButton
                              size="s"
                              className="button-transparent"
                              color={
                                props.showValidation &&
                                !!referralError &&
                                selectedReasons.length === 0
                                  ? "danger"
                                  : "text"
                              }
                              onClick={() =>
                                setIsNoReferralOpen(!isNoReferralOpen)
                              }
                              iconType={
                                selectedReasons.length === 0 ? "" : "check"
                              }
                              iconSide="right"
                            >
                              {txt.get("orders.order.missing_referral")}
                            </EuiButton>
                          }
                          isOpen={isNoReferralOpen}
                          closePopover={() => setIsNoReferralOpen(false)}
                        >
                          <EuiFormRow>
                            <EuiComboBox
                              isDisabled={!props.isEditable}
                              style={{ width: "270px" }}
                              singleSelection={{ asPlainText: true }}
                              isLoading={isLoading}
                              color="accent"
                              id="referral-missing"
                              fullWidth
                              placeholder={txt.get(
                                "orders.order.missing_referral_reason"
                              )}
                              options={reasons}
                              selectedOptions={selectedReasons}
                              onChange={(selected) =>
                                onSelectedReasonsChanged(selected)
                              }
                              // onCreateOption={onNewReason}
                            />
                          </EuiFormRow>
                          {referralNeedsFollowUp(referral) ? (
                            <EuiFormRow>
                              <EuiFieldText
                                disabled={!props.isEditable}
                                style={{ width: "270px" }}
                                isLoading={isLoading}
                                color="accent"
                                id="referral-responsible"
                                fullWidth
                                placeholder={txt.get(
                                  "orders.order.missing_referral_follow_up"
                                )}
                                value={referral?.no_referral_follow_up || ""}
                                onChange={(e) =>
                                  onNoReferralFollowUpChanged(
                                    e.target.value,
                                    false
                                  )
                                }
                                onBlur={(e) =>
                                  onNoReferralFollowUpChanged(e.target.value)
                                }
                              />
                            </EuiFormRow>
                          ) : (
                            <></>
                          )}

                          <EuiSpacer />

                          <EuiFlexGroup>
                            <EuiFlexItem>
                              <EuiButton
                                size="s"
                                fill
                                onClick={() => setIsNoReferralOpen(false)}
                              >
                                {txt.get("generic.ok")}
                              </EuiButton>
                            </EuiFlexItem>
                          </EuiFlexGroup>
                        </EuiPopover>
                      </EuiFlexItem>
                    ) : files.length > 0 ? (
                      <EuiFlexItem>
                        <EuiFormRow
                          isInvalid={
                            props.showValidation && !referral?.referred_at
                          }
                          display="rowCompressed"
                          label={
                            "" //  txt.get("orders.order.referred_at")
                          }
                          error={referredAtError}
                        >
                          <EuiDatePicker
                            isInvalid={
                              props.showValidation && !referral?.referred_at
                            }
                            showTimeSelect={false}
                            dateFormat={"DD-MM-YYYY"}
                            compressed={true}
                            selected={
                              referral?.referred_at
                                ? MomentHelper.toMoment(referral.referred_at)
                                : null
                            }
                            aria-placeholder={txt.get(
                              "orders.order.referred_at"
                            )}
                            placeholder={txt.get("orders.order.referred_at")}
                            onChange={(value) => {
                              onReferredAtChanged(value ? value : null);
                            }}
                          />
                        </EuiFormRow>
                      </EuiFlexItem>
                    ) : (
                      <></>
                    )}
                    {props.inputs?.referral_status &&
                    props.inputs?.referral_status.visible ? (
                      <EuiFormRow
                        className="info-appended"
                        display="rowCompressed"
                        label={
                          ""
                          // <EuiText
                          //   size="xs"
                          //   color="#303030"
                          //   style={{ fontWeight: "bold" }}
                          // >
                          //   {txt.get("generic.status")}
                          // </EuiText>
                        }
                        labelAppend={
                          props.inputs?.referral_status &&
                          props.inputs?.referral_status.visible &&
                          props.order?.referral?.id &&
                          props.order?.referral?.status ? (
                            <EuiPopover
                              id="referral-log"
                              button={
                                <EuiButtonIcon
                                  aria-label="referral-log"
                                  display="base"
                                  color="text"
                                  key="referral-log"
                                  size="xs"
                                  onClick={() => {
                                    setIsReferralLogOpen(!isReferralLogOpen);
                                  }}
                                  style={{
                                    position: "relative",
                                    top: "-5px",
                                    left: "-3px",
                                    backgroundColor: "transparent",
                                  }}
                                  iconType="iInCircle"
                                />
                              }
                              isOpen={isReferralLogOpen}
                              closePopover={() => {
                                setIsReferralLogOpen(false);
                              }}
                              panelPaddingSize="none"
                              anchorPosition="rightCenter"
                              style={{ height: "100%" }}
                            >
                              <MMReferralStatusLogs order={props.order} />
                            </EuiPopover>
                          ) : (
                            <></>
                          )
                        }
                      >
                        <EuiSuperSelect
                          compressed={true}
                          itemClassName="referral-status-select-item"
                          hasDividers={true}
                          placeholder={txt.get("generic.status")}
                          valueOfSelected={referral?.status || ""}
                          options={statusOptions}
                          onChange={(value) => onStatusChanged(value)}
                        />
                      </EuiFormRow>
                    ) : (
                      <></>
                    )}
                    {props.inputs?.referral_status?.visible &&
                    referral &&
                    referral.status &&
                    showRejection &&
                    canEditRejection ? (
                      <EuiFlexItem grow={true}>
                        <EuiFormRow>
                          <EuiComboBox
                            isDisabled={!props.isEditable}
                            style={{ width: "270px" }}
                            // singleSelection={{ asPlainText: true }}
                            isLoading={isLoading}
                            color="accent"
                            id="referral-rejection"
                            fullWidth
                            placeholder={txt.get(
                              "orders.order.referral_rejection_reason"
                            )}
                            options={rejectionReasons}
                            selectedOptions={selectedRejections}
                            onChange={(selected) =>
                              onSelectedRejectionsChanged(selected)
                            }
                            // onCreateOption={onNewRejection}
                          />
                        </EuiFormRow>
                      </EuiFlexItem>
                    ) : referral && referral.status && showRejection ? (
                      <EuiText color="danger" size="s">
                        <EuiIcon type="errorFilled" />
                        &nbsp;
                        {selectedRejections
                          .map((reason: any) => reason.label)
                          .join(", ")}
                      </EuiText>
                    ) : (
                      <></>
                    )}
                  </EuiFlexGroup>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFormRow>
          </EuiFlexGroup>
        ) : (
          <></>
        )}
      </EuiFlexGrid>
    );
  };

  return (
    <EuiFlexGroup>
      <EuiFlexItem>
        {renderReferralUpload(getOrderInput("referral", props.inputs))}
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

export default MMOrderReferral;
