import {
  EuiComboBox,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFormRow,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import { MMOrderLineFieldProps } from "../order-line-entry";
import { useEffect, useState } from "react";
import txt from "helpers/text-helper";
import { toSelectOptions } from "hoc/helper-hooks";
import { toFunctionDescriptionList } from "store/data/product/product";

function MMOrderFunctionDescription(props: MMOrderLineFieldProps) {
  const lang: string = txt.lang();

  const [selectedProductFunctions, setSelectedProductFunctions] = useState<
    any[]
  >([]);
  const [productFunctions, setProductFunctions] = useState<any[]>([]);
  const [isProductFunctionsEditable, setIsProductFunctionsEditable] =
    useState<boolean>(false);

  useEffect(() => {
    let functionDescription: string = "";
    let functions: string[] = [];
    let selectedFunctions: string[] = [];

    if (
      props.selection &&
      props.selection.variation &&
      props.selection.variation[`function_description_${lang}`]
    ) {
      functionDescription =
        props.selection.variation[`function_description_${lang}`];
      functions = toFunctionDescriptionList(functionDescription);
    }

    if (props.selection.variation?.editable_properties) {
      selectedFunctions = props.selection?.functionDescription
        ? props.selection.functionDescription.trim().split(",")
        : [];
    } else {
      selectedFunctions = functions;
    }

    setProductFunctions(toSelectOptions(functions));
    setSelectedProductFunctions(toSelectOptions(selectedFunctions));
    setIsProductFunctionsEditable(
      !!props.selection?.variation?.editable_properties
    );
  }, [props.selection]);

  const onProductFunctionsChange = (selected: any) => {
    if (props.onChange) {
      props.onChange({
        ...props.selection,
        functionDescription: selected
          .map((select: any) => select.value)
          .join(","),
      });
    } else {
      setSelectedProductFunctions(selected);
    }
  };
  return (
    <EuiFlexGrid gutterSize="none">
      <EuiSpacer size="s" />
      <EuiFormRow
        style={{ maxWidth: "100%", width: "260px" }}
        display="rowCompressed"
        className="product-input"
        label={
          props.showTitle
            ? txt.get("orders.order.function_description")
            : undefined
        }
      >
        {isProductFunctionsEditable ? (
          <EuiComboBox
            fullWidth={true}
            compressed={true}
            aria-label={txt.get("orders.order.function_description")}
            placeholder={txt.get("orders.order.function_description_select")}
            options={productFunctions}
            selectedOptions={selectedProductFunctions}
            onChange={(selected: any) => onProductFunctionsChange(selected)}
          />
        ) : (
          <EuiFlexGroup gutterSize="xs" direction="column">
            {productFunctions.map((option: any, i: number) => (
              <EuiText size="s" color="gray" key={`pf-${i}`}>
                - {option.value}
              </EuiText>
            ))}
          </EuiFlexGroup>
        )}
      </EuiFormRow>
    </EuiFlexGrid>
  );
}

export default MMOrderFunctionDescription;
