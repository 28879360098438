import APIHelper, { ApiResponseStatus } from "./api-helper";
import env from "helpers/env-helper";

import AuthenticationHelper from "helpers/authentication-helper";
import StringHelper from "helpers/string-helper";
import { versionInfo } from "helpers/version-helper";
import { OrderStatus } from "store/data/order/order";
import txt from "helpers/text-helper";
import { AssignedDevice } from "store/data/scan/scanner";

const ORDER_SORTABLE_FIELDS: string[] = [
  "id",
  "ordered_at",
  "ordered_by",
  "practitioner",
  "location",
  "delivery_method",
  "client_code",
  "hand",
  "status",
  "updated_at",
  "created_at",
  "referral.updated_at",
];

const INSURERS_SORTABLE_FIELDS: string[] = [
  "id",
  "name",
  "is_active",
  "uvozi_code",
  "created_at",
  "updated_at",
];

const UZOVI_CODES_SORTABLE_FIELDS: string[] = [
  "id",
  "name",
  "code",
  "insurer_id",
  "created_at",
  "updated_at",
];

const INVOICE_DETAILS_SORTABLE_FIELDS: string[] = [
  "id",
  "order_id",
  "order_line_id",
  "vlot_order_id",
  "vlot_order_line_id",
  "vlot_invoice_id",
];

const INVOICE_MATCHES_SORTABLE_FIELDS: string[] = [
  // "order_id",
  // "order_line_id",
  "client_code",
  "order_date",
];
const CONTRACTS_SORTABLE_FIELDS: string[] = [
  "id",
  "insurer_name",
  "base_article_code",
];

const COSTINGS_SORTABLE_FIELDS: string[] = [
  "id",
  "authorization",
  "is_quoted",
  "is_authorized",
  "is_invoiced",
  "order_line.order.client_code",
  "contract.insurer_id",
  "contract.base_article_code",
  "order",
  "created_at",
  "updated_at",
];

const ORGANISATION_SORTABLE_FIELDS: string[] = [
  "id",
  "name",
  "acronym",
  "created_at",
  "updated_at",
];
const USER_SORTABLE_FIELDS: string[] = [
  "id",
  "first_name",
  "last_name",
  "email",
  "organisation_id",
  "created_at",
  "updated_at",
];
const LOCATION_SORTABLE_FIELDS: string[] = [
  "id",
  "name",
  "city",
  "is_active",
  "organisation_id",
  "created_at",
  "updated_at",
];

const REFERRER_SORTABLE_FIELDS: string[] = [
  "id",
  "is_active",
  "organisation_id",
  "alternative_organisation",
  "note",
  "created_at",
  "updated_at",
];

const APPOINTMENT_SORTABLE_FIELDS: string[] = [
  "id",
  "start",
  "practitioner_id",
  "location_id",
];

const WORKFLOW_SORTABLE_FIELDS: string[] = [
  "id",
  "name",
  "acronym",
  "is_active",
  "created_at",
  "updated_at",
];
const PRODUCTIONS_SORTABLE_FIELDS: string[] = [
  "id",
  "code",
  "order_id",
  "product",
  "client",
  "order_date",
  "created_at",
  "updated_at",
  "order.location",
  "order.delivery_method",
];

const ORDER_MATCH_SORTABLE_FIELDS: string[] = [
  "order_id",
  "order_line_id",
  "order_date",
];
// const ORDER_FILTERABLE_FIELDS:string[]     = ["id", "ordered_at", "ordered_by", "practitioner","created_at", "client_code", "hand", "status"];
// const ORDER_DEFAULT_SINCE = '2022-10-01';

class ConnectAPIHelper extends APIHelper {
  private ACCESS_TOKEN_KEY = "cloud.manometric.api.connect.access_token";
  private API_ROOT = env("REACT_APP_API_CONNECT");

  //add auditing info in headers
  getHeaders() {
    let headers = super.getHeaders();
    let auditInfo = {
      deviceInfo: {
        userAgent: navigator.userAgent,
        cookieEnabled: navigator.cookieEnabled,
        language: navigator.language,
        platform: navigator.platform,
      },
      version: versionInfo(),
    };
    headers["MM-Audit-Info"] = StringHelper.toBase64(JSON.stringify(auditInfo));
    return headers;
  }

  getRoot() {
    return this.API_ROOT;
  }

  getAccesstokenKey() {
    return this.ACCESS_TOKEN_KEY;
  }

  getUserCredentialsKey() {
    return null;
  }

  async preHook() {
    //await this.checkAuthentication();
  }

  async postHook() {}

  async checkAuthentication() {
    await AuthenticationHelper.refreshAuthentication();
    this.setAccessToken(AuthenticationHelper.getToken());
  }

  async getPermissions() {
    const path = `permissions`;

    await this.checkAuthentication();

    const result: any = await this.get(path);
    return this.handleResult(result);
  }

  orderIsSortableBy(key: string) {
    return ORDER_SORTABLE_FIELDS.includes(key);
  }

  insurersIsSortableBy(key: string) {
    return INSURERS_SORTABLE_FIELDS.includes(key);
  }

  uzoviCodesSortableBy(key: string) {
    return UZOVI_CODES_SORTABLE_FIELDS.includes(key);
  }

  invoiceDetailsIsSortableBy(key: string) {
    return INVOICE_DETAILS_SORTABLE_FIELDS.includes(key);
  }

  invoiceMatchesIsSortableBy(key: string) {
    return INVOICE_MATCHES_SORTABLE_FIELDS.includes(key);
  }

  contractIsSortableBy(key: string) {
    return CONTRACTS_SORTABLE_FIELDS.includes(key);
  }
  costingIsSortableBy(key: string) {
    return COSTINGS_SORTABLE_FIELDS.includes(key);
  }

  organisationIsSortableBy(key: string) {
    return ORGANISATION_SORTABLE_FIELDS.includes(key);
  }

  userIsSortableBy(key: string) {
    return USER_SORTABLE_FIELDS.includes(key);
  }

  locationIsSortableBy(key: string) {
    return LOCATION_SORTABLE_FIELDS.includes(key);
  }

  referrerIsSortableBy(key: string) {
    return REFERRER_SORTABLE_FIELDS.includes(key);
  }

  appointmentIsSortableBy(key: string) {
    return APPOINTMENT_SORTABLE_FIELDS.includes(key);
  }

  workflowIsSortableBy(key: string) {
    return WORKFLOW_SORTABLE_FIELDS.includes(key);
  }

  productionIsSortableBy(key: string) {
    return PRODUCTIONS_SORTABLE_FIELDS.includes(key);
  }

  orderMatchIsSortableBy(key: string) {
    return ORDER_MATCH_SORTABLE_FIELDS.includes(key);
  }

  async getLanguages(languageCode?: string) {
    const path: string = "settings/languages";
    const params = {
      language_code: languageCode,
    };
    const result: any = await this.get(path, params);
    if (result !== undefined && result.status === "OK" && result.result) {
      return result.result;
    } else {
      return {};
    }
  }

  async getTranslations(format?: string) {
    const path: string = "settings/languages/translations";
    let params: any = {
      // language_code : languageCode
    };
    if (format) {
      params.format = format;
    }
    const result: any = await this.get(path, params);
    // console.log("getTranslations", result);
    if (result !== undefined && result.status === "OK" && result.result) {
      return result.result;
    } else {
      return null;
    }
  }

  async getContractsXls(format?: string) {
    const path: string = "contracts/export";
    let params: any = {};
    if (format) {
      params.format = format;
    }
    const result: any = await this.post(path, params);
    if (result !== undefined && result.status === "OK" && result.result) {
      return result.result;
    } else {
      return null;
    }
  }

  async removeAppointments() {
    const path: string = "appointments/remove";

    await this.checkAuthentication();

    const result: any = await this.post(path);
    console.log("removeAppointments", result);
    if (result !== undefined && result.status === "OK") {
      return true;
    } else {
      return result.message;
    }
  }

  async getProductionNotesUpdates(orderId?: number) {
    const path: string = "orders/production/update-notes";

    await this.checkAuthentication();

    const result: any = await this.get(path, orderId ? { orderId } : undefined);
    if (result !== undefined && result.status && result.status === "OK") {
      return true;
    } else {
      return false;
    }
  }

  async getProductionStatusUpdates(orderId?: number) {
    const path: string = "orders/production/update";

    await this.checkAuthentication();

    const result: any = await this.get(path, orderId ? { orderId } : undefined);
    if (result !== undefined && result.status && result.status === "OK") {
      return true;
    } else {
      return false;
    }
  }

  async getProductionData() {
    const path: string = "orders/production/data";

    await this.checkAuthentication();

    const result: any = await this.get(path);
    if (result !== undefined && result.status && result.status === "OK") {
      return true;
    } else {
      return false;
    }
  }

  async getDataToReload() {
    const path: string = "orders/reload";

    await this.checkAuthentication();

    const result: any = await this.get(path);
    if (result !== undefined && result.status && result.status === "OK") {
      return true;
    } else {
      return false;
    }
  }

  async reloadDrawerCheckData() {
    const path: string = "orders/reload-drawer-check-data";

    await this.checkAuthentication();

    const result: any = await this.post(path);
    if (result !== undefined && result.status && result.status === "OK") {
      return true;
    } else {
      return false;
    }
  }

  //get product info for the order, so you have old product info
  async getProductsForOrder(orderId: number) {
    const path: string = `orders/products`;
    await this.checkAuthentication();
    const params: any = {
      language_code: txt.lang(),
      order_id: orderId,
    };
    const result: any = await this.get(path, params);
    return this.handleResult(result);
  }

  async getProducts() {
    const path: string = `orders/products`;
    await this.checkAuthentication();
    const params: any = {
      language_code: txt.lang(),
    };
    const result: any = await this.get(path, params);
    return this.handleResult(result);
  }

  async getProductFields() {
    const path: string = `products/fields`;
    await this.checkAuthentication();
    const params: any = {
      language_code: txt.lang(),
    };
    const result: any = await this.get(path, params);
    return this.handleResult(result);
  }

  async getProductsToValidate(set?: string) {
    if (!set) set = "all";
    const path: string = `orders/validate/${set}`;

    await this.checkAuthentication();

    const result: any = await this.get(path);
    return this.handleResult(result);
  }

  async getOrderMatches(
    filters?: any,
    limit?: number,
    offset?: number,
    sort?: string,
    order?: string,
    since?: Date,
    until?: Date
  ) {
    const path = "orders/matches";

    let params = { limit, offset, sort, order, ...filters };
    if (since) params.since = this.toApiDate(since);
    if (until) params.until = this.toApiDate(until);

    await this.checkAuthentication();

    const result: any = await this.get(path, params);

    return result;
  }
  async fixOrderLine(lineInfo: any) {
    const path = "orders/matches/fix";

    let params = {};

    await this.checkAuthentication();

    const result: any = await this.post(path, params, lineInfo);

    return result;
  }

  async getOrders(
    filters?: any,
    limit?: number,
    offset?: number,
    sort?: string,
    order?: string,
    since?: Date,
    until?: Date
  ) {
    const path = "orders";

    let params = { limit, offset, sort, order, ...filters };
    if (since) params.since = this.toApiDate(since);
    if (until) params.until = this.toApiDate(until);

    await this.checkAuthentication();

    const result: any = await this.get(path, params);

    return result;
  }

  async getCostings(
    filters?: any,
    limit?: number,
    offset?: number,
    sort?: string,
    order?: string
  ) {
    const path = "costings";

    let params = { limit, offset, sort, order, ...filters };

    await this.checkAuthentication();

    const result: any = await this.get(path, params);

    return result;
  }

  async getCostingsExport(
    filters?: any,
    limit?: number,
    offset?: number,
    sort?: string,
    order?: string
  ) {
    const path = "costings/export";

    let params = {
      // limit, offset, sort, order,
      ...filters,
    };

    await this.checkAuthentication();

    const result: any = await this.get(path, params);

    return this.handleResult(result);
  }

  async getOrderLinesExport() {
    const path = "orders/export";

    let params = {};

    await this.checkAuthentication();

    const result: any = await this.get(path, params);

    return this.handleResult(result);
  }

  async getOrderLinesValidateExport(type: "csv" | "xls" = "xls") {
    const path = "orders/validate-export";

    let params = {
      type,
    };

    await this.checkAuthentication();

    const result: any = await this.post(path, params);

    return this.handleResult(result);
  }

  async updateCostingFields(costingId: number, fields: any) {
    const path = `costings/${costingId}`;

    let params = {};
    let body = fields;

    await this.checkAuthentication();

    const result: any = await this.post(path, params, body);

    return result;
  }

  async getOrderAmounts(filters?: any) {
    const path = "orders/amounts";
    let params: any = {};
    if (filters) {
      params = { ...filters };
    }
    await this.checkAuthentication();

    const result: any = await this.get(path, params);

    return result;
  }

  async getOrderCounts(by?: string[], includeDemo?: boolean, since?: string) {
    //by practitioner, by location, by organisation are options to explode the counts into
    const path = "orders/counts";
    let params: any = {};
    if (by) {
      params.by = by;
    }
    if (includeDemo !== undefined) {
      params.include_demo = includeDemo;
    }
    if (since !== undefined) {
      params.since = since;
    }
    await this.checkAuthentication();

    const result: any = await this.get(path, params);

    return this.handleResult(result);
  }

  async deleteOrder(orderId: number) {
    return this.deleteOrders([orderId]);
  }

  async deleteOrders(orderIds: any) {
    const path = "orders/delete";

    let body = { order_ids: orderIds };

    await this.checkAuthentication();

    const result: any = await this.post(path, null, body);
    return this.handleResult(result);
  }

  async duplicateOrder(orderId: number) {
    const path = `orders/order/${orderId}/duplicate`;

    await this.checkAuthentication();

    const result: any = await this.post(path);
    return this.handleResult(result);
  }

  async updateOrderStatus(
    orderId: number,
    status: OrderStatus,
    comment?: string,
    orderLineStatusses?: any,
    loggedAt?: Date | null
  ) {
    const path = `orders/order/${orderId}/status/`;

    await this.checkAuthentication();

    let body: any = { status };
    if (comment) {
      body.comment = comment;
    }
    if (orderLineStatusses) {
      body.order_lines = orderLineStatusses;
    }
    if (loggedAt) {
      body.logged_at = loggedAt;
    }
    const result: any = await this.post(path, null, body);
    return this.handleResult(result);
  }

  async getOrder(orderId: number) {
    const path = `orders/order/${orderId}`;

    await this.checkAuthentication();

    const result: any = await this.get(path);
    return this.handleResult(result);
  }

  async saveAndSubmitOrder(orderInfo: any) {
    const path = "orders/create";

    await this.checkAuthentication();

    const result: any = await this.post(path, null, orderInfo);
    return this.handleResult(result);
  }

  async createOrderEvaluation(orderId: number, score: number, notes?: string) {
    const path = `orders/order/${orderId}/evaluate`;

    await this.checkAuthentication();
    let body: any = { score };
    if (notes) {
      body.notes = notes;
    }

    const result: any = await this.post(path, null, body);
    return this.handleResult(result);
  }

  async getProductionLog(orderlineId: number) {
    const path = `orders/production-log`;

    await this.checkAuthentication();

    const result: any = await this.get(path, {
      order_line_id: orderlineId,
    });
    return this.handleResult(result);
  }

  async saveOrder(orderInfo: any) {
    const path = "orders/save";

    await this.checkAuthentication();

    const result: any = await this.post(path, null, orderInfo);
    return this.handleResult(result);
  }

  async updateOrderFields(orderId: number, fields: any) {
    const path = "orders/updatefields";

    await this.checkAuthentication();

    const result: any = await this.post(path, null, { id: orderId, fields });
    return this.handleResult(result);
  }

  async getOrderValues() {
    const path = `orders/values`;

    await this.checkAuthentication();

    const result: any = await this.get(path);
    return this.handleResult(result);
  }

  async getOrderFiles(orderId: number) {
    const path = `orders/files`;
    const params = {
      order_id: orderId,
    };

    await this.checkAuthentication();

    const result: any = await this.get(path, params);
    return this.handleResult(result);
  }

  async getMergedFiles(fileIds: number[]) {
    const path = `files/merged?file_ids=${fileIds.join("&file_ids=")}`;
    await this.checkAuthentication();

    const result: any = await this.getFile(path, false);
    return result;
  }

  async removeOrderFile(
    fileId: number,
    orderId?: number,
    referralId?: number,
    orderLineId?: number,
    fittingId?: number,
    orderCostingId?: number
  ) {
    const path = `orders/files/delete`;
    const body = {
      file_id: fileId,
      order_id: orderId,
      referral_id: referralId,
      order_line_id: orderLineId,
      fitting_id: fittingId,
      order_costing_id: orderCostingId,
    };

    await this.checkAuthentication();

    const result: any = await this.post(path, null, body);
    return this.handleResult(result);
  }

  async getWorkflows(
    filters?: any,
    limit?: number,
    offset?: number,
    sort?: string,
    order?: string
  ) {
    const path = `productions/workflows`;

    let params = { limit, offset, sort, order, ...filters };
    await this.checkAuthentication();

    const result: any = await this.get(path, params);

    return result;
  }

  async getWorkflow(id: number) {
    const path = `productions/workflows/${id}`;

    await this.checkAuthentication();

    const result: any = await this.get(path);

    return result;
  }

  async createWorkflow(workflowInfo: any) {
    const path = `productions/workflows`;
    const result: any = await this.post(path, null, workflowInfo);
    return result;
  }

  async updateWorkflow(workflowId: number, workflowInfo: any) {
    const path = `productions/workflows/${workflowId}`;
    const result: any = await this.post(path, null, workflowInfo);
    return result;
  }

  async deleteWorkflow(workflowId: number) {
    const path = `productions/workflows/${workflowId}/delete`;
    const result: any = await this.post(path, null);
    return result;
  }

  async getAvailableWorkflowSteps() {
    const path = `productions/workflow-step-available`;
    const result: any = await this.get(path, null);
    return this.handleResult(result);
  }

  async getProductionChecks() {
    const path = `productions/checks`;
    const result: any = await this.get(path, null);
    return this.handleResult(result);
  }

  async getProductions(
    filters?: any,
    limit?: number,
    offset?: number,
    sort?: string,
    order?: string
  ) {
    const path = `productions`;

    let params = { limit, offset, sort, order, ...filters };
    await this.checkAuthentication();

    const result: any = await this.get(path, params);

    return result;
  }

  async getReferrals(
    filters?: any,
    limit?: number,
    offset?: number,
    sort?: string,
    order?: string
  ) {
    const path = `referrals`;

    let params = { limit, offset, sort, order, ...filters };
    await this.checkAuthentication();

    const result: any = await this.get(path, params);

    return result;
  }

  async getAdminOrganisations(
    filters: any = {},
    limit?: number,
    offset?: number,
    sort?: string,
    order?: string
  ) {
    let params = { limit, offset, sort, order, ...filters };
    const path = `organisations`;
    const result: any = await this.get(path, params);
    return result;
  }

  async getAdminOrganisation(organisationId: number) {
    const path = `organisations/${organisationId}`;
    const result: any = await this.get(path);
    return result;
  }

  async createAdminOrganisation(organisationInfo: any) {
    const path = `organisations/`;
    const result: any = await this.post(path, null, organisationInfo);
    return result;
  }

  async updateAdminOrganisation(organisationId: number, organisationInfo: any) {
    const path = `organisations/${organisationId}`;
    const result: any = await this.post(path, null, organisationInfo);
    return result;
  }

  async deleteAdminOrganisation(organisationId: number) {
    const path = `organisations/${organisationId}/delete`;
    const result: any = await this.post(path, null);
    return result;
  }

  async getAdminLocations(
    filters?: any,
    limit?: number,
    offset?: number,
    sort?: string,
    order?: string
  ) {
    let params = { limit, offset, sort, order, ...filters };
    const path = `locations`;
    const result: any = await this.get(path, params);
    return result;
  }

  async getAdminLocation(locationId: number) {
    const path = `locations/${locationId}`;
    const result: any = await this.get(path);
    return result;
  }

  async createAdminLocation(locationInfo: any) {
    const path = `locations/`;
    const result: any = await this.post(path, null, locationInfo);
    return result;
  }

  async updateAdminLocation(locationId: number, locationInfo: any) {
    const path = `locations/${locationId}`;
    const result: any = await this.post(path, null, locationInfo);
    return result;
  }

  async deleteAdminLocation(locationId: number) {
    const path = `locations/${locationId}/delete`;
    const result: any = await this.post(path, null);
    return result;
  }

  async getReferrers() {
    const path = `referrers`;
    let params = { limit: 1500 };
    const result: any = await this.get(path, params);
    return this.handleResult(result);
  }

  async getAdminReferrers(
    filters?: any,
    limit?: number,
    offset?: number,
    sort?: string,
    order?: string
  ) {
    let params = { limit, offset, sort, order, ...filters };
    const path = `referrers`;
    const result: any = await this.get(path, params);
    return result;
  }

  async getAdminReferrer(referrerId: number) {
    const path = `referrers/${referrerId}`;
    const result: any = await this.get(path);
    return result;
  }

  async createAdminReferrer(referrerInfo: any) {
    const path = `referrers/`;
    const result: any = await this.post(path, null, referrerInfo);
    return result;
  }

  async updateAdminReferrer(refferId: number, referrerInfo: any) {
    const path = `referrers/${refferId}`;
    const result: any = await this.post(path, null, referrerInfo);
    return result;
  }

  async updateAdminReferrerFields(referrerId: number, fields: any) {
    const path = `referrers/${referrerId}/fields`;

    let params = {};
    let body = fields;

    await this.checkAuthentication();

    const result: any = await this.post(path, params, body);

    return result;
  }
  async deleteAdminReferrer(referrerId: number) {
    const path = `referrers/${referrerId}/delete`;
    const result: any = await this.post(path, null);
    return result;
  }

  async getInvoiceCounts() {
    const path = "invoices/counts";
    let params: any = {};
    await this.checkAuthentication();

    const result: any = await this.get(path, params);

    return this.handleResult(result);
  }

  async getAdminUzoviCodes(
    filters?: any,
    limit?: number,
    offset?: number,
    sort?: string,
    order?: string,
    handled: boolean = true
  ) {
    let params = { limit, offset, sort, order, ...filters };
    const path = `insurers/uzovi-codes`;
    const result: any = await this.get(path, params);
    return handled ? this.handleResult(result) : result;
  }

  async getAdminUzoviCode(uzoviCodeId: number) {
    const path = `insurers/uzovi-codes/${uzoviCodeId}`;
    const result: any = await this.get(path);
    return result;
  }

  async createAdminUzoviCode(uzoviCodeInfo: any) {
    const path = `insurers/uzovi-codes/`;
    const result: any = await this.post(path, null, uzoviCodeInfo);
    return result;
  }

  async updateAdminUzoviCode(uzoviCodeId: number, uzoviCodeInfo: any) {
    const path = `insurers/uzovi-codes/${uzoviCodeId}`;
    const result: any = await this.post(path, null, uzoviCodeInfo);
    return result;
  }

  async deleteAdminUzoviCode(uzoviCodeId: number) {
    const path = `insurers/uzovi-codes/${uzoviCodeId}/delete`;
    const result: any = await this.post(path, null);
    return result;
  }

  async getAdminInsurers(
    limit?: number,
    offset?: number,
    sort?: string,
    order?: string
  ) {
    let params = { limit, offset, sort, order };
    const path = `insurers`;
    const result: any = await this.get(path, params);
    return result;
  }

  async getAdminInsurer(insurerId: number) {
    const path = `insurers/${insurerId}`;
    const result: any = await this.get(path);
    return result;
  }

  async createAdminInsurer(insurerInfo: any) {
    const path = `insurers/`;
    const result: any = await this.post(path, null, insurerInfo);
    return result;
  }

  async updateAdminInsurer(insurerId: number, insurerInfo: any) {
    const path = `insurers/${insurerId}`;
    const result: any = await this.post(path, null, insurerInfo);
    return result;
  }

  async getAdminInvoiceDetails(
    filters: any,
    limit?: number,
    offset?: number,
    sort?: string,
    order?: string
  ) {
    let params = { limit, offset, sort, order, ...filters };
    const path = `invoices/details`;
    const result: any = await this.get(path, params);
    return result;
  }

  async getAdminInvoiceMatches(
    filters: any = {},
    limit?: number,
    offset?: number,
    sort?: string,
    order?: string
  ) {
    let params = { limit, offset, sort, order, ...filters };
    const path = `invoices/matches`;
    const result: any = await this.get(path, params);
    return result;
  }

  async invoiceMatch(id: number, orderId: number, orderLineId: number) {
    let body = { order_id: orderId, order_line_id: orderLineId };
    const path = `invoices/details/${id}/match`;
    const result: any = await this.post(path, {}, body);
    return result;
  }

  async invoiceUnmatch(id: number) {
    const path = `invoices/details/${id}/unmatch`;
    const result: any = await this.post(path);
    return result;
  }

  async getAdminContracts(
    filters?: any,
    limit?: number,
    offset?: number,
    sort?: string,
    order?: string
  ) {
    let params = { limit, offset, sort, order, ...filters };
    const path = `contracts`;
    const result: any = await this.get(path, params);
    return result;
  }

  async updateAssignedDevices(assignedDevices: AssignedDevice[]) {
    const path = `assigned-devices/`;
    const result: any = await this.post(path, null, assignedDevices);
    return result;
  }

  async deleteAssignedDevices(deviceId: number) {
    const path = `assigned-devices/${deviceId}/delete`;
    const result: any = await this.post(path);
    return result;
  }

  async getSpecialties() {
    const path = `referrers/specialties`;

    await this.checkAuthentication();

    const result: any = await this.get(path);
    return this.handleResult(result);
  }

  async getXLSExport() {
    const path = `exports/`;
    const result: any = await this.get(path);
    return this.handleResult(result);
  }

  //the personal details of a user are matches from keycloak by email
  async getPersonalDetails(email?: string) {
    let params = email ? { search: email } : {};
    const path = `personal-details`;
    const result: any = await this.get(path, params);
    return this.handleResult(result);
  }

  async getAdminUsers(
    filters?: any,
    limit?: number,
    offset?: number,
    sort?: string,
    order?: string
  ) {
    let params = { limit, offset, sort, order, ...filters };
    const path = `personal-details`;
    const result: any = await this.get(path, params);
    return result;
  }

  async getAdminUser(userId: number) {
    const path = `personal-details/${userId}`;
    const result: any = await this.get(path);
    return result;
  }

  async createAdminUser(userInfo: any) {
    const path = `personal-details/`;
    const result: any = await this.post(path, null, userInfo);
    return result;
  }

  async updateAdminUser(userId: number, userInfo: any) {
    const path = `personal-details/${userId}`;
    const result: any = await this.post(path, null, userInfo);
    return result;
  }

  async deleteAdminUser(userId: number) {
    const path = `personal-details/${userId}/delete`;
    const result: any = await this.post(path, null);
    return result;
  }

  async getOrganisations() {
    const path = `organisations`;
    const params: any = {
      limit: 200,
      offset: 0,
      sort: "name",
      order: "asc",
    };
    const result: any = await this.get(path, params);
    return this.handleResult(result);
  }

  async getOrganisation(organisationId: number) {
    const path = `organisations/${organisationId}`;
    const result: any = await this.get(path);
    return this.handleResult(result);
  }

  /* not yet paged */
  async getPractitioners(
    organisationIds?: number[],
    practitionerIds?: number[]
  ) {
    const path = `personal-details`;
    let params: any = { limit: 300 };
    if (organisationIds) {
      params.organisation_ids = organisationIds;
    }
    if (practitionerIds) {
      params.practitioner_ids = practitionerIds;
    }

    const result: any = await this.get(path, params);
    return this.handleResult(result);
  }

  async getOrderStatusLogs(orderId: number) {
    const path = `orders/order/${orderId}/logs`;

    await this.checkAuthentication();

    const result: any = await this.get(path);
    return this.handleResult(result);
  }

  async updateOrderStatusLog(
    orderId: number,
    orderStatusLogId: number,
    loggedAt: Date,
    comment: string
  ) {
    const path: string = `orders/order/${orderId}/logs/${orderStatusLogId}`;
    const body: any = {
      logged_at: loggedAt,
      comment: comment,
    };

    await this.checkAuthentication();

    const result: any = await this.post(path, null, body);
    return result;
  }

  async deleteOrderStatusLog(orderId: number, orderStatusLogId: number) {
    const path = `orders/order/${orderId}/logs/${orderStatusLogId}/delete`;

    await this.checkAuthentication();

    const result: any = await this.post(path);
    return result;
  }

  async getOrderReferralStatusLogs(orderId: number) {
    const path = `orders/order/${orderId}/referral-logs`;

    await this.checkAuthentication();

    const result: any = await this.get(path);
    return this.handleResult(result);
  }

  async getNpsEvaluations(
    filters?: any,
    limit?: number,
    offset?: number,
    sort?: string,
    order?: string
  ) {
    let params = { limit, offset, sort, order, ...filters };
    const path = `evaluations/nps`;
    const result: any = await this.get(path, params);
    return result;
  }
  async getEvaluations(
    filters?: any,
    limit?: number,
    offset?: number,
    sort?: string,
    order?: string
  ) {
    let params = { limit, offset, sort, order, ...filters };
    const path = `evaluations`;
    const result: any = await this.get(path, params);
    return result;
  }

  async getEvaluationCounts() {
    const path = `evaluations/counts`;

    await this.checkAuthentication();

    const result: any = await this.get(path);
    return this.handleResult(result);
  }

  async getCommunicationReasons() {
    const path = `orders/communication_reasons`;

    await this.checkAuthentication();

    let result: any = await this.get(path);
    return this.handleResult(result);
  }

  async getNeedTags(question: string) {
    const path = `orders/need_tags`;

    let params = { question };
    await this.checkAuthentication();

    let result: any = await this.get(path, params);
    return this.handleResult(result);
  }

  async getIncompleteReasons() {
    const path = `orders/incomplete_reasons`;

    await this.checkAuthentication();

    let result: any = await this.get(path);
    return this.handleResult(result);
  }

  async getNoReferralReasons() {
    const path = `orders/no_referral_reasons`;

    await this.checkAuthentication();

    const result: any = await this.get(path);
    return this.handleResult(result);
  }

  async getReferralRejectionReasons() {
    const path = `orders/referral_rejection_reasons`;

    await this.checkAuthentication();

    const result: any = await this.get(path);
    return this.handleResult(result);
  }

  async getSentence(
    text: string,
    intent?: "descriptive" | "need" | "motivation",
    lang: string = "nl"
  ) {
    const path: string = `tools/sentence`;
    let params: any = { text, lang };
    if (intent) {
      params.intent = intent;
    }
    await this.checkAuthentication();

    const result: any = await this.get(path, params);
    return result;
  }

  handleResult(result: any) {
    if (
      result !== undefined &&
      result.status &&
      result.status === ApiResponseStatus.OK
    ) {
      return result.result || true;
    } else if (result.status === ApiResponseStatus.Error) {
      return { message: result.message };
    } else {
      return null;
    }
  }
}

export default ConnectAPIHelper;
