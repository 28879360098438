import {
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiTextArea,
  useIsWithinBreakpoints,
} from "@elastic/eui";
import {
  MMOrderLineFieldProps,
  MMOrderLineProductInput,
} from "../order-line-entry";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import AuthenticationHelper from "helpers/authentication-helper";
import MMAITextToSentence from "components/layouts/aitools/text-to-sentence";
import { ValidationResult } from "helpers/input-helper";

function MMOrderProductDescription(props: MMOrderLineFieldProps) {
  const isMobile: boolean = useIsWithinBreakpoints(["xs", "s"]);
  const [productDescription, setProductDescription] = useState<string>(
    props.selection.productDescription
  );
  const [isProductFunctionsEditable, setIsProductFunctionsEditable] =
    useState<boolean>(false);

  const [error, setError] = useState<string>();

  useEffect(() => {
    setProductDescription(props.selection.productDescription);
    setIsProductFunctionsEditable(
      !!props.selection?.variation?.editable_properties
    );
  }, [props.selection]);

  useEffect(() => {
    if (props.hasValidation && props.orderLineInputs) {
      if (
        props.orderLineInputs.productDescription &&
        props.orderLineInputs.productDescription.mandatory &&
        props.orderLineInputs.productDescription.validator
      ) {
        let validationResult: ValidationResult =
          props.orderLineInputs.productDescription.validator(
            productDescription
          );
        setError(!validationResult.result ? validationResult.message : "");
      } else {
        setError("");
      }
    } else {
      setError("");
    }
  }, [props.hasValidation, props.orderLineInputs, productDescription]);

  const onProductDescriptionChange = (productDescription: string) => {
    if (props.onChange) {
      props.onChange({
        ...(props.selection || {}),
        productDescription,
      } as MMOrderLineProductInput);
    } else {
      setProductDescription(productDescription);
    }
  };

  const css = () => {
    let result: any = {};

    if (!isMobile) {
      result = {
        ...result,
        width: "100%",
        minWidth: "260px",
        maxWidth: props.maxWidth ? props.maxWidth : "260px",
        // height: "72px",
      };
    }

    return result;
  };

  return isProductFunctionsEditable ? (
    <EuiFlexGroup gutterSize="xs" style={css()}>
      <EuiFlexItem style={{ position: "relative" }}>
        <EuiFormRow
          style={css()}
          className="product-input"
          display="rowCompressed"
          label={
            props.showTitle
              ? txt.get("orders.order.product_description")
              : undefined
          }
          isInvalid={!!error}
          error={error}
        >
          <EuiFieldText
            style={css()}
            value={productDescription}
            compressed={true}
            placeholder={`${txt.get("orders.order.product_description")}...`}
            onChange={(e) => setProductDescription(e.target.value)}
            onBlur={(e) => onProductDescriptionChange(e.target.value)}
          />
        </EuiFormRow>
      </EuiFlexItem>
    </EuiFlexGroup>
  ) : (
    <></>
  );
}

export default MMOrderProductDescription;
