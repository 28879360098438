import { fallbackToLegacyNeed } from "./legacy-fallback";

export const NEED_EMPTY = {
  id: null,
  question: "",
  answer: "",
  order_id: null,
};

export interface Need {
  id: number | null;
  question: string;
  answer: string;
  order_id: number | null;
}

export const hasNeed = (needs: Need[], question: string) => {
  return needs.findIndex((need: Need) => need.question === question) >= 0;
};

export const getNeed = (needs: Need[], question: string) => {
  let result: string = "";
  let need: Need | undefined = needs.find(
    (need: Need) => need.question === question
  );
  if (need) {
    result = need.answer;
  }
  return result;
};

export const getNeeds = (needs: Need[], questionKey: string): Need[] => {
  let result: Need[] = [];

  result = needs.filter(
    (need: Need) =>
      need.question.startsWith(questionKey) &&
      need.answer !== "false" &&
      need.answer !== ""
  );
  result.sort((a: any, b: any) =>
    a.question.indexOf("explanation") < 0 &&
    b.question.indexOf("explanation") >= 0
      ? -1
      : b.question.indexOf("explanation") < 0 &&
          a.question.indexOf("explanation") >= 0
        ? 1
        : 0
  );
  result.sort((a: any, b: any) =>
    a.question.indexOf("other") < 0 && b.question.indexOf("other") >= 0
      ? -1
      : b.question.indexOf("other") < 0 && a.question.indexOf("other") >= 0
        ? 1
        : 0
  );

  return result;
};
