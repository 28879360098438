import {
  EuiFilterButton,
  EuiFilterGroup,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFormRow,
} from "@elastic/eui";
import { MMOrderLineFieldProps } from "../order-line-entry";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { ProductUse } from "store/data/product/product";

const DEFAULT_USE: ProductUse = ProductUse.Day;

function MMOrderUse(props: MMOrderLineFieldProps) {
  const [use, setUse] = useState<ProductUse | null>();

  useEffect(() => {
    setUse(
      props.selection && props.selection.use ? props.selection.use : DEFAULT_USE
    );
  }, [props.selection]);

  const onUseChange = (use: ProductUse | null) => {
    if (props.onChange) {
      props.onChange({ ...props.selection, use: use ? use : undefined });
    } else {
      setUse(use ? use : null);
    }
  };

  return (
    <EuiFlexGrid gutterSize="none">
      <EuiFlexGroup gutterSize="xs">
        <EuiFormRow
          display="rowCompressed"
          className="product-input"
          label={props.showTitle ? txt.get("orders.order.use") : undefined}
        >
          <EuiFilterGroup contentEditable={false} compressed={true}>
            <EuiFilterButton
              isDisabled={!props.isEditable}
              hasActiveFilters={use === ProductUse.Day}
              onClick={(e: any) =>
                onUseChange(use === ProductUse.Day ? null : ProductUse.Day)
              }
            >
              {txt.get("orders.order.use_option.day")}
            </EuiFilterButton>
            <EuiFilterButton
              isDisabled={!props.isEditable}
              hasActiveFilters={use === ProductUse.Night}
              onClick={(e: any) =>
                onUseChange(use === ProductUse.Night ? null : ProductUse.Night)
              }
            >
              {txt.get("orders.order.use_option.night")}
            </EuiFilterButton>
          </EuiFilterGroup>
        </EuiFormRow>
      </EuiFlexGroup>
    </EuiFlexGrid>
  );
}

export default MMOrderUse;
